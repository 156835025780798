.tab-wrapper {
  position: relative;
  background-color: blue;
}

.tab-plus {}

.back_button {
  position: absolute;
  top: 20px;
  left: -10px;
}

.tab-arrows {
  flex-direction: row;
  align-content: center;
}

.multi-onglet {
  position: relative;
}

.arrows__multi-onglet {
  position: absolute;
  top: 0;
  left: 4%;

  &--left {}

  &--right {}
}

.tab-actions {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}









.panell div.tab.active {
  background-color: #fff !important;
  //border-bottom: 4px solid #e02730 !important;
  color: #000000;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  //font-weight: bold;
}

.panell div.tab {
  position: relative;
  border-bottom: 0px !important;
  background-color: transparent !important;
  //border-bottom: 4px solid #eeeeee !important;
  max-width: 180px;
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 10px !important;
  padding-right: 30px !important;

  margin: 0px !important;
  height: 34px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  color: black;
  font-weight: 600;
  font-size: 13px;
  //border: 1px solid #ddd;
}

.panell div.tab:not(.active):not(:last-child):not(:first-child):not(:has(+ .active))::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 0;
  display: block;
  width: 2px;
  height: 15px;
  border-radius: 30px;
  background-color: #bbb;
}

:past(p, span) {
  display: none;
}

.panell div.tab:last-child {
  border-bottom: 0px !important;
  background-color: transparent !important;
  padding: 0px !important;
  border: none;
}

.panell div.tab:first-child {
  border-bottom: 0px !important;
  background-color: transparent !important;
  padding: 0px !important;
}

.panell div.tab a.closeTab {
  position: absolute;
  right: 5px !important;
  top: 8px !important;
  height: 20px;
  width: 20px;

}

.panell div.tab a.closeTab:before,
.panell div.tab a.closeTab:after {
  background-color: rgb(74, 74, 74) !important;
}

.panell div.tab.active a.closeTab:before,
.panell div.tab.active a.closeTab:after {
  background-color: rgb(74, 74, 74) !important;
}


.panell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 8px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border: 0px !important;

  background-color: var(--light-main-bg) !important;


}

.panell-main {
  margin: 0 !important;
}

.panell-content {

  padding: 0 !important;
  padding-top: 15px !important;
  background-color: transparent;
  // background-color: #f9f9f9;


}

.tapSwitch {

  padding-top: 20px !important;

}

.ant-list-grid .ant-col>.ant-list-item {
  //margin-bottom: 0px !important;
}