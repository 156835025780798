@import "~antd/dist/antd.css";
@import "dynamique/colors";

@import url("https://fonts.googleapis.com/css?family=Roboto|Oswald:300,300i,400,400i,500,500i,700,700i,900&amp;display=swap");

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
span,
label {
  //font-size: 12px;
  margin-bottom: 0;
}

/* 
span,
label,
input,
select {
  font-size: 12px;
}

h1 {
  font-size: 16px;

}

h2 {
  font-size: 15px;

}

h3 {
  font-size: 14px;

}

h4 {
  font-size: 13px;
} */

#chat-widget-container {
  min-width: 140px !important;
  min-height: 140px !important;
  //width: 420px !important;
  //height: 750px !important;
}

.recherche-autocomplete-dropdown {

  max-height: 450px;

  .ant-select-dropdown-menu {
    max-height: 450px;
  }

  a {
    color: #1890ff;
  }
}




body {
  transform-origin: top left;
  // background-image: url(./shared/assets/images/dots-background.svg);
  background-color: #FFFEFD;
  background-size: 21px 21px;
  background-repeat: repeat;
  background-position: top left;
  background-attachment: auto;
  margin: 0;
  padding: 0;
  //  background-size: cover;
  transform-origin: 0 0;
  //zoom: 0.8;


}

:root {

  --zoom: 1;
}

/* 
#root {
  transform-origin: 0 0;
  zoom: 0.8 !important;

}

.ant-modal {
  zoom: 0.8;
}
.ant-dropdown>div,
.ant-dropdown>ul,
.ant-tooltip>div,
.ant-select-dropdown>div {


  zoom: 0.8 !important;

}

 */



::placeholder {
  color: rgba(0, 0, 0, .6) !important;

}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, .6) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, .6) !important;
}

// *::selection {
//   background: none;
// }
// *::-moz-selection {
//   background: none;
// }

body {

  --scrollbar-thumb-background: #eee;
  --scrollbar-track-background: #f9f9F9;

}

.color-primary {
  color: var(--main-bg-color) !important;
}

.color-success {
  color: #01c40b !important;
}

.iconTable {
  background-color: #f6f6f6;
  padding: 8px;
  margin: 2px;
  border-radius: 6px;
}

.iconTable:hover {
  background-color: #fff;
  padding: 8px;
  margin: 2px;
  border-radius: 6px;
}

.iconTable2 {
  background-color: var(--main-bg-color) !important;
  color: #fff !important;
  padding: 6px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
}

.iconTable3 {
  background-color: #fff !important;
  color: rgb(0, 0, 0) !important;
  line-height: 22px;
  width: 25px;
  height: 22px;
  margin: 0 auto;
  border-radius: 6px;
  font-size: 16px;
  margin-left: 5px;
}

.iconTable6 {
  background-color: #e92917 !important;
  color: rgb(0, 0, 0) !important;
  line-height: 30px;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border-radius: 6px;
  font-size: 16px;
  margin-left: 5px;
}

.iconTable4 {
  background-color: transparent !important;
  color: rgb(0, 0, 0) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 12px;
  height: 34px;
  width: 30px;
}

.iconTable5 {
  background-color: transparent !important;
  color: #fff !important;
  padding: 0px;
  border-radius: 0px;
  font-size: 20px;
  padding-right: 5px;
  padding-left: 5px;

}

.iconTable5 i {
  font-size: 16px;
  font-weight: bold;
}

.iconTable2:hover {
  background-color: #c20000;
}

.btn-primary {
  background: var(--main-bg-color) !important;
  border: 1px solid var(--main-bg-color) !important;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 10px 35px !important;
  display: inline-block !important;
}

.btn-primary-small {
  background: var(--main-bg-color) !important;
  border: 1px solid var(--main-bg-color) !important;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 4px 20px !important;
  display: inline-block !important;
}

.btn-primary-alt {
  background: transparent !important;
  border: 1px solid var(--main-bg-color) !important;
  color: var(--main-bg-color);
  font-family: "Roboto", sans-serif !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 35px;
  display: inline-block;
}

.btn-primary-alt:hover {
  background: var(--main-bg-color) !important;
  border: 1px solid var(--main-bg-color) !important;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 35px;
  display: inline-block;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.bg_gray_section {
  //background: #fafafa;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.p-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.p-20 {
  padding: 10px;
}

.m-top-0 {
  margin-top: 0;
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-25 {
  margin-top: 25px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-35 {
  margin-top: 35px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-top-45 {
  margin-top: 45px;
}

.m-top-50 {
  margin-top: 50px;
}

.m-top-55 {
  margin-top: 55px;
}

.m-top-60 {
  margin-top: 60px;
}

.m-top-65 {
  margin-top: 65px;
}

.m-top-70 {
  margin-top: 70px;
}

.m-top-75 {
  margin-top: 75px;
}

.m-top-80 {
  margin-top: 80px;
}

.m-top-85 {
  margin-top: 85px;
}

.m-top-90 {
  margin-top: 90px;
}

.m-top-95 {
  margin-top: 95px;
}

.m-top-100 {
  margin-top: 100px;
}

.m-top-105 {
  margin-top: 105px;
}

.m-top-110 {
  margin-top: 110px;
}

.m-top-115 {
  margin-top: 115px;
}

.m-top-120 {
  margin-top: 120px;
}

.m-top-125 {
  margin-top: 125px;
}

.m-top-130 {
  margin-top: 130px;
}

.m-top-135 {
  margin-top: 135px;
}

.m-top-140 {
  margin-top: 140px;
}

.m-top-145 {
  margin-top: 145px;
}

.m-top-150 {
  margin-top: 150px;
}

.m-right-0 {
  margin-right: 0;
}

.m-right-5 {
  margin-right: 5px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-right-15 {
  margin-right: 15px;
}

.m-right-20 {
  margin-right: 20px;
}

.m-right-25 {
  margin-right: 25px;
}

.m-right-30 {
  margin-right: 30px;
}

.m-right-35 {
  margin-right: 35px;
}

.m-right-40 {
  margin-right: 40px;
}

.m-right-45 {
  margin-right: 45px;
}

.m-right-50 {
  margin-right: 50px;
}

.m-right-55 {
  margin-right: 55px;
}

.m-right-60 {
  margin-right: 60px;
}

.m-right-65 {
  margin-right: 65px;
}

.m-right-70 {
  margin-right: 70px;
}

.m-right-75 {
  margin-right: 75px;
}

.m-right-80 {
  margin-right: 80px;
}

.m-right-85 {
  margin-right: 85px;
}

.m-right-90 {
  margin-right: 90px;
}

.m-right-95 {
  margin-right: 95px;
}

.m-right-100 {
  margin-right: 100px;
}

.m-right-105 {
  margin-right: 105px;
}

.m-right-110 {
  margin-right: 110px;
}

.m-right-115 {
  margin-right: 115px;
}

.m-right-120 {
  margin-right: 120px;
}

.m-right-125 {
  margin-right: 125px;
}

.m-right-130 {
  margin-right: 130px;
}

.m-right-135 {
  margin-right: 135px;
}

.m-right-140 {
  margin-right: 140px;
}

.m-right-145 {
  margin-right: 145px;
}

.m-right-150 {
  margin-right: 150px;
}

.m-bottom-0 {
  margin-bottom: 0;
}

.m-bottom-5 {
  margin-bottom: 5px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-bottom-25 {
  margin-bottom: 25px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.m-bottom-35 {
  margin-bottom: 35px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.m-bottom-45 {
  margin-bottom: 45px;
}

.m-bottom-50 {
  margin-bottom: 50px;
}

.m-bottom-55 {
  margin-bottom: 55px;
}

.m-bottom-60 {
  margin-bottom: 60px;
}

.m-bottom-65 {
  margin-bottom: 65px;
}

.m-bottom-70 {
  margin-bottom: 70px;
}

.m-bottom-75 {
  margin-bottom: 75px;
}

.m-bottom-80 {
  margin-bottom: 80px;
}

.m-bottom-85 {
  margin-bottom: 85px;
}

.m-bottom-90 {
  margin-bottom: 90px;
}

.m-bottom-95 {
  margin-bottom: 95px;
}

.m-bottom-100 {
  margin-bottom: 100px;
}

.m-bottom-105 {
  margin-bottom: 105px;
}

.m-bottom-110 {
  margin-bottom: 110px;
}

.m-bottom-115 {
  margin-bottom: 115px;
}

.m-bottom-120 {
  margin-bottom: 120px;
}

.m-bottom-125 {
  margin-bottom: 125px;
}

.m-bottom-130 {
  margin-bottom: 130px;
}

.m-bottom-135 {
  margin-bottom: 135px;
}

.m-bottom-140 {
  margin-bottom: 140px;
}

.m-bottom-145 {
  margin-bottom: 145px;
}

.m-bottom-150 {
  margin-bottom: 150px;
}

.m-left-0 {
  margin-left: 0;
}

.m-left-5 {
  margin-left: 5px;
}

.m-left-10 {
  margin-left: 10px;
}

.m-left-15 {
  margin-left: 15px;
}

.m-left-20 {
  margin-left: 20px;
}

.m-left-25 {
  margin-left: 25px;
}

.m-left-30 {
  margin-left: 30px;
}

.m-left-35 {
  margin-left: 35px;
}

.m-left-40 {
  margin-left: 40px;
}

.m-left-45 {
  margin-left: 45px;
}

.m-left-50 {
  margin-left: 50px;
}

.m-left-55 {
  margin-left: 55px;
}

.m-left-60 {
  margin-left: 60px;
}

.m-left-65 {
  margin-left: 65px;
}

.m-left-70 {
  margin-left: 70px;
}

.m-left-75 {
  margin-left: 75px;
}

.m-left-80 {
  margin-left: 80px;
}

.m-left-85 {
  margin-left: 85px;
}

.m-left-90 {
  margin-left: 90px;
}

.m-left-95 {
  margin-left: 95px;
}

.m-left-100 {
  margin-left: 100px;
}

.m-left-105 {
  margin-left: 105px;
}

.m-left-110 {
  margin-left: 110px;
}

.m-left-115 {
  margin-left: 115px;
}

.m-left-120 {
  margin-left: 120px;
}

.m-left-125 {
  margin-left: 125px;
}

.m-left-130 {
  margin-left: 130px;
}

.m-left-135 {
  margin-left: 135px;
}

.m-left-140 {
  margin-left: 140px;
}

.m-left-145 {
  margin-left: 145px;
}

.m-left-150 {
  margin-left: 150px;
}

.p-left-0 {
  padding-left: 0;
}

.p-left-5 {
  padding-left: 5px;
}

.p-left-10 {
  padding-left: 10px;
}

.p-left-15 {
  padding-left: 15px;
}

.p-left-20 {
  padding-left: 20px;
}

.p-left-25 {
  padding-left: 25px;
}

.p-left-30 {
  padding-left: 30px;
}

.p-left-35 {
  padding-left: 35px;
}

.p-left-40 {
  padding-left: 40px;
}

.p-left-45 {
  padding-left: 45px;
}

.p-left-50 {
  padding-left: 50px;
}

.p-left-55 {
  padding-left: 55px;
}

.p-left-60 {
  padding-left: 60px;
}

.p-left-65 {
  padding-left: 65px;
}

.p-left-70 {
  padding-left: 70px;
}

.p-left-75 {
  padding-left: 75px;
}

.p-left-80 {
  padding-left: 80px;
}

.p-left-85 {
  padding-left: 85px;
}

.p-left-90 {
  padding-left: 90px;
}

.p-left-95 {
  padding-left: 95px;
}

.p-left-100 {
  padding-left: 100px;
}

.p-left-105 {
  padding-left: 105px;
}

.p-left-110 {
  padding-left: 110px;
}

.p-left-115 {
  padding-left: 115px;
}

.p-left-120 {
  padding-left: 120px;
}

.p-left-125 {
  padding-left: 125px;
}

.p-left-130 {
  padding-left: 130px;
}

.p-left-135 {
  padding-left: 135px;
}

.p-left-140 {
  padding-left: 140px;
}

.p-left-145 {
  padding-left: 145px;
}

.p-left-150 {
  padding-left: 150px;
}

.p-right-0 {
  padding-right: 0;
}

.p-right-5 {
  padding-right: 5px;
}

.p-right-10 {
  padding-right: 10px;
}

.p-right-15 {
  padding-right: 15px;
}

.p-right-20 {
  padding-right: 20px;
}

.p-right-25 {
  padding-right: 25px;
}

.p-right-30 {
  padding-right: 30px;
}

.p-right-35 {
  padding-right: 35px;
}

.p-right-40 {
  padding-right: 40px;
}

.p-right-45 {
  padding-right: 45px;
}

.p-right-50 {
  padding-right: 50px;
}

.p-right-55 {
  padding-right: 55px;
}

.p-right-60 {
  padding-right: 60px;
}

.p-right-65 {
  padding-right: 65px;
}

.p-right-70 {
  padding-right: 70px;
}

.p-right-75 {
  padding-right: 75px;
}

.p-right-80 {
  padding-right: 80px;
}

.p-right-85 {
  padding-right: 85px;
}

.p-right-90 {
  padding-right: 90px;
}

.p-right-95 {
  padding-right: 95px;
}

.p-right-100 {
  padding-right: 100px;
}

.p-right-105 {
  padding-right: 105px;
}

.p-right-110 {
  padding-right: 110px;
}

.p-right-115 {
  padding-right: 115px;
}

.p-right-120 {
  padding-right: 120px;
}

.p-right-125 {
  padding-right: 125px;
}

.p-right-130 {
  padding-right: 130px;
}

.p-right-135 {
  padding-right: 135px;
}

.p-right-140 {
  padding-right: 140px;
}

.p-right-145 {
  padding-right: 145px;
}

.p-right-150 {
  padding-right: 150px;
}

.p-top-0 {
  padding-top: 0;
}

.p-top-5 {
  padding-top: 5px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-top-15 {
  padding-top: 15px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-top-25 {
  padding-top: 25px;
}

.p-top-30 {
  padding-top: 30px;
}

.p-top-35 {
  padding-top: 35px;
}

.p-top-40 {
  padding-top: 40px;
}

.p-top-45 {
  padding-top: 45px;
}

.p-top-50 {
  padding-top: 50px;
}

.p-top-55 {
  padding-top: 55px;
}

.p-top-60 {
  padding-top: 60px;
}

.p-top-65 {
  padding-top: 65px;
}

.p-top-70 {
  padding-top: 70px;
}

.p-top-75 {
  padding-top: 75px;
}

.p-top-80 {
  padding-top: 80px;
}

.p-top-85 {
  padding-top: 85px;
}

.p-top-90 {
  padding-top: 90px;
}

.p-top-95 {
  padding-top: 95px;
}

.p-top-100 {
  padding-top: 100px;
}

.p-top-105 {
  padding-top: 105px;
}

.p-top-110 {
  padding-top: 110px;
}

.p-top-115 {
  padding-top: 115px;
}

.p-top-120 {
  padding-top: 120px;
}

.p-top-125 {
  padding-top: 125px;
}

.p-top-130 {
  padding-top: 130px;
}

.p-top-135 {
  padding-top: 135px;
}

.p-top-140 {
  padding-top: 140px;
}

.p-top-145 {
  padding-top: 145px;
}

.p-top-150 {
  padding-top: 150px;
}

.p-bottom-0 {
  padding-bottom: 0;
}

.p-bottom-5 {
  padding-bottom: 5px;
}

.p-bottom-10 {
  padding-bottom: 10px;
}

.p-bottom-15 {
  padding-bottom: 15px;
}

.p-bottom-20 {
  padding-bottom: 20px;
}

.p-bottom-25 {
  padding-bottom: 25px;
}

.p-bottom-30 {
  padding-bottom: 30px;
}

.p-bottom-35 {
  padding-bottom: 35px;
}

.p-bottom-40 {
  padding-bottom: 40px;
}

.p-bottom-45 {
  padding-bottom: 45px;
}

.p-bottom-50 {
  padding-bottom: 50px;
}

.p-bottom-55 {
  padding-bottom: 55px;
}

.p-bottom-60 {
  padding-bottom: 60px;
}

.p-bottom-65 {
  padding-bottom: 65px;
}

.p-bottom-70 {
  padding-bottom: 70px;
}

.p-bottom-75 {
  padding-bottom: 75px;
}

.p-bottom-80 {
  padding-bottom: 80px;
}

.p-bottom-85 {
  padding-bottom: 85px;
}

.p-bottom-90 {
  padding-bottom: 90px;
}

.p-bottom-95 {
  padding-bottom: 95px;
}

.p-bottom-100 {
  padding-bottom: 100px;
}

.p-bottom-105 {
  padding-bottom: 105px;
}

.p-bottom-110 {
  padding-bottom: 110px;
}

.p-bottom-115 {
  padding-bottom: 115px;
}

.p-bottom-120 {
  padding-bottom: 120px;
}

.p-bottom-125 {
  padding-bottom: 125px;
}

.p-bottom-130 {
  padding-bottom: 130px;
}

.p-bottom-135 {
  padding-bottom: 135px;
}

.p-bottom-140 {
  padding-bottom: 140px;
}

.p-bottom-145 {
  padding-bottom: 145px;
}

.p-bottom-150 {
  padding-bottom: 150px;
}

.h-5 {
  height: 5px;
}

.h-10 {
  height: 10px;
}

.h-15 {
  height: 15px;
}

.h-20 {
  height: 20px;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px;
}

.h-35 {
  height: 35px;
}

.h-40 {
  height: 40px;
}

.h-45 {
  height: 45px;
}

.h-50 {
  height: 50px;
}

.h-55 {
  height: 55px;
}

.h-60 {
  height: 60px;
}

.h-65 {
  height: 65px;
}

.h-70 {
  height: 70px;
}

.h-75 {
  height: 75px;
}

.h-80 {
  height: 80px;
}

.h-85 {
  height: 85px;
}

.h-90 {
  height: 90px;
}

.h-95 {
  height: 95px;
}

.h-100 {
  height: 100px;
}

.h-105 {
  height: 105px;
}

.h-110 {
  height: 110px;
}

.h-115 {
  height: 115px;
}

.h-120 {
  height: 120px;
}

.h-125 {
  height: 125px;
}

.h-130 {
  height: 130px;
}

.h-135 {
  height: 135px;
}

.h-140 {
  height: 140px;
}

.h-145 {
  height: 145px;
}

.h-150 {
  height: 150px;
}

/*----------------------------------------*/

/*  Auto Default CSS
/*----------------------------------------*/

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Roboto", sans-serif;
  color: #000000;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 0;
}

h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  line-height: 36px;
}

h3 {
  font-size: 30px;
  line-height: 30px;
}

h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

h5 {
  font-size: 14px;
  line-height: 18px;
}

h6 {
  font-size: 12px;
  line-height: 14px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input,
span {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

figure {
  padding: 0;
  margin: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.container {
  //max-width: 1430px !important;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 30px;
}

.container.catalog {

  // padding-left: 0 !important;
}




@media only screen and (max-width: 479px) {
  .container {
    width: 100% !important;
    max-width: 100% !important;

  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

/*-- 
    - Background color
-----------------------------------------*/
.ant-form-item-label {
  line-height: 0px !important;
}

.ant-form-item-label>label {
  color: rgb(0, 0, 0);
  // margin-bottom: 0 !important;
}

.dropdown-item {
  color: #000;
}

.ant-col {
  color: #000;
}



.scroll-hidden {
  overflow: auto;
  min-height: 54px;

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  -webkit-overflow-scrolling: touch;
  transition: all 300ms;

}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}


.ant-tag {
  // color: #000;

  padding: 4px 4px;
}

.ant-tag.big {
  max-width: 120px;
  min-width: 120px;
  padding: 4px 4px;
  border: 0px solid #000;

}

.ant-tag.small {

  padding: 2px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-light-grey {
  background-color: #f6fafb;
}

.bg-grey {
  background-color: #f8f8f8;
}

.bluewood-bg {
  background: #354b65;
}

/*- Overlay Color BG -*/

.bluewood-bg-overlay {
  position: relative;
}

.bluewood-bg-overlay::before {
  background: rgba(70, 90, 112, 0.9);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overly-bg-black {
  position: relative;
}

.overly-bg-black::after {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/*-- 
    - color
-----------------------------------------*/

/*-- 
    - Input Placeholder
-----------------------------------------*/

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*-- 
    Scroll Up 
-----------------------------------------*/

#scrollUp {
  background: var(--main-bg-color) none repeat scroll 0 0;
  bottom: 85px;
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 45px;
  line-height: 39px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 45px;
  z-index: 9999;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#scrollUp:hover i {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/*-- 
    - Main Wrapper
-----------------------------------------*/

.main-wrapper.header-transparent {
  padding: 0 !important;
  margin: 0 !important;
}

/*-- 
    - Section Padding
-------------------------------------*/

.section-ptb {
  padding: 100px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-ptb {
    padding: 90px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-ptb {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-ptb {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 479px) {
  .section-ptb {
    padding: 60px 0;
  }
}

.section-pt {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt {
    padding-top: 60px;
  }
}

.section-pt-70 {
  padding-top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt-70 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt-70 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt-70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt-70 {
    padding-top: 30px;
  }
}

.section-pb-70 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb-70 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb-70 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb-70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb-70 {
    padding-bottom: 30px;
  }
}

.section-pb {
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

.pb-60 {
  padding-bottom: 60px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-23 {
  margin-top: 23px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-57 {
  margin-top: 57px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-58 {
  margin-bottom: 58px;
}

.mb-46 {
  margin-bottom: 46px;
}

.mb-47 {
  margin-bottom: 47px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-66 {
  margin-bottom: 66px;
}

.mb-68 {
  margin-bottom: 68px;
}

.mb-70 {
  margin-bottom: 70px;
}

/* 02. Element */

.button {
  background: var(--main-bg-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 22px;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 3px;
}

/* 2. Home 1 Header css here */

/* 01. header css here */

.off_canvas_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #000000;
  top: 0;
}

.off_canvas_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.off_canvas_menu {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .off_canvas_menu {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .off_canvas_menu {
    display: block;
  }
}

.off_canvas_menu_wrapper {
  width: 290px;
  position: fixed;
  background: #fff;
  z-index: 99;
  top: 0;
  height: calc((100vh / var(--zoom)) / var(--zoom));
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  left: 0;
  margin-left: -300px;
  padding: 50px 20px 30px;
  overflow-y: auto;
}

.off_canvas_menu_wrapper.active {
  margin-left: 0;
}

.off_canvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}

.off_canvas_menu_wrapper .search_container {
  display: block;
}

.off_canvas_menu_wrapper .header_configure_area {
  display: block;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}

.offcanvas_main_menu>li.menu-item-has-children.menu-open>span.menu-expand {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas_main_menu>li ul li.menu-item-has-children.menu-open span.menu-expand {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas_main_menu li {
  position: relative;
}

.offcanvas_main_menu li:last-child {
  margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}

.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.offcanvas_main_menu li a:hover {
  color: var(--main-bg-color);
}

.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.Offcanvas_footer {
  margin-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.Offcanvas_footer span a {
  font-size: 14px;
}

.Offcanvas_footer span a:hover {
  color: var(--main-bg-color);
}

.Offcanvas_footer ul {
  margin-top: 20px;
}

.Offcanvas_footer ul li {
  display: inline-block;
  margin-right: 4px;
}

.Offcanvas_footer ul li:last-child {
  margin-right: 0;
}

.Offcanvas_footer ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #ffffff;
}

.Offcanvas_footer ul li.facebook a {
  background: #3c5b9b;
}

.Offcanvas_footer ul li.facebook a:hover {
  background: #3c5b9b;
}

.Offcanvas_footer ul li.youtube a {
  background: red;
}

.Offcanvas_footer ul li.twitter a {
  background: #1da1f2;
}

.Offcanvas_footer ul li.twitter a:hover {
  background: var(--main-bg-color);
}

.Offcanvas_footer ul li.pinterest a {
  background: #bd081b;
}

.Offcanvas_footer ul li.pinterest a:hover {
  background: var(--main-bg-color);
}

.Offcanvas_footer ul li.google-plus a {
  background: #dd4d42;
}

.Offcanvas_footer ul li.google-plus a:hover {
  background: var(--main-bg-color);
}

.Offcanvas_footer ul li.linkedin a {
  background: #000;
}

.Offcanvas_footer ul li.linkedin a:hover {
  background: var(--main-bg-color);
}

.slinky-theme-default a:not(.back) {
  padding: 10px 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.slinky-theme-default a:not(.back):hover {
  background: inherit;
  color: var(--main-bg-color);
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: 13px;
}

.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 32px;
  height: 32px;
  display: block;
  text-align: center;
  line-height: 31px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
}

.canvas_close a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #fff;
}

.canvas_open {
  position: absolute;
  right: 21px;
  top: 32px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .canvas_open {
    right: 24px;
    top: 24px;
  }
}

.canvas_open a {
  font-size: 30px;
  color: #000000;
  width: 43px;
  height: 41px;
  display: block;
  line-height: 39px;
  text-align: center;
  border: 1px solid #000000;
}

@media only screen and (max-width: 767px) {
  .canvas_open a {
    width: 35px;
    height: 34px;
    line-height: 34px;
    font-size: 24px;
  }
}

.canvas_open a:hover {
  color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.off_canvas_menu_two .canvas_close a:hover {
  background: #0066c0;
  border-color: #0066c0;
}

.off_canvas_menu_two .offcanvas_main_menu li a:hover {
  color: #0066c0;
}

.off_canvas_menu_two .Offcanvas_footer span a:hover {
  color: #0066c0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_header {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main_header {
    padding-bottom: 25px;
  }
}

.main_header.header_padding {
  padding-bottom: 0;
}

.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 900ms;
  animation-duration: 900ms;
  -webkit-animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  border-bottom: 0;
  display: block;
  padding: 6px 20px;
  left: 0;
  right: 0;
}





.sticky_header_area {
  display: none;
}

.sticky_header_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.sticky_header_right .main_menu {
  margin-left: 0;
  margin-right: 50px;
}



.sticky_header_right .main_menu nav>ul>li>a {
  color: var(--main-color-in-bg);

}



.sticky_header_right .main_menu nav>ul>li>a.active {
  color: var(--main-bg-color);
}

.sticky_header_right .main_menu nav>ul>li>a::before {
  display: none;
}

.sticky_header_right .main_menu nav>ul>li:hover>a {
  color: var(--main-bg-color);
}

/*header top css here*/

.header_top {
  background: #000000;
  color: #d6d6d6 !important;
  height: 50px;
}


@media only screen and (max-width: 767px) {
  .antomi_message {
    text-align: center;
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .antomi_message {
    text-align: center;
    margin-bottom: 18px;
  }
}

.antomi_message p {
  font-size: 14px;
  line-height: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .antomi_message p {
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .antomi_message p {
    font-size: 13px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .antomi_message p {
    font-size: 13px;
    line-height: 21px;
  }
}

@media only screen and (max-width: 767px) {
  .header_top_settings {
    text-align: center !important;
    margin-bottom: 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_top_settings {
    text-align: center !important;
    margin-bottom: 21px;
  }
}

.header_top_settings ul li {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
}

.header_top_settings ul li a {
  font-size: 14px;
  line-height: 50px;
  color: #d6d6d6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_top_settings ul li {
    font-size: 13px;
    margin-right: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_top_settings ul li {
    font-size: 13px;
    line-height: 18px;
    margin-right: 15px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_top_settings ul li {
    font-size: 13px;
    line-height: 18px;
    margin-right: 15px;
    padding-right: 0;
  }
}

.header_top_settings ul li::before {
  background: #ebebeb;
  display: block;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  z-index: 9;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_top_settings ul li::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_top_settings ul li::before {
    display: none;
  }
}

.header_top_settings ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.header_top_settings ul li:last-child::before {
  display: none;
}

.header_top_settings ul li a:hover {
  color: var(--main-bg-color);
}

/*header top css end*/

/* 2.2 header middel css here */
.logo {
  text-align: center;


}

.logo img {
  width: auto;
  max-height: 60px;


}




.header_middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;

  text-align: center;
  position: relative;
  background-color: #000;
  // border-bottom: 2px solid #000 ;
}


/* 2.4 main menu css here */

.menu_position {
  position: relative;
}

.main_menu {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main_menu {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main_menu {
    display: none;
  }
}

.main_menu nav>ul>li {
  display: inline-block;
  position: relative;
  margin-right: 25px;
  padding: 8px 0;
}

.main_menu nav>ul>li:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .main_menu nav>ul>li {
    margin-right: 32px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main_menu nav>ul>li {
    margin-right: 26px;
  }
}

.main_menu nav>ul>li:hover ul.sub_menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(600px) rotateX(0deg);
  transform: perspective(600px) rotateX(0deg);
}

.main_menu nav>ul>li:hover .mega_menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(600px) rotateX(0deg);
  transform: perspective(600px) rotateX(0deg);
}

.main_menu nav>ul>li:hover>a::before {
  left: 0;
  right: 0;
  width: 100%;
}

.main_menu nav>ul>li>a {
  display: block;
  font-size: 14px !important;
  line-height: 0px;
  text-transform: uppercase;
  font-weight: 600 !important;
  position: relative;
  color: var(--main-color-in-bg)
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main_menu nav>ul>li>a {
    font-size: 13px;
  }
}

.main_menu nav>ul>li>a i {
  margin-left: 3px;
}

.main_menu nav>ul>li>a.active::before {
  left: 0;
  right: 0;
  width: 100%;
}

.main_menu nav>ul>li>a::before {
  background: var(--main-color-in-bg);
  bottom: -15px;
  content: "";
  display: block;
  height: 4px;
  left: 50%;
  position: absolute;
  right: 50%;
  top: auto;
  width: 0;
  z-index: 9;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.main_menu nav>ul>li ul.sub_menu {
  position: absolute;
  min-width: 200px;
  padding: 24px 30px;
  background: #fff;
  border: 1px solid #ebebeb;
  -webkit-transform: perspective(600px) rotateX(-90deg);
  transform: perspective(600px) rotateX(-90deg);
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.main_menu nav>ul>li ul.sub_menu li a {
  font-size: 13px;
  font-weight: 400;
  display: block;
  line-height: 32px;
  text-transform: capitalize;
  font-size: 13px;
}

.main_menu nav>ul>li ul.sub_menu li a:hover {
  color: var(--main-bg-color);
}

.main_menu nav>ul>li.mega_items {
  position: static;
}

.main_menu nav>ul>li .mega_menu {
  position: absolute;
  min-width: 100%;
  padding: 25px 30px 30px 30px;
  background: #fff;
  border: 1px solid #ebebeb;
  -webkit-transform: perspective(600px) rotateX(-90deg);
  transform: perspective(600px) rotateX(-90deg);
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega_menu_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mega_menu_inner>li>a {
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.mega_menu_inner>li>a:hover {
  color: var(--main-bg-color);
}

.mega_menu_inner>li ul li {
  display: block;
}

.mega_menu_inner>li ul li a {
  font-size: 13px;
  font-weight: 400;
  display: block;
  line-height: 28px;
  text-transform: capitalize;
}

.mega_menu_inner>li ul li a:hover {
  color: var(--main-bg-color);
}

/*main menu css end*/

.header_configure_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  justify-content: center;
  align-items: center;

  height: 100%;

}






.header_addons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 4px;
  margin-right: 10px !important;
  border-radius: 5px;
  background-color: #eee;
  border: 1px solid #ddd;
  z-index: 99;
  height: 38px;
  width: 38px;
  /*    background-image: url(./shared/assets/images/filet.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center; */

  .anticon {

    color: #000;
    font-size: 30px;
  }

  .anticon:hover {
    color: var(--main-bg-color) !important;
  }
}

.header_configure_area .header_addons:last-child {

  margin-right: 0px !important;
}


.header_addons .user .dropdown-toggle::after {
  display: none;
}

.user_list {
  top: 10px !important;
  right: 0px !important;
  padding: 0px;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -o-max-content;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 13.5px 1.5px rgba(210, 210, 210, 0.07);
  overflow: hidden;
}

.user_list a {
  border: none !important;
  display: flex;
  align-items: center;
  height: 50px !important;
  line-height: 50px !important;
  width: 100% !important;
  text-align: left !important;
  border-radius: 0 !important;
  color: #000000 !important;
}

.user_list .dropdown-item.active,
.dropdown-item:active {
  color: #000000 !important;
  text-decoration: none !important;
  background-color: transparent !important;
}





@media only screen and (max-width: 1040px) {
  .header_bottom .header_addons:not(:last-child) {
    display: none;
  }


  .logo {
    display: none;
  }

}

.header_addons a {
  height: 30px;
  min-height: 30px;

  line-height: 26px;
  position: relative;
  text-align: center;
  width: 30px;
  min-width: 30px;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #000000;
}








.select-box {
  width: 50px;
  height: 50px;

  &-btn {
    line-height: 33px;
  }
}

.header_addons img {

  max-height: none !important;
  max-width: none !important;

  //filter: invert(1) sepia(1) saturate(1) hue-rotate(175deg);
}

.header_addons a span.wishlist_count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--main-bg-color);
  color: #fff;
  font-size: 11px;
  height: 18px;
  width: 25px;
  right: -12px;
  top: -10px;
  line-height: 22px;
  position: absolute;
  text-align: center;

  z-index: 18;

  border-radius: 25px;
}





.mini_cart_wrapper {
  position: relative;
}

.mini_cart_wrapper>a:hover {
  color: var(--main-bg-color);
}

.mini_cart_wrapper>a i.ion-ios-arrow-down {
  margin-left: 4px;
}

.mini_cart_wrapper>a i.cart {
  border: 2px solid #ebebeb;
  display: inline-block;
  height: 50px;
  line-height: 46px;
  text-align: center;
  width: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mini_cart_wrapper>a i.cart {
    height: 46px;
    width: 46px;
    line-height: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mini_cart_wrapper>a i.cart {
    height: 40px;
    line-height: 36px;
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .mini_cart_wrapper>a i.cart {
    height: 40px;
    line-height: 36px;
    width: 40px;
  }
}

.mini_cart_wrapper>a span.cart_count {
  background: var(--main-bg-color);
  color: #fff;
  font-size: 13px;
  height: 22px;
  left: -12px;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  width: 22px;
  z-index: 9;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mini_cart_wrapper>a span.cart_count {
    font-size: 12px;
    height: 20px;
    left: -16px;
    line-height: 20px;
    width: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .mini_cart_wrapper>a span.cart_count {
    font-size: 12px;
    height: 20px;
    left: -16px;
    line-height: 20px;
    width: 20px;
  }
}

.mini_cart_wrapper>a span.cart_price {
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mini_cart_wrapper>a span.cart_price {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .mini_cart_wrapper>a span.cart_price {
    font-size: 13px;
  }
}

.mini_cart_wrapper:hover .mini_cart {
  max-height: 532px;
  padding: 18px 30px 30px;
  visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mini_cart_wrapper:hover .mini_cart {
    padding: 10px 15px 15px;
    visibility: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .mini_cart_wrapper:hover .mini_cart {
    padding: 10px 15px 15px;
    visibility: inherit;
  }
}

.mini_cart {
  position: absolute;
  min-width: 355px;
  padding: 0 30px 0;
  background: #fff;
  border: 0;
  z-index: 999;
  right: 0;
  top: 115%;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mini_cart {
    display: none;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    max-height: 500px;
    min-width: 260px;
    border: 1px solid #ebebeb;
    left: -108px;
    visibility: inherit;
    padding: 10px 15px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .mini_cart {
    display: none;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    max-height: 500px;
    min-width: 260px;
    border: 1px solid #ebebeb;
    left: -108px;
    visibility: inherit;
    padding: 10px 15px 15px;
  }
}

.cart_img {
  width: 90px;
  margin-right: 10px;
  border: 1px solid #ebebeb;
}

.cart_img:hover {
  border-color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart_img {
    width: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .cart_img {
    width: 70px;
  }
}

.cart_info {
  width: 63%;
}

.cart_info a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-bottom: 6px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart_info a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .cart_info a {
    font-size: 13px;
  }
}

.cart_info a:hover {
  color: var(--main-bg-color);
}

.cart_info p {
  font-size: 12px;
}

.cart_info p span {
  font-weight: 600;
}

.cart_remove a {
  font-size: 15px;
  border: 1px solid #ebebeb;
  width: 20px;
  height: 20px;
  display: block;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
}

.cart_remove a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #ffffff;
}

.cart_item {
  overflow: hidden;
  padding: 11px 0;
  border-bottom: 1px solid #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mini_cart_table {
  padding: 23px 0;
}

.cart_total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_total span {
  font-size: 14px;
  font-weight: 400;
}

.cart_total span.price {
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart_total span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .cart_total span {
    font-size: 13px;
  }
}

.cart_button:first-child {
  margin-bottom: 15px;
}

.cart_button a {
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  background: #eef0f1;
  border: 1px solid #ebebeb;
  display: block;
  text-align: center;
  line-height: 20px;
  margin-bottom: 0;
  padding: 13px 0px 11px;
}

.cart_button a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #ffffff;
}

.create_order_button_active {
  background: var(--main-bg-color);
  color: #ffffff;
}

/*header middel css end*/

/*mobaile menu css here*/

.mean-container .mean-bar {
  background: inherit;
  position: absolute;
  z-index: 9;
  top: 43px;
  left: 0;
  width: 100%;
}

.mean-container .mean-bar::after {
  top: -33px;
  left: 22px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .mean-container .mean-bar::after {
    left: 22px;
    font-size: 15px;
  }
}

.mean-container .mean-nav {
  max-height: 300px;
  overflow: auto;
}

.mean-container .mean-nav ul li a {
  font-weight: 400;
}

.mean-container a.meanmenu-reveal {
  float: left;
  right: 17px;
  top: -34px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .mean-container a.meanmenu-reveal {
    right: 15px;
    top: -34px;
  }
}

.mean-container a.meanmenu-reveal span {
  background: #fff;
}

.mobile-menu {
  border: 1px solid #ddd;
  height: 45px;
  top: -31px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .mobile-menu {
    top: 19px;
  }
}

/*header bottom css heer*/

.header_bottom {
  position: sticky;
  top: 0;
  background: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
  height: 60px;
  //border-bottom: 5px solid var(--main-bg-color);
}

.header_bottom .column1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21%;
  flex: 0 0 21%;
  max-width: 21%;
}

.column1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21%;
  flex: 0 0 21%;
  max-width: 21%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom .column1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .column1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .header_bottom .column1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .column1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header_bottom .column2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  max-width: 56%;
}

.column2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  max-width: 56%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom .column2 {
    display: none;
  }
}

.header_bottom .column3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18%;
  flex: 0 0 18%;
  max-width: 18%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom .column3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .header_bottom .column3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*search container css end*/

.header_bigsale a {
  background: #333;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.header_bigsale a:hover {
  background: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bigsale a {
    height: 45px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .header_bigsale a {
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }
}

/*-- 2.3 Categories menu css here --*/

.categories_menu {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .categories_menu {
    margin-bottom: 0;
  }
}

.categories_title {
  background: var(--main-bg-color) !important;
  // border-radius: 15px;
  //padding: 0 30px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  text-align: left;
  cursor: pointer;
  //line-height: 45px;
  // height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_title {
    height: 35px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_title {
    height: 35px;
    line-height: 22px;
  }
}

/* .categories_title::before {
  content: "\f394";
  color: #fff;
  display: inline-block;
  font-family: Ionicons;
  position: absolute;
  font-size: 22px;
  line-height: 0px;
  right: 20px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
} */

.categories_title h2 {
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_title h2 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_title h2 {
    font-size: 13px;
    line-height: 22px;
  }
}

.categories_menu_toggle {
  padding: 13px 0 7px;
  border: 2px solid var(--main-bg-color);
  background: #fff;
  position: absolute;
  width: 100%;
  top: 140%;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .categories_menu_toggle {
    padding: 11px 0 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle {
    display: none;
    max-height: 350px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle {
    display: none;
    max-height: 350px;
    overflow: auto;
    padding: 13px 10px 7px 20px;
  }
}

.categories_menu_toggle>ul>li {
  position: relative;
}

.categories_menu_toggle>ul>li>a {
  font-size: 14px;
  line-height: 43px;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  cursor: pointer;
  padding: 0 20px 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 41px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 34px;
    padding: 0 6px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 35px;
    padding: 0;
  }
}

.categories_menu_toggle>ul>li>a i.fa-angle-right {
  float: right;
  font-size: 15px;
  line-height: 35px;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li>a i.fa-angle-right {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a i.fa-angle-right {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li>a:hover {
    color: var(--main-bg-color);
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a:hover {
    color: var(--main-bg-color);
  }
}

.categories_menu_toggle>ul>li:last-child>a {
  border-bottom: 0;
}

.categories_menu_toggle>ul>li:hover ul.categories_mega_menu {
  opacity: 1;
  visibility: visible;
  left: 100%;
}

.categories_menu_toggle>ul>li:hover>a {
  color: var(--main-bg-color);
}

.categories_menu_toggle>ul>li ul.categories_mega_menu {
  position: absolute;
  left: 120%;
  width: 720px;
  border: 1px solid #ebebeb;
  background: #fff;
  padding: 25px 20px 23px;
  overflow: hidden;
  top: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    width: 680px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 0px 22px;
    border: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 0px 40px;
    border: 0;
  }
}

.categories_menu_toggle>ul>li ul.categories_mega_menu.open {
  display: block;
  left: 0;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li {
  padding: 0 15px;
  width: 25%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li {
    width: 100%;
    padding: 0;
  }
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li>a {
  line-height: 30px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li>a:hover {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li.menu_item_children>a {
    text-transform: capitalize;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li.menu_item_children>a {
    text-transform: capitalize;
    font-weight: 400;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li.menu_item_children>a {
    position: relative;
  }

  .categories_menu_toggle ul li.menu_item_children>a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    -webkit-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li.menu_item_children>a {
    position: relative;
  }

  .categories_menu_toggle ul li.menu_item_children>a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    -webkit-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li.menu_item_children.open>a::before {
    display: none;
  }

  .categories_menu_toggle ul li.menu_item_children.open>a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    -webkit-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li.menu_item_children.open>a::before {
    display: none;
  }

  .categories_menu_toggle ul li.menu_item_children.open>a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    -webkit-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub_menu {
    display: none;
    padding: 1px 0 9px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_sub_menu {
    display: none;
    padding: 1px 0 9px 30px;
  }
}

.categorie_sub_menu li a {
  color: #000000;
  text-transform: capitalize;
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
}

.categorie_sub_menu li a:hover {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub_menu li a {
    line-height: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_sub_menu li a {
    line-height: 33px;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
  width: 620px;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
  width: 33.33%;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
    width: 100%;
  }
}

.categorie_sub {
  opacity: 0;
  z-index: 999;
  position: absolute;
  right: -2px;
  left: -2px;
  border-left: 2px solid var(--main-bg-color);
  border-right: 2px solid var(--main-bg-color);
  border-bottom: 2px solid var(--main-bg-color);
  background: #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  top: 65%;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub {
    width: 100%;
    left: 0px;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
}

.categorie_sub.open {
  top: 95%;
  opacity: 1;
  visibility: visible;
}

.categories_menu_toggle ul li.has-sub>a::before {
  position: absolute;
  content: "\f055";
  font-family: FontAwesome;
  top: 0;
  right: 20px;
  font-size: 18px;
  pointer-events: none;
}

.categories_menu_toggle ul li.has-sub>a.open::before {
  content: "\f056";
}

.categories_menu_toggle ul li.has-sub ul.categorie_sub li a {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 16px;
  padding-left: 30px;
  display: block;
}

.categories_menu_toggle ul li.has-sub ul.categorie_sub li a:hover {
  color: var(--main-bg-color);
}

.categories_menu_toggle ul li.has-sub {
  padding-bottom: 10px;
}

.categories_menu_toggle ul li ul.categories_mega_menu>li:first-child {
  padding-left: 0;
}

.categories_menu_toggle ul li ul.categories_mega_menu>li:last-child {
  padding-right: 0;
}

.categorie_banner {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .categorie_banner {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_banner {
    display: none;
  }
}

.categorie_banner a img:hover {
  opacity: 0.8;
}

/*-- 2.7 Categories menu css end --*/

/*home two css here*/

.categories_three .categories_menu_toggle {
  display: none;
}

/*home four css here*/

.Offcanvas_four .canvas_open a {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.Offcanvas_four .canvas_open a:hover {
  color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.sticky_header_four {
  display: none;
}

.sticky_header_four.sticky-header.sticky {
  display: block;
}

.header_four {
  background: #232f3e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header_four {
    margin-bottom: 30px;
  }
}

.header_four .header_top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header_four .antomi_message p {
  color: #ffffff;
}

.header_four .header_top_settings ul li {
  color: #ffffff;
}

.header_middle_style4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_middle_style4 {
    border-bottom: 0;
  }
}

.header_middle_style4 .column1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21%;
  flex: 0 0 21%;
  max-width: 21%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_middle_style4 .column1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .header_middle_style4 .column1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header_middle_style4 .column2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  max-width: 56%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_middle_style4 .column2 {
    display: none;
  }
}

.header_middle_style4 .column3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18%;
  flex: 0 0 18%;
  max-width: 18%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_middle_style4 .column3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .header_middle_style4 .column3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header_middle_style4 .search_container {
  padding-right: 10px;
}

.menu_four nav>ul>li>a {
  color: #ffffff;
}

.menu_four nav>ul>li>a::before {
  background: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_configure_four .header_addons {
    margin-right: 25px;
  }
}

.header_configure_four .header_addons a {
  color: #ffffff;
}

.header_configure_four .header_addons a:hover {
  color: var(--main-bg-color);
}

.header_configure_four .mini_cart_wrapper>a {
  color: #ffffff;
}

.header_configure_four .mini_cart_wrapper>a:hover {
  color: var(--main-bg-color);
}

.categories_four .categories_title {
  background: #232f3e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_four .categories_title {
    background: var(--main-bg-color);
  }
}

@media only screen and (max-width: 767px) {
  .categories_four .categories_title {
    background: var(--main-bg-color);
  }
}

.categories_four .categories_title h2 {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.categories_four .categories_title h2:hover {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_four .categories_title h2:hover {
    color: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .categories_four .categories_title h2:hover {
    color: #ffffff;
  }
}

.categories_four .categories_menu_toggle {
  top: 100%;
  display: none;
}

.h_bigsale_four a {
  background: none;
  color: #fbcf00;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h_bigsale_four a {
    background: var(--main-bg-color);
    color: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .h_bigsale_four a {
    background: var(--main-bg-color);
    color: #ffffff;
  }
}

.h_bigsale_four a:hover {
  background: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h_bigsale_four a:hover {
    background: var(--main-bg-color);
  }
}

@media only screen and (max-width: 767px) {
  .h_bigsale_four a:hover {
    background: var(--main-bg-color);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_padding {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_padding {
    padding-bottom: 0;
  }
}

/*extra css heer*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account {
    display: none;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .header_account {
    display: none;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account.bottom {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header_account.bottom {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account.bottom>ul>li>a i {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .header_account.bottom>ul>li>a i {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account.bottom>ul>li>a i.ion-chevron-down {
    font-size: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .header_account.bottom>ul>li>a i.ion-chevron-down {
    font-size: 10px;
  }
}

.header_account>ul>li {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_account>ul>li {
    margin-right: 12px;
    padding-right: 12px;
  }
}

.header_account>ul>li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.header_account>ul>li:last-child::before {
  display: none;
}

.header_account>ul>li::before {
  content: "";
  display: block;
  width: 1px;
  background: #ccc;
  height: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 9;
  right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account>ul>li::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_account>ul>li::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account>ul>li {
    margin-right: 0;
  }

  .header_account>ul>li:hover>a {
    color: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .header_account>ul>li {
    margin-right: 0;
  }
}

.header_account>ul>li:hover ul.dropdown_currency,
.header_account>ul>li:hover ul.dropdown_language,
.header_account>ul>li:hover ul.dropdown_links {
  visibility: visible;
  max-height: 200px;
}

.header_account>ul>li:hover>a {
  color: var(--main-bg-color);
}

.header_account>ul>li>a {
  line-height: 45px;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_account>ul>li>a {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_account>ul>li>a {
    line-height: 22px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .header_account>ul>li>a {
    line-height: 22px;
    font-size: 13px;
  }
}

.header_account>ul>li>a i {
  font-size: 10px;
  margin-left: 3px;
}

.header_account>ul>li:hover a:not([href]):not([tabindex]) {
  color: var(--main-bg-color);
}

.dropdown_currency,
.dropdown_language,
.dropdown_links {
  position: absolute;
  background: #fff;
  border: 1px solid #ebebeb;
  min-width: 130px;
  text-align: left;
  top: 100%;
  left: 0;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  overflow: hidden;
  z-index: 9999;
  border-radius: 3px;
  visibility: hidden;
  max-height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .dropdown_currency,
  .dropdown_language,
  .dropdown_links {
    left: -30px;
  }
}

@media only screen and (max-width: 767px) {

  .dropdown_currency,
  .dropdown_language,
  .dropdown_links {
    left: -30px;
  }
}

.dropdown_currency li a,
.dropdown_language li a,
.dropdown_links li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  white-space: nowrap;
  padding: 8px 8px 8px 20px;
  line-height: 20px;
  color: #000000;
}

.dropdown_currency li a:hover,
.dropdown_language li a:hover,
.dropdown_links li a:hover {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_language {
    left: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_language {
    left: -10px;
  }
}

.dropdown_links {
  right: 0;
  left: auto;
}

/*extra css end*/

/* 02. slider area css here */

.slider_s_one .col-lg-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  max-width: 56%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_one .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_one .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.slider_s_one .offset-lg-3 {
  margin-left: 21%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_one .offset-lg-3 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_one .offset-lg-3 {
    margin-left: 0;
  }
}

.slider_s_one .s_banner {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18%;
  flex: 0 0 18%;
  max-width: 18%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_one .s_banner {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_one .s_banner {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.single_slider {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 500px;
  margin-right: 0 !important;
  border-radius: 15px !important;
  overflow: hidden;

}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider {
    // height: 418px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_slider {
    // height: 351px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_slider {
    height: 330px;
    background-position: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider {
    background-position: 10%;
    height: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_section {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider_section {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.slider_area .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
}

@media only screen and (max-width: 767px) {
  .slider_area .owl-dots {
    bottom: 10px;
  }
}

.slider_area .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  display: inline-block;
  margin: 0 7px;
  border-radius: 50%;
  position: relative;
}

.slider_area .owl-dots .owl-dot::before {
  position: absolute;
  content: "";
  width: 11px;
  height: 11px;
  left: -2px;
  top: -2px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.slider_area .owl-dots .owl-dot.active::before {
  opacity: 1;
}

.slider_area .owl-dots .owl-dot:hover::before {
  opacity: 1;
}

.slider_content {
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content {
    padding-left: 30px;
  }
}

.slider_content h3 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--main-bg-color);
  margin-bottom: 10px;
}

.slider_content h1 {
  font-size: 40px;
  line-height: 42px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content h1 {
    font-size: 28px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content h1 {
    font-size: 28px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content h1 {
    font-size: 20px;
    line-height: 22px;
  }
}

.slider_content p {
  margin: 38px 0 44px;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
}

.slider_content p span {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content p {
    font-size: 15px;
    line-height: 22px;
    margin: 20px 0 26px;
    max-width: 432px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content p {
    margin: 18px 0 23px;
    font-size: 15px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content p {
    margin: 17px 0 22px;
    font-size: 12px;
    line-height: 20px;
    max-width: 360px;
  }
}

@media only screen and (max-width: 479px) {
  .slider_content p {
    max-width: 100%;
  }
}

.slider_content a {
  font-size: 13px;
  font-weight: 500;
  padding: 0 35px;
  display: inline-block;
  border-radius: 4px;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  background: var(--main-bg-color);
}

.slider_content a:hover {
  color: #ffffff;
  background: #000000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content a {
    padding: 0 20px;
    height: 43px;
    line-height: 39px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content a {
    padding: 0 15px;
    height: 43px;
    line-height: 41px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content a {
    font-size: 12px;
    height: 35px;
    line-height: 31px;
    padding: 0 13px;
  }
}

.slider_content.color_white h3 {
  color: #ffffff;
}

.slider_content.color_white h1 {
  color: #ffffff;
}

.slider_content.color_white p {
  color: #ffffff;
}

.slider_content.color_white p span {
  color: #ffde00;
}

.active .slider_content h1,
.active .slider_content h2,
.active .slider_content h3,
.active .slider_content a,
.active .slider_content p,
.active .slider_content span {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.active .slider_content h2 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.active .slider_content h3,
.active .slider_content span {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.active .slider_content p {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.active .slider_content a {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.gallery-thumbs {
  height: 60px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.gallery-thumbs .swiper-slide {
  border-right: 1px solid #ebebeb;
  padding: 8px 16px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallery-thumbs .swiper-slide {
    line-height: 41px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallery-thumbs .swiper-slide {
    padding: 8px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .gallery-thumbs .swiper-slide {
    padding: 8px 3px;
    font-size: 12px;
  }
}

.gallery-thumbs .swiper-slide:hover {
  color: var(--main-bg-color);
}

.gallery-thumbs .swiper-slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--main-bg-color);
  bottom: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 99;
}

.gallery-thumbs .swiper-slide-thumb-active {
  color: var(--main-bg-color);
}

.gallery-thumbs .swiper-slide-thumb-active::before {
  opacity: 1;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffffff;
}

/*home two css here*/

.slider_s_two .single_slider {
  height: 450px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_s_two .single_slider {
    height: 418px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_s_two .single_slider {
    height: 351px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_two .single_slider {
    height: 330px;
    background-position: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_two .single_slider {
    background-position: 10%;
    height: 300px;
  }
}

.slider_s_two .col-lg-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 79%;
  flex: 0 0 79%;
  max-width: 79%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_two .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_two .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.slider_s_two .offset-lg-3 {
  margin-left: 21%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_two .offset-lg-3 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_two .offset-lg-3 {
    margin-left: 0;
  }
}

.slider_s_two .slider_content {
  padding-left: 72px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .slider_s_two .slider_content {
    padding-left: 0;
    width: 100%;
  }
}

.slider_s_two .gallery-thumbs .swiper-slide {
  padding: 8px 43px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_two .gallery-thumbs .swiper-slide {
    line-height: 21px;
    padding: 8px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_two .gallery-thumbs .swiper-slide {
    padding: 8px 0px;
  }
}

/*home three css here*/

.slider_s_three .single_slider {
  height: 510px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_s_three .single_slider {
    height: 418px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_s_three .single_slider {
    height: 351px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_three .single_slider {
    height: 330px;
    background-position: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_three .single_slider {
    background-position: 10%;
    height: 300px;
  }
}

.slider_c_three {
  text-align: center;
  padding-left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_c_three p {
    max-width: 100%;
  }
}

/*home four css here*/

.slider_s_four .col-lg-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58%;
  flex: 0 0 58%;
  max-width: 58%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_four .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_four .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.slider_s_four .col-lg-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21%;
  flex: 0 0 21%;
  max-width: 21%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_four .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_four .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.slider_s_four .single_slider {
  height: 431px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_s_four .single_slider {
    height: 404px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_s_four .single_slider {
    height: 351px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_four .single_slider {
    height: 330px;
    background-position: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_four .single_slider {
    background-position: 10%;
    height: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_four .slider_area {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_s_four .slider_area {
    margin-bottom: 30px;
  }
}

/*  04. banner section css here  */

@media only screen and (max-width: 767px) {
  .banner_area {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .single_banner {
    margin-bottom: 30px !important;
  }
}

.banner_thumb {
  position: relative;
  overflow: hidden;
}

.banner_thumb:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

@media only screen and (max-width: 767px) {
  .banner_thumb a {
    width: 100%;
  }
}

.banner_thumb a img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (max-width: 767px) {
  .banner_thumb a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_style2 {
    margin-bottom: 0;
  }
}

.banner_style2 .col-lg-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 28%;
  flex: 0 0 28%;
  max-width: 28%;
}

@media only screen and (max-width: 767px) {
  .banner_style2 .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.banner_style2 .col-lg-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 44%;
  flex: 0 0 44%;
  max-width: 44%;
}

@media only screen and (max-width: 767px) {
  .banner_style2 .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar_banner_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar_banner_area {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar_banner_area .single_banner {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .sidebar_banner_area .single_banner:last-child {
    margin-right: 0;
  }
}

/*home three css here*/

@media only screen and (max-width: 767px) {
  .banner_three_bottom {
    margin-bottom: 30px;
  }
}

/*home four css here*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_s_four .banner_thumb a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_style4 {
    margin-bottom: 30px;
  }
}

/* 03. product section css here */

.tab-content>.tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.tab-content>.tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.categories_product_inner {
  overflow: hidden;
  border-left: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}


.home_section_bg {
  // background: #ecf0f1;

}



.bg_gray {
  background: #fafafa !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_section_bg {
    // padding: 60px 0 79px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .home_section_bg {
    // padding: 60px 0 57px;
    padding: 0;
  }
}

.single_categories_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  float: left;
  padding: 7px 10px;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_categories_product {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_categories_product {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .single_categories_product {
    width: 100%;
  }
}

.single_categories_product:hover {
  z-index: 2;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
}

.categories_sidebar_slider {
  width: auto;
  margin: 0 -10px;
}

.categories_sidebar_slider .owl-stage-outer {
  padding: 10px;
  margin: -20px -10px;
}

.categories_sidebar_slider .owl-stage-outer .owl-item {
  padding: 10px;
}

.categories_sidebar_slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0.5px 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.categories_sidebar_slide .single_categories_product {
  width: 100% !important;
  border: 1px solid #ebebeb;
  margin: -0.5px 0;
}

.categories_product_content {
  padding: 10px;
}

.categories_product_content h4 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 9px;
  color: var(--main-bg-color);
}

.categories_product_content h4 a:hover {
  color: var(--main-bg-color);
}

.categories_product_content p {
  font-size: 13px;
  line-height: 23px;
}

.categories_product_thumb {
  width: 40%;
}

.section_title {
  margin-bottom: 28px;
}

@media only screen and (max-width: 767px) {
  .section_title {
    margin-bottom: 15px;
  }
}

.section_title h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 7px;
  text-transform: capitalize;
  border-bottom: 3px solid var(--main-bg-color);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section_title h2 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title h2 {
    font-size: 17px;
    line-height: 22px;
    padding-bottom: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .section_title h2 {
    font-size: 17px;
    line-height: 20px;
  }
}

.product_area .owl-carousel .owl-stage-outer {
  padding-bottom: 60px;
}

.single_product {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 13.5px 1.5px rgba(210, 210, 210, 0.07);
  margin-bottom: 5px;
  margin-top: 5px;
}

.single_product:hover {
  -webkit-box-shadow: 0px 0px 13.5px 1.5px rgba(210, 210, 210, 0.2);
  -moz-box-shadow: 0px 0px 13.5px 1.5px rgba(210, 210, 210, 0.2);
  box-shadow: 0px 0px 13.5px 1.5px rgba(210, 210, 210, 0.2);
}

.single_product:hover .action_links {
  opacity: 1;
  visibility: visible;
}

.single_product:hover .product_thumb a.secondary_img {
  opacity: 1;
  visibility: visible;
}

.single_product:hover .add_to_cart {
  visibility: visible;
  opacity: 1;
  bottom: -61px;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
}

.product_thumb {
  position: relative;
  // padding: 10px;
  height: 155px !important;
}

.product_thumb a.secondary_img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .product_thumb a img {
    width: 100%;
  }
}

.product_content_inner {
  padding: 20px 20px 25px 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_content_inner {
    padding: 20px 16px 25px 16px;
  }
}

.product_content {
  position: relative;
  text-align: center;
}

.product_content h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  color: #000000;
}

.product_content h4 a:hover {
  color: var(--main-bg-color);
  text-decoration: underline;
}

.add_to_cart {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  visibility: hidden;
  z-index: 9;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  background: #fff;
  padding: 20px 10px 25px;
}

.add_to_cart a {
  border: 2px solid #ebebeb;
  height: 45px;
  line-height: 41px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  max-width: 220px;
  width: 100%;
}

.add_to_cart a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .add_to_cart a {
    height: 38px;
    line-height: 34px;
    font-size: 13px;
    width: 150px;
  }
}

.price_box span {
  line-height: 2rem;
  font-size: 2rem;
  color: #ea3d3a;
  font-weight: 700;
}

.price_box span.old_price {
  text-decoration: line-through;
  font-weight: 400;
  margin-right: 6px;
  font-size: 1rem;
}

@media only screen and (max-width: 767px) {
  .price_box span.old_price {
    font-size: 13px;
  }
}

.price_box span.current_price {
  line-height: 1.8rem;
  font-size: 1.7rem;
  color: #ea3d3a;
  font-weight: 700;
}

.countdown_text p {
  font-size: 14px;
  line-height: 25px;
}

.countdown_text p span {
  font-weight: 700;
  color: var(--main-bg-color);
}

.product_timing {
  margin-top: 15px;
}

.single_countdown {
  display: inline-block;
  margin-right: 6px;
}

.single_countdown:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_countdown {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_countdown {
    margin-right: 3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_countdown {
    margin-right: 3px;
  }
}

.countdown_title {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0;
  line-height: 18px;
}

.countdown_number {
  font-size: 15px;
  font-weight: 500;
  line-height: 50px;
  width: 50px;
  height: 50px;
  background: #f5f5f9;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .countdown_number {
    line-height: 46px;
    width: 46px;
    height: 46px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown_number {
    line-height: 46px;
    width: 46px;
    height: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown_number {
    line-height: 46px;
    width: 46px;
    height: 46px;
  }
}

.action_links {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  z-index: 9;
  text-align: center;
}

.action_links ul li {
  display: inline-block;
}

.action_links ul li a {
  font-size: 21px;
  border: 2px solid #ebebeb;
  height: 43px;
  width: 43px;
  line-height: 39px;
  text-align: center;
  background: #ffffff;
  display: block;
}

.action_links ul li a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #ffffff;
}

.product_rating ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product_rating ul li {
  line-height: 23px;
  margin-right: 3px;
}

.product_rating ul li:last-child {
  margin-right: 0;
}

.product_rating ul li a {
  font-size: 20px;
  color: #ffc600;
}

.product_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_header {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product_header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    margin-bottom: 20px;
  }
}

.product_header .section_title {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .product_header .product_tab_btn {
    margin-top: 15px;
  }
}

.product_tab_btn ul li {
  margin-right: 35px;
}

.product_tab_btn ul li:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_tab_btn ul li {
    margin-right: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product_tab_btn ul li {
    margin-right: 13px;
  }
}

.product_tab_btn ul li a {
  font-size: 14px;
  line-height: 25px;
  text-transform: capitalize;
  display: block;
}

.product_tab_btn ul li a:hover {
  color: var(--main-bg-color);
}

.product_tab_btn ul li a.active {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_tab_btn ul li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .product_tab_btn ul li a {
    line-height: 27px;
  }
}

.label_product span {
  position: absolute;
}

.label_product span.label_sale {
  top: 15px;
  right: 15px;
  text-transform: capitalize;
  color: #ffffff;
  background: #62ab00;
  font-size: 10px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 50%;
}

.label_product span.label_new {
  top: 20px;
  right: 20px;
  width: 50px;
  height: 23px;
  text-align: center;
  line-height: 23px;
  color: #ffffff;
  background: #000000;
  font-size: 12px;
  border-radius: 3px;
  text-transform: capitalize;
  z-index: 3;
}

.product_carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product_carousel .owl-stage .owl-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product_carousel .owl-stage .owl-item .product_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
}

.product_carousel .owl-stage .owl-item .product_items .single_product {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
}

.product_carousel .owl-item.last .single_product {
  border-right: 1px solid #ebebeb;
}

.product_carousel .product_thumb a img {
  width: inherit;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .product_carousel .product_thumb a img {
    width: 100%;
  }
}

.product_carousel .col-lg-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.product_carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.product_carousel .owl-nav div {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 46%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  font-size: 24px;
  border: 1px solid #ebebeb;
  width: 65px;
  height: 65px;
  line-height: 63px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_carousel .owl-nav div {
    left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_carousel .owl-nav div {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_carousel .owl-nav div {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product_carousel .owl-nav div {
    left: 0;
    width: 38px;
    height: 38px;
    line-height: 37px;
    font-size: 20px;
  }
}

.product_carousel .owl-nav div:hover {
  color: #ffffff;
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.product_carousel .owl-nav div.owl-next {
  right: -30px;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_carousel .owl-nav div.owl-next {
    right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_carousel .owl-nav div.owl-next {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_carousel .owl-nav div.owl-next {
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product_carousel .owl-nav div.owl-next {
    right: 0;
  }
}

.product_items .single_product:first-child {
  border-bottom: 0;
}

.product_items .single_product:hover {
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
}

.product_footer {
  position: relative;
}

.product_sidebar_slider .owl-item.active .single_product {
  border-right: 2px solid #ebebeb;
}

.product_sidebar_slider .owl-item.active.last .single_product {
  border-right: 0px solid #ebebeb;
}

.product_sidebar_slider .single_product {
  border: none;
}

.product_sidebar_slider .single_product .add_to_cart {
  position: static;
  visibility: visible;
  opacity: 1;
  padding: 0;
}

.product_sidebar_slider .single_product:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.product_sidebar_slider .single_product:hover .add_to_cart {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*custom product area css here*/

.small_product_area.product_bg {
  padding: 45px 0 30px;
}

.small_p_container .single_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 15px;
}

.small_p_container .single_product:hover .product_cart_button {
  opacity: 1;
  visibility: visible;
}

.small_p_container .product_content {
  width: 62%;
  padding-left: 15px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .small_p_container .product_content {
    width: 66%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small_p_container .product_content {
    width: 67%;
  }
}

@media only screen and (max-width: 767px) {
  .small_p_container .product_content {
    width: 70%;
    padding-left: 12px;
  }
}

.small_p_container .product_thumb {
  width: 38%;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .small_p_container .product_thumb {
    width: 29%;
  }
}

.small_p_container .product_name h4 {
  margin-bottom: 12px;
}

.product_cart_button {
  position: absolute;
  bottom: 0;
  right: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.product_cart_button a {
  font-size: 18px;
  height: 43px;
  width: 43px;
  line-height: 39px;
  border: 2px solid #ebebeb;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_cart_button a {
    font-size: 16px;
    height: 40px;
    width: 40px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .product_cart_button a {
    font-size: 15px;
    height: 40px;
    width: 40px;
    line-height: 36px;
  }
}

.product_cart_button a:hover {
  color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.small_product_style2 .product_thumb {
  width: 24%;
}

.small_product_style2 .product_content {
  width: 76%;
}

.small_product_style2 .product_items .single_product:last-child {
  border-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small_product_list {
    margin-bottom: 54px;
  }
}

@media only screen and (max-width: 767px) {
  .small_product_list {
    margin-bottom: 54px;
  }
}

/*home two css here*/

.deals_product_style2 .single_product {
  overflow: hidden;
}

.deals_product_style2 .product_thumb {
  float: left;
  width: 43%;
}

@media only screen and (max-width: 767px) {
  .deals_product_style2 .product_thumb {
    width: 100%;
  }
}

.deals_product_style2 .product_content {
  float: left;
  padding: 15px 0 15px 15px;
  width: 57%;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .deals_product_style2 .product_content {
    width: 100%;
    padding: 15px 0 0 0;
  }
}

.deals_product_style2 .price_box {
  margin-bottom: 13px;
}

/*home three css here*/

.s_title_style3 {
  position: relative;
  padding: 5px 0 5px 15px;
}

@media only screen and (max-width: 767px) {
  .s_title_style3 {
    padding: 3px 0 3px 15px;
  }
}

.s_title_style3::before {
  position: absolute;
  content: "";
  background: var(--main-bg-color);
  bottom: 0;
  left: 0;
  top: 0;
  width: 4px;
}

.s_title_style3 h2 {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 0;
  border-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .s_title_style3 h2 {
    font-size: 18px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .s_title_style3 h2 {
    font-size: 17px;
    line-height: 22px;
  }
}

/*home four css here*/

.home_style4_inner .col-lg-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20.3%;
  flex: 0 0 20.3%;
  max-width: 20.3%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_style4_inner .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .home_style4_inner .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.home_style4_inner .col-lg-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 29.7%;
  flex: 0 0 29.7%;
  max-width: 29.7%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_style4_inner .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .home_style4_inner .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.home_style4_inner .product_content_inner {
  padding: 21px 16px 24px 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_style4_inner .product_content_inner {
    padding: 18px 16px 21px 16px;
  }
}

.home_style4_inner .single_banner {
  height: 92%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_style4_inner .single_banner {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .home_style4_inner .single_banner {
    height: 100%;
    margin-bottom: 0 !important;
  }
}

.home_style4_inner .banner_thumb {
  height: 100%;
}

.home_style4_inner .banner_thumb a img {
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_style4_inner .banner_thumb a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .home_style4_inner .banner_thumb a img {
    width: 100%;
  }
}

.home_style4_inner .add_to_cart a {
  width: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_style4_inner .product_area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .home_style4_inner .product_area {
    margin-top: 30px;
  }
}

.category_menu {
  background: #ffffff;
  height: 92%;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category_menu {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .category_menu {
    height: 94%;
    margin-bottom: 30px;
  }
}

.category_menu_img {
  position: absolute;
  bottom: 0;
  left: 30px;
}

.category_menu_content {
  padding: 30px 20px 100px 20px;
}

@media only screen and (max-width: 767px) {
  .category_menu_content {
    padding: 30px 20px 160px 20px;
  }
}

.category_menu_content ul li {
  padding: 5px 0;
  border-bottom: 1px solid #ebebeb;
}

.category_menu_content ul li a {
  display: block;
}

.category_menu_content ul li a:hover {
  color: var(--main-bg-color);
}

/* 05. blog area css here */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_area {
    margin-bottom: 54px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_area {
    margin-bottom: 55px;
  }
}

.blog_container {
  border: 1px solid #ebebeb;
}

.blog_container:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.blog_container .owl-nav.disabled {
  display: block;
}

.blog_container .owl-nav div {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  font-size: 24px;
  border: 1px solid #ebebeb;
  width: 65px;
  height: 65px;
  line-height: 63px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_container .owl-nav div {
    left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_container .owl-nav div {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_container .owl-nav div {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_container .owl-nav div {
    left: 0;
    width: 38px;
    height: 38px;
    line-height: 37px;
    font-size: 20px;
  }
}

.blog_container .owl-nav div:hover {
  color: #ffffff;
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.blog_container .owl-nav div.owl-next {
  right: -30px;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_container .owl-nav div.owl-next {
    right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_container .owl-nav div.owl-next {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_container .owl-nav div.owl-next {
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_container .owl-nav div.owl-next {
    right: 0;
  }
}

.blog_container .col-lg-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.single_blog {
  background: #ffffff;
  border-right: 1px solid #ebebeb;
  padding: 20px;
}

.blog_content {
  padding: 20px 0 7px 0;
  text-align: center;
}

.blog_content h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .blog_content h4 {
    font-size: 13px;
  }
}

.blog_content h4 a:hover {
  color: var(--main-bg-color);
}

.blog_content .post_meta p {
  font-size: 13px;
  line-height: 23px;
}

.blog_content .post_meta p a {
  color: var(--main-bg-color);
}

.blog_content .post_meta p a:hover {
  text-decoration: underline;
}

.blog_content .post_desc {
  margin-top: 14px;
}

.blog_content .post_readmore {
  margin-top: 14px;
}

.blog_content .post_readmore a {
  color: var(--main-bg-color);
}

.blog_content .post_readmore a:hover {
  text-decoration: underline;
}

/*06. newsletter area css here*/

.newsletter_area {
  margin-top: -73px;
}

@media only screen and (max-width: 767px) {
  .newsletter_area {
    margin-top: -50px;
  }
}

.newsletter_inner {
  padding: 45px 35px;
  border: 1px solid #ebebeb;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .newsletter_inner {
    padding: 30px 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter_sing_up {
    margin-bottom: 14px;
  }
}

.newsletter_sing_up h3 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .newsletter_sing_up h3 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 3px;
  }
}

.newsletter_sing_up p {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}

.newsletter_sing_up p span {
  color: var(--main-bg-color);
}

@media only screen and (max-width: 767px) {
  .newsletter_sing_up p {
    font-size: 13px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe_content {
    margin-bottom: 22px;
  }
}

.subscribe_content p {
  font-size: 14px;
  line-height: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe_form {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe_form {
    width: 100%;
  }
}

.subscribe_form form {
  width: 100%;
  position: relative;
  background: #ffffff;
  border-radius: 4px;
}

.subscribe_form form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666;
}

.subscribe_form form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666;
}

.subscribe_form form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #666;
}

.subscribe_form form input:-moz-placeholder {
  /* Firefox 18- */
  color: #666;
}

.subscribe_form form input {
  width: 100%;
  border: 1px solid #ebebeb;
  background: none;
  padding: 0 158px 0 15px;
  height: 50px;
  font-size: 14px;
  color: #666;
  border-radius: 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe_form form input {
    padding: 0 125px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe_form form input {
    height: 40px;
    font-size: 12px;
    padding: 0 106px 0 15px;
  }
}

.subscribe_form form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 20px;
  width: 140px;
  background: var(--main-bg-color);
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  height: 100%;
  border: 0;
  display: block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.subscribe_form form button:hover {
  color: #ffffff;
  background: #000000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe_form form button {
    width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe_form form button {
    font-size: 12px;
    padding: 0 8px;
    width: 94px;
  }
}

.mailchimp-error {
  text-align: left;
  color: green;
}

.mailchimp-success {
  text-align: left;
  max-width: 510px;
  color: green;
}

.mailchimp-success.active {
  margin-top: 20px;
}

/* 21. shipping css here */

.carousel-custom {
  margin: 20px;
  margin-bottom: 0px;
  margin-right: 0px;
  border-radius: 15px !important;
  overflow: hidden;
  // padding: 20px;
  background-color: #fff;
  // border: 1px solid #ddd !important;

}


.shipping__area {
  &--inner {
    display: flex;
    align-items: stretch;
    // border: 1px solid #ebebeb;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid #ddd;

    @media (max-width: 960px) {
      flex-wrap: wrap;
    }

    &--item:hover {
      background-color: var(--light-main-bg);

    }

    &--item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 100%;
      width: 20%;
      padding: 12px 12px;



      @media (max-width: 991px) {
        width: 50%;
        border-bottom: 1px solid #ebebeb;

        &:last-child {
          border-bottom: none;
        }
      }

      @media (max-width: 660px) {
        width: 100%;
      }

      &--icon {
        margin-right: 10px;

        img {
          width: 60px;
          filter: var(--filter-image);

          color: var(--main-bg-color);

        }

        width: 20%;
      }

      &--text {
        width: 80%;

        h5 {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    &--item:not(:last-child) {

      border-right: 1px solid #ddd;

    }
  }
}

.shipping_inner {
  overflow: hidden;
  border: 1px solid #ebebeb;
}

.single_shipping {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  float: left;
  padding: 25px 10px 25px 20px;
  border-right: 1px solid #ebebeb;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_shipping {
    padding: 25px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_shipping {
    width: 50%;
    border-bottom: 1px solid #ebebeb;
  }

  .single_shipping:last-child {
    border-right: 1px solid #ebebeb !important;
    border-bottom: 0;
  }

  .single_shipping:nth-child(3) {
    border-right: 0;
  }

  .single_shipping:nth-child(4) {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .single_shipping {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: left;
    border-bottom: 1px solid #ebebeb;
    border-right: 0;
  }

  .shipping_image {
    width: 40px;
  }

  .single_shipping:last-child {
    border-bottom: 0;
  }
}

.single_shipping:last-child {
  border-right: 0;
}

.shipping_icon {
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping_icon {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .shipping_icon {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping_icon img {
    width: 30px;
  }
}

.shipping_content h5 {
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 3px;
  color: #000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping_content h5 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shipping_content h5 {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .shipping_content h5 {
    font-size: 13px;
  }
}

.shipping_content p {
  font-size: 13px;
  line-height: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping_content p {
    font-size: 13px;
    line-height: 20px;
  }
}

/*brand css here*/

.brand_area {
  padding: 40px 0;
}

.brand_container .single_brand img {
  width: inherit;
  margin: 0 auto;
}

.brand_container:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.brand_container .owl-nav.disabled {
  display: block;
}

.brand_container .owl-nav div {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  left: 0;
  font-size: 18px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #a2a2a2;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
}

.brand_container .owl-nav div:hover {
  color: #ffffff;
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.brand_container .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

/*  07. footer area css here */





@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets_container {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container.widget_menu {
    margin-bottom: 51px;
  }
}

.widgets_container h3 {
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  position: relative;
  padding-bottom: 11px;
}

.widgets_container h3::before {
  position: absolute;
  background: var(--main-bg-color);
  content: "";
  display: block;
  height: 3px;
  width: 70px;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widgets_container h3 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets_container h3 {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container h3 {
    margin-bottom: 22px;
  }
}

.aff_content {
  // margin-bottom: 25px;

  li {
    padding-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .aff_content {
    margin-bottom: 17px;
  }
}

.aff_content p {
  font-size: 14px;
  line-height: 25px;
}

.app_img {
  margin-bottom: 10px;
}

.app_img:last-child {
  margin-bottom: 0;
}

.footer_contact>p {
  font-size: 14px;
  line-height: 25px;
}

.footer_menu ul li {

  margin-bottom: 12px;
}

.footer_menu ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_menu ul li {
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_menu ul li {
    margin-bottom: 14px;
  }
}

.footer_menu ul li a,
.footer_menu a {
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.footer_menu ul li a:hover,
.footer_menu a:hover {
  color: var(--main-bg-color);
}

@media only screen and (max-width: 767px) {
  .footer_menu ul li a {
    font-size: 13px;
    line-height: 18px;
  }
}

.footer_social {
  margin-top: 17px;
}

@media only screen and (max-width: 767px) {
  .footer_social {
    text-align: left;
  }
}

.footer_social ul li {
  display: inline-block;
  margin-right: 5px;
}

.footer_social ul li:last-child {
  margin-right: 0;
}




.footer_social ul li a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  display: block;
  text-align: center;
  font-size: 30px;
  border-radius: 4px;
  color: #ffffff;
}

.footer_social ul li a:hover {
  background: var(--main-bg-color);
}





.footer_social ul li a.facebook {
  background: #3b5998;
}

.footer_social ul li a.youtube {
  background: red;
}

.footer_social ul li a.twitter {
  background: #00aced;
}

.footer_social ul li a.twitter:hover {
  background: var(--main-bg-color);
}

.footer_social ul li a.instagram {
  background: #bc2a8d;
}

.footer_social ul li a.instagram:hover {
  background: var(--main-bg-color);
}

.footer_social ul li a.linkedin {
  background: #007bb6;
}

.footer_social ul li a.linkedin:hover {
  background: var(--main-bg-color);
}

.footer_social ul li a.rss {
  background: #f26522;
}

.footer_social ul li a.rss:hover {
  background: var(--main-bg-color);
}

.footer_contact_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.contact_text {
  margin-left: 15px;
}


.contact_text p {
  font-size: 14px;
  line-height: 25px;
}

.contact_text p strong {
  color: var(--main-bg-color);
}

@media only screen and (max-width: 767px) {
  .copyright_area {
    text-align: center;
    margin-bottom: 15px;
  }
}

.copyright_area p {
  text-transform: capitalize;
  line-height: 25px;
  font-size: 14px;
  font-weight: bold;
}

.copyright_area a {
  font-weight: bold !important;
}

@media only screen and (max-width: 767px) {
  .copyright_area p {
    font-size: 13px;
  }
}

.copyright_area p a {
  color: #000;
}

.copyright_area p a:hover {
  text-decoration: underline;
}

.footer_bottom {
  margin-top: 15px;
  padding: 7px 18px;
  background-color: var(--main-bg-color);
  //width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}



.footer_payment p {
  font-size: 14px;
  line-height: 23px;
  text-transform: capitalize;
  font-style: italic;
  margin-bottom: 8px;
}

/* 10. shop page css here */

.breadcrumbs_area {
  padding: 20px 0;
}

.breadcrumb_content ul li {
  display: inline-block;
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
  color: var(--main-bg-color);
}

.breadcrumb_content ul li::before {
  position: absolute;
  content: ">";
  right: -4px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
}

.breadcrumb_content ul li:last-child {
  margin-right: 0;
}

.breadcrumb_content ul li:last-child::before {
  display: none;
}

.breadcrumb_content ul li a {
  color: #000000;
}

.breadcrumb_content ul li a:hover {
  color: var(--main-bg-color);
}

.shop_area {
  background: #f5f5f9;
  padding: 60px 0 133px;
}

@media only screen and (max-width: 767px) {
  .shop_area {
    padding: 60px 0 110px;
  }
}

.sidebar_widget {
  //margin-bottom: 15px;
  background: transparent !important;
  overflow: auto;
  max-height: 100%;
}

.sidebar_widget .single_banner {
  border: 0;
}

@media only screen and (max-width: 767px) {
  .sidebar_widget .single_banner {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar_widget .single_banner a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar_widget .single_banner a img {
    width: 100%;
  }
}

.sticky--tab .sidebar_widget {

  margin-bottom: 40px;

  // border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 0px;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.widget_list {
  margin-bottom: 0px;
  padding: 0px 0 !important;


}

.widget_list:last-child {
  margin-bottom: 0;
}

.widget_list h3 {
  font-size: 14px;
  margin-bottom: 25px;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget_list h3 {
    font-size: 13px;
  }
}

.widget_list h3::before {
  position: absolute;
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  background: var(--main-bg-color);
  bottom: 0;
}

.widget_list>ul>li {
  border-bottom: 1px solid #ebebeb;
}

.widget_list>ul>li:first-child a {
  padding-top: 0;
}

.widget_list>ul>li:last-child {
  border-bottom: 0;
}

.widget_list>ul>li:last-child a {
  padding-bottom: 0;
}

.widget_list>ul>li>a {
  font-size: 14px;
  display: block;
  line-height: 25px;
  padding: 10px 0;
}

.widget_list>ul>li>a:hover {
  color: var(--main-bg-color);
}

.widget_list>ul>li.widget_sub_categories>a {
  position: relative;
}

.widget_list>ul>li.widget_sub_categories>a::before {
  content: "\f107";
  cursor: pointer;
  font-family: FontAwesome;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
}

.widget_list>ul>li.widget_sub_categories>a.active::before {
  content: "\f106";
  cursor: pointer;
  font-family: FontAwesome;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
}

.widget_list>ul>li ul {
  padding-left: 15px;
}

.widget_list>ul>li ul li {
  border-bottom: 1px solid #ebebeb;
}

.widget_list>ul>li ul li:first-child {
  border-top: 1px solid #ebebeb;
}

.widget_list>ul>li ul li:last-child {
  border-bottom: 0;
}

.widget_list>ul>li ul li a {
  padding-bottom: 10px;
  padding: 10px 0;
  display: block;
}

.widget_list.banner_area {
  padding: 0;
  border: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget_list .banner_thumb {
    width: 33%;
    margin: 0 auto;
  }
}

.widget_list .banner_thumb a img {
  width: 100%;
}

.ui-slider-horizontal .ui-slider-range {
  background: var(--main-bg-color);
  height: 5px;
}

.ui-slider-horizontal {
  height: 3px;
  background: #dbdbdb;
  border: none;
  width: 92%;
  margin: 0 auto;
  margin-bottom: 22px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #fff;
  border: 0;
  border-radius: 0;
  width: 19px;
  height: 19px;
  top: -7px;
  cursor: pointer;
  border-radius: 50%;
  border: 5px solid var(--main-bg-color);
}

.widget_list.widget_filter form {
  padding-top: 10px;
}

.widget_list.widget_filter form input {
  background: none;
  border: none;
  font-size: 12px;
  float: right;
  text-align: right;
  line-height: 31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget_list.widget_filter form input {
    width: 65px;
  }
}

.widget_list.widget_filter form button {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  text-transform: capitalize;
  color: #ffffff;
  background: #000000;
  border: 0;
  border-radius: 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.widget_list.widget_filter form button:hover {
  background: var(--main-bg-color);
}

.recent_product_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.recent_product_list {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  margin-bottom: 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  max-width: 100%;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .recent_product_list {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 calc(50% - 10px);
    flex: 1 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    border: 1px solid #ddd;
    padding: 20px;
  }
}

.recent_product_list:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .recent_product_list:last-child {
    border: 1px solid #ddd;
    padding: 20px;
  }
}

.recent_product_list:hover .product_thumb a.secondary_img {
  opacity: 1;
  visibility: visible;
}

.recent_product_list .product_thumb {
  width: 33%;
  float: left;
  margin-bottom: 0;
  padding: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recent_product_list .product_thumb {
    width: 33%;
  }
}

.recent_product_list .product_content {
  width: 67%;
  float: left;
  padding-left: 15px;
  text-align: left;
}

.recent_product_list .product_content h4 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 9px;
}

.recent_product_list .product_content h4 a:hover {
  color: var(--main-bg-color);
  text-decoration: underline;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recent_product_list .product_content {
    padding-left: 10px;
    width: 67%;
  }
}

.recent_product_list .price_box {
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recent_product_list .price_box span.current_price {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recent_product_list .price_box span.old_price {
    font-size: 13px;
  }
}

.tag_cloud {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag_cloud button {
  margin: 5px;
}

.tag_cloud a {
  margin: 0 5px 8px 0;
  padding: 5px 10px;
  text-transform: capitalize;
  display: inline-block;
  border: 1px solid #ebebeb;
  background: #ffffff;
  border-radius: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tag_cloud a {
    padding: 5px 10px;
  }
}

.tag_cloud a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #ffffff;
}

.shop_banner_thumb {
  text-align: center;
}

.shop_toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  padding: 8px 10px;
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .shop_toolbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 10px 10px 18px;
  }
}

.select_option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .select_option {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.select_option .nice-select {
  border: 0;
  height: 30px;
  line-height: 29px;
}

.select_option .nice-select ul.list {
  top: 114%;
  right: 0;
  width: 200px;
  max-height: 200px;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .niceselect_option {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .page_amount {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .page_amount p {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
  }
}

.list_button ul li {
  margin-right: 12px;
}

.list_button ul li:last-child {
  margin-right: 0;
}

.list_button ul li a:hover {
  color: #c39456;
}

.list_button ul li a i {
  margin-right: 5px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.list_button ul li a.active {
  color: var(--main-bg-color);
}

.list_button ul li a.active i {
  background: #c39456;
  color: #ffffff;
}

.product_ratting ul li {
  display: inline-block;
}

.product_ratting ul li a {
  color: #c39456;
}

@media only screen and (max-width: 767px) {
  .product_list_item .product_thumb {
    margin-bottom: 18px;
  }
}

.product_list_item .product_content h3 {
  margin-bottom: 10px;
}

.product_list_item .product_ratting {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .pagination {
    margin-top: 19px;
  }
}

.pagination ul li {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f1f1f1;
  border-radius: 3px;
  margin-left: 3px;
}

.pagination ul li:first-child {
  margin-left: 0;
}

.pagination ul li a {
  display: block;
  border-radius: 3px;
}

.pagination ul li a:hover {
  background: var(--main-bg-color);
  color: #ffffff;
}

.pagination ul li.current {
  background: var(--main-bg-color);
  color: #ffffff;
}

.pagination ul li.next {
  width: 40px;
}

.shop_toolbar.t_bottom {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_toolbar.t_bottom {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar.t_bottom {
    padding: 15px 10px 15px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar.t_bottom .pagination {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_reverse .tab-content .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .shop_reverse .tab-content .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_view .quick_button {
    bottom: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_view .quick_button a {
    line-height: 37px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_view .action_button ul li a {
    width: 43px;
    height: 40px;
    line-height: 38px;
  }
}

.grid_view .hover_action a {
  width: 43px;
  height: 40px;
  line-height: 38px;
}

/* shop page css here*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_reverse .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .shop_reverse .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.row.shop_wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.shop_toolbar_btn>button {
  margin-right: 10px;
  border: 0;
  background: inherit;
}

.shop_toolbar_btn>button.btn-grid-3 {
  /* background: url(../src/sheard/assets/img/icon/bkg_grid.png) no-repeat scroll center center; */
  width: 20px;
  height: 20px;
}

.shop_toolbar_btn>button.btn-grid-3.active {
  /* background: url(../src/sheard/assets/img/icon/bkg_grid_hover.png) no-repeat scroll center center !important; */
}

.shop_toolbar_btn>button.btn-grid-4 {
  /* background: url(../src/sheard/assets/img/icon/bkg_grid4.png) no-repeat scroll center center; */
  width: 26px;
  height: 22px;
}

.shop_toolbar_btn>button.btn-grid-4.active {
  /* background: url(../src/sheard/assets/img/icon/bkg_grid4_hover.png) no-repeat scroll center center !important;  */
}

.shop_toolbar_btn>button.btn-list {
  /* background: url(../src/sheard/assets/img/icon/bkg_list.png) no-repeat scroll center center; */
  width: 20px;
  height: 20px;
}

.shop_toolbar_btn>button.btn-list.active {
  /* background: url(../src/sheard/assets/img/icon/bkg_list_hover.png) no-repeat scroll center center !important; */
}

.product_content.list_content {
  display: none;
}

.product_content.list_content .add_to_cart {
  position: inherit;
  padding: 0;
  opacity: inherit;
  visibility: inherit;
  bottom: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: left;
  margin-bottom: 2px;
}

.product_content.list_content .add_to_cart a {
  text-align: center;
}

.grid_content .product_rating {
  margin-bottom: 4px;
}

.grid_content .product_rating ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_content .action_links ul li.add_to_cart a {
    width: 110px;
  }
}

.grid_content h4.product_name {
  margin-bottom: 15px;
}

.grid_list .product_name {
  display: none;
}

.grid_list .product_rating {
  display: none;
}

.grid_list .product_thumb .action_links {
  display: none;
}

.grid_list .product_content.grid_content {
  display: none;
}

.grid_list .product_content.list_content {
  text-align: left;
  margin-top: 0;
  display: block;
}

@media only screen and (max-width: 767px) {
  .grid_list .product_content.list_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    min-width: 100%;
    padding-left: 0;
  }
}

.grid_list .product_content.list_content .product_rating {
  display: block;
  margin-bottom: 3px;
}

.grid_list .product_content.list_content .product_name {
  display: block;
}

.grid_list .product_content.list_content .price_box {
  margin-bottom: 2px;
}

.grid_list .product_content.list_content .action_links {
  opacity: inherit;
  visibility: visible;
  text-align: left;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  position: inherit;
  padding: 0;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  background: inherit;
  bottom: inherit;
}

.grid_list .product_content.list_content .action_links ul li {
  display: block;
  margin-bottom: 4px;
}

.grid_list .product_content.list_content .action_links ul li:last-child {
  margin-bottom: 0;
}

.grid_list .product_content.list_content .action_links ul li a {
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  width: inherit;
  height: inherit;
  display: inline-block;
  line-height: 22px;
  border: 0;
  font-size: 14px;
}

.grid_list .product_content.list_content .action_links ul li a i {
  font-size: 20px;
  margin-right: 10px;
}

.grid_list .product_content.list_content .action_links ul li a:hover {
  background: inherit;
  color: var(--main-bg-color);
}

.grid_list .product_content.list_content .product_description {
  display: inline-block;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.grid_list .product_content.list_content .product_description p {
  font-size: 14px;
  line-height: 24px;
}

.grid_list .single_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
}

@media only screen and (max-width: 767px) {
  .grid_list .single_product {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.grid_list .product_thumb {
  margin-bottom: 0;
  float: left;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .grid_list .product_thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.col-cust-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: max-width;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-cust-5 {
    -ms-flex: 0 0 33%;
    -webkit-box-flex: 0;
    flex: 0 0 33%;
  }
}

@media only screen and (max-width: 767px) {
  .col-cust-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .col-cust-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop_wrapper>div {
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.shop_toolbar_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  padding: 8px 10px;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .shop_toolbar_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px 10px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar_btn {
    margin-bottom: 20px;
  }
}

.shop_wrapper {
  // background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .shop_wrapper .product_thumb a img {
    width: 100%;
  }
}

.shop_wrapper .single_product {
  position: relative;
  border-top: 0;
  height: 165px;
  border: 1px solid #fef1f0;
}

.single_product .image_product {
  width: 80%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop_wrapper .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .shop_wrapper .col-lg-8 {

    flex: 75%;
    max-width: 75%;

  }
}

@media only screen and (max-width: 992px) {
  .shop_wrapper .col-lg-3 {
    padding-right: 0;
  }
}



/* shop page css end*/

/*shop fullwidth css here*/

.shop_fullwidth .shop_wrapper.grid_3 .product_thumb a img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_fullwidth .shop_toolbar.t_bottom {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop_fullwidth .shop_toolbar.t_bottom {
    margin-bottom: 0;
  }
}

/* 15. product details css here */

.product_page_bg {
  background: #f5f5f9;
  padding: 60px 0 75px;
}

@media only screen and (max-width: 767px) {
  .product_page_bg {
    padding: 60px 0 50px;
  }
}

.product_details_wrapper {
  background: #ffffff;
  border: 1px solid #ebebeb;
}

.product_details {
  padding: 30px 30px 55px;
}

@media only screen and (max-width: 767px) {
  .product_details {
    padding: 30px 20px 52px;
  }
}

#img-1 {
  border: 1px solid #ebebeb;
}

.header_product {
  border-bottom: 1px solid #ebebeb;
}

.product_d_right h3 {
  text-transform: capitalize;
  line-height: 20px;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_d_right h3 {
    font-size: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .product_d_right h3 {
    margin-bottom: 17px;
    font-size: 18px;
  }
}

.product_d_right h3 a:hover {
  color: var(--main-bg-color);
}

.product_d_right .product_rating {
  margin-bottom: 17px;
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_rating {
    margin-bottom: 12px;
  }
}

.product_d_right .product_rating ul li.review a {
  color: #000000;
  margin-left: 10px;
  font-size: 14px;
}

.product_d_right .product_rating ul li.review a:hover {
  color: var(--main-bg-color);
}

.product_d_right .price_box {
  margin-bottom: 14px;
}

@media only screen and (max-width: 767px) {
  .product_d_right .price_box {
    margin-bottom: 9px;
  }
}

.product_d_right .price_box span.current_price {
  font-size: 23px;
}

@media only screen and (max-width: 767px) {
  .product_d_right .price_box span.current_price {
    font-size: 18px;
  }
}

.product_d_right .price_box span.old_price {
  font-size: 20px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .product_d_right .price_box span.old_price {
    font-size: 17px;
  }
}

.product_d_right .product_description {
  margin-bottom: 19px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_description {
    margin-bottom: 15px;
    padding-bottom: 18px;
  }
}

.product_d_right .product_description::before {
  display: none;
}

.product_d_right .product_description p {
  font-size: 14px;
  line-height: 26px;
}

.product_d_right .priduct_social ul li {
  display: inline-block;
  margin-right: 7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_d_right .priduct_social ul li {
    margin-right: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .product_d_right .priduct_social ul li {
    margin-right: 0;
  }
}

.product_d_right .priduct_social ul li:last-child {
  margin-right: 0;
}

.product_d_right .priduct_social ul li a {
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 3px;
  text-transform: capitalize;
  display: block;
}

@media only screen and (max-width: 767px) {
  .product_d_right .priduct_social ul li a {
    padding: 0 3px;
    font-size: 11px;
  }
}

.product_d_right .priduct_social ul li a:hover {
  opacity: 0.8;
}

.product_d_right .priduct_social ul li a.facebook {
  background: #3b5999;
}

.product_d_right .priduct_social ul li a.twitter {
  background: #1da1f2;
}

.product_d_right .priduct_social ul li a.pinterest {
  background: #cb2028;
}

.product_d_right .priduct_social ul li a.google-plus {
  background: #fe6d4c;
}

.product_d_right .priduct_social ul li a.linkedin {
  background: #000;
}

.product_d_right .product_timing {
  margin-bottom: 20px;
  margin-top: 26px;
  width: 260px;
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_timing {
    margin-bottom: 16px;
    margin-top: 25px;
  }
}

.product_d_right .countdown_title {
  text-align: center;
}

.product_nav {
  float: right;
  position: relative;
  top: -46px;
}

@media only screen and (max-width: 767px) {
  .product_nav {
    display: none;
  }
}

.product_nav ul li {
  display: inline-block;
  margin-left: 3px;
}

.product_nav ul li:first-child {
  margin-left: 0;
}

.product_nav ul li a {
  background: var(--main-bg-color);
  border-radius: 3px;
  color: #ffffff;
  display: block;
  font-size: 15px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
}

.product_nav ul li a:hover {
  background: #000000;
}

.product_variant.quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity {
    margin-bottom: 16px;
  }
}

.product_variant.quantity label {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 0;
}

.product_variant.quantity input {
  width: 130px;
  border: 1px solid #ebebeb;
  background: none;
  height: 42px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_variant.quantity input {
    width: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_variant.quantity input {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity input {
    width: 65px;
    margin-left: 10px;
  }
}

.product_variant.quantity button {
  border: 0;
  font-size: 16px;
  margin-left: 20px;
  background: var(--main-bg-color);
  height: 42px;
  line-height: 42px;
  text-transform: capitalize;
  min-width: 270px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_variant.quantity button {
    min-width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_variant.quantity button {
    min-width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity button {
    min-width: inherit;
    margin-left: 10px;
    padding: 0 12px;
    font-size: 14px;
  }
}

.product_variant.quantity button:hover {
  background: #3e444a;
}

.product_variant.color {
  margin-bottom: 26px;
}

@media only screen and (max-width: 767px) {
  .product_variant.color {
    margin-bottom: 18px;
  }
}

.product_variant.color h3 {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 0;
  margin-right: 40px;
}

.product_variant.color label {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.product_variant.color ul li {
  display: inline-block;
  padding: 2px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.product_variant.color ul li:hover {
  border-color: #e88888;
}

.product_variant.color ul li:last-child {
  margin-right: 0;
}

.product_variant.color ul li a {
  width: 30px;
  height: 30px;
  display: block;
}

.product_variant.color ul li.color1 a {
  background: #000000;
}

.product_variant.color ul li.color2 a {
  background: #bebebe;
}

.product_variant.color ul li.color3 a {
  background: #fe0000;
}

.product_variant.color ul li.color4 a {
  background: #ffff01;
}

.product_variant.size {
  margin-bottom: 30px;
}

.product_variant.size label {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.product_variant.size .niceselect_option {
  float: inherit;
  max-width: 200px;
}

.product_d_action {
  margin-bottom: 14px;
}

.product_d_action ul li a {
  font-size: 14px;
  line-height: 28px;
}

.product_d_action ul li a:hover {
  color: var(--main-bg-color);
}

.product_meta {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .product_meta {
    margin-bottom: 20px;
  }
}

.product_meta span {
  font-weight: 500;
}

.product_meta span a {
  margin-left: 10px;
  font-weight: 400;
}

.product_meta span a:hover {
  color: var(--main-bg-color);
}

.product_info_button {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
  margin-bottom: 29px;
}

@media only screen and (max-width: 767px) {
  .product_info_button ul li {
    margin-bottom: 5px;
  }

  .product_info_button ul li:last-child {
    margin-bottom: 0;
  }
}

.product_info_button ul li a {
  display: block;
  float: left;
  text-transform: capitalize;
  font-size: 20px;
  color: #555;
  font-weight: 500;
  margin-right: 35px;
  line-height: 26px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .product_info_button ul li a {
    margin-right: 25px;
    font-size: 17px;
  }
}

.product_info_button ul li a.active {
  color: #333333;
}

.product_info_button ul li a:hover {
  color: #333333;
}

.product_info_button ul li:last-child a {
  margin-right: 0;
}

.product_review_form button {
  border: none;
  background: #000000;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 15px 3px;
  display: block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 13px;
}

.product_review_form button:hover {
  background: var(--main-bg-color);
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_review_form form .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.product_info_content p {
  line-height: 28px;
}

.product_d_table {
  padding: 10px 0 22px;
}

.product_d_table table {
  border-top: 1px solid #ddd;
  width: 100%;
}

.product_d_table table tbody tr {
  border-bottom: 1px solid #ddd;
}

.product_d_table table tbody tr td {
  padding: 7px 17px;
}

.product_d_table table tbody tr td:first-child {
  border-right: 1px solid #ddd;
  width: 30%;
  font-weight: 700;
}

.product_d_inner {
  padding: 0 30px 30px;
}

@media only screen and (max-width: 767px) {
  .product_d_inner {
    padding: 0 20px 30px;
  }
}

.product_info_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}

.product_info_inner .product_ratting {
  margin-bottom: 10px;
}

.product_info_inner .product_ratting p {
  margin-bottom: 5px;
}

.product_info_inner .product_ratting strong {
  margin-top: 10px;
  display: block;
  margin-bottom: 8px;
}

.reviews_wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .reviews_wrapper h2 {
    font-size: 15px;
  }
}

.reviews_wrapper .product_rating {
  margin-bottom: 20px;
}

.reviews_wrapper .product_rating h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

.comment_title {
  margin-bottom: 20px;
}

.product_review_form input {
  border: 1px solid #ddd;
  background: none;
  width: 100%;
  height: 40px;
  padding: 0 20px;
}

.product_review_form textarea {
  border: 1px solid #ddd;
  background: none;
  height: 120px;
  resize: none;
  width: 100%;
  margin-bottom: 14px;
  padding: 0 20px;
}

.product_review_form p {
  margin-bottom: 7px;
}

.reviews_comment_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 22px;
}

.reviews_comment_box .comment_text {
  width: 100%;
  border: 1px solid #ebebeb;
  position: relative;
  margin-left: 21px;
  padding: 12px;
  border-radius: 3px;
}

.reviews_comment_box .comment_text::before {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  content: "";
  display: block;
  height: 10px;
  left: -6px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}

.reviews_meta p {
  font-size: 15px;
  margin-bottom: 15px;
}

.reviews_meta p strong {
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
}

.reviews_meta .product_rating {
  float: right;
}

.reviews_meta .product_rating ul li {
  display: inline-block;
}

.reviews_meta .product_rating ul li a {
  color: #0066c0;
}

.s-tab-zoom.owl-carousel .owl-nav {
  display: block;
}

.s-tab-zoom.owl-carousel .owl-nav div {
  position: absolute;
  background: #f2f2f2;
  border-radius: 3px;
  height: 32px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  width: 32px;
  text-align: center;
  line-height: 32px;
  left: -7px;
  font-size: 18px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.s-tab-zoom.owl-carousel .owl-nav div:hover {
  background: var(--main-bg-color);
  color: #ffffff;
}

.s-tab-zoom.owl-carousel .owl-nav div.owl-next {
  right: -7px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product-details-tab {
    margin-bottom: 56px;
  }
}

.product-details-tab:hover .s-tab-zoom.owl-carousel .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.single-zoom-thumb {
  margin-top: 20px !important;
  width: 90%;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-zoom-thumb {
    width: 92%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-zoom-thumb {
    width: 93%;
  }
}

@media only screen and (max-width: 767px) {
  .single-zoom-thumb {
    width: 101%;
  }
}

.single-zoom-thumb ul li {
  border: 1px solid #ddd;
}

.single-zoom-thumb ul li a {
  width: 100%;
}

/* 12. product grouped css here */

.grouped_form {
  border: 1px solid #ebebeb;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grouped_form {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .grouped_form {
    margin-bottom: 17px;
  }
}

.grouped_form table {
  width: 100%;
}

.grouped_form table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.grouped_form table tbody tr td {
  border-right: 1px solid #ddd;
  padding: 11px 5px;
  text-align: center;
}

.grouped_form table tbody tr td input[type="checkbox"] {
  width: 20px;
  height: 21px;
}

.grouped_form table tbody tr td input[type="number"] {
  width: 50px;
  background: inherit;
  border: 1px solid #ebebeb;
  padding: 0 5px;
  height: 40px;
}

.grouped_form table tbody tr td.grouped-product-list.quantity {
  min-width: 80px;
  text-align: center;
  line-height: 12px;
}

.grouped_form table tbody tr td.grouped-product-list.label {
  min-width: 188px;
  font-weight: 500;
  font-size: 14px;
}

.grouped_form table tbody tr td.grouped-product-list.label a:hover {
  color: var(--main-bg-color);
}

.grouped_form table tbody tr td.grouped-product-list.price {
  font-size: 14px;
  font-weight: 500;
  min-width: 190px;
}

.grouped_form table tbody tr td.grouped-product-list.price p {
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

.grouped_form table tbody tr td.grouped-product-list.price p::before {
  color: #0066c0;
  content: "\f058";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 40px;
}

.grouped_form table tbody tr td:last-child {
  border-right: 0;
}

.grouped_form table tbody tr:last-child {
  border-bottom: 0;
}

.box_quantity.group button {
  margin-left: 0;
}

/*product grouped css end*/

/*variabla product css here*/

.p_section1.related_product .slick-list {
  padding-bottom: 144px !important;
  margin-bottom: -135px;
}

.variable_product .niceselect_option .list {
  width: 100%;
}

.product_d_meta {
  margin-bottom: 20px;
}

.product_d_meta span {
  display: block;
  line-height: 18px;
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 400;
}

.product_d_meta span:last-child {
  margin-bottom: 0;
}

.product_d_meta span a:hover {
  color: var(--main-bg-color);
}

/*product sidebar css here*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_section .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product_section .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.product_sidebar .product_description p {
  width: 100%;
}

.product_sidebar .product_variant.quantity input {
  width: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .product_variant.quantity input {
    width: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_variant.quantity input {
    width: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product_variant.quantity input {
    width: 60px;
  }
}

.product_sidebar .product_variant.quantity button {
  min-width: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .product_variant.quantity button {
    min-width: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_variant.quantity button {
    min-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product_variant.quantity button {
    min-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product-details-tab {
    margin-bottom: 0;
  }
}

.product_sidebar .price_box span.current_price {
  font-size: 16px;
}

.product_sidebar .price_box span.old_price {
  font-size: 15px;
}

.product_sidebar .price_box span.regular_price {
  font-size: 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_sidebar .add_to_cart a {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .add_to_cart a {
    width: 190px;
  }
}

.product_right_sidebar {
  padding: 25px 25px 0;
  margin-bottom: 55px;
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar {
    padding: 25px 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar .product-details-tab {
    margin-bottom: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_right_sidebar .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_right_sidebar .single-zoom-thumb {
    width: 92%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_right_sidebar .single-zoom-thumb {
    width: 92%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_right_sidebar .priduct_social ul li {
    margin-right: 2px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_right_sidebar .priduct_social ul li a {
    padding: 0 6px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_right_sidebar .product_d_right h3 {
    font-size: 18px;
  }
}

/* 13. cart page css here */

.cart_page_bg {
  background: #f5f5f9;
  padding: 55px 0 133px;
}

@media only screen and (max-width: 767px) {
  .cart_page_bg {
    padding: 55px 0 111px;
  }
}

.shopping_cart_area {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #ebebeb;
}

.header_cart_page {
  border-bottom: 1px solid #ebebeb;
}

.other_bread {
  padding-top: 41px;
  padding-bottom: 41px;
}

.table_desc {
  border: 1px solid #ebebeb;
  margin-bottom: 60px;
}

.table_desc .cart_page table {
  width: 100%;
}

.table_desc .cart_page table thead tr th {
  border-bottom: 3px solid var(--main-bg-color);
  border-right: 1px solid #ebebeb;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;
}

.table_desc .cart_page table tbody tr td {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  text-align: center;
  padding: 10px;
}

.table_desc .cart_page table tbody tr td.product_remove {
  min-width: 100px;
}

.table_desc .cart_page table tbody tr td.product_remove a {
  font-size: 20px;
  color: #000000;
}

.table_desc .cart_page table tbody tr td.product_remove a:hover {
  color: var(--main-bg-color);
}

.table_desc .cart_page table tbody tr td.product_thumb {
  max-width: 180px;
}

.table_desc .cart_page table tbody tr td.product_thumb a img {
  width: 100px;
}

.table_desc .cart_page table tbody tr td.product_name {
  min-width: 180px;
}

.table_desc .cart_page table tbody tr td.product_name a {
  color: #000000;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
}

.table_desc .cart_page table tbody tr td.product_name a:hover {
  color: var(--main-bg-color);
}

.table_desc .cart_page table tbody tr td.product-price {
  min-width: 130px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.table_desc .cart_page table tbody tr td.product_quantity {
  min-width: 180px;
}

.table_desc .cart_page table tbody tr td.product_quantity label {
  font-weight: 500;
  margin-right: 5px;
}

.table_desc .cart_page table tbody tr td.product_quantity input {
  width: 60px;
  height: 40px;
  padding: 0 5px 0 10px;
  background: none;
  border: 1px solid #ebebeb;
}

.table_desc .cart_page table tbody tr td .product_total {
  min-width: 120px;
}

.cart_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

@media only screen and (max-width: 767px) {
  .cart_submit {
    text-align: center;
  }
}

.cart_submit button {
  background: #000000;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.cart_submit button:hover {
  background: var(--main-bg-color);
}

.coupon_inner {
  padding: 10px 20px 25px;
}

@media only screen and (max-width: 767px) {
  .coupon_inner {
    padding: 10px 14px 25px;
  }
}

.coupon_inner p {
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner button {
  background: #000000;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.coupon_inner button:hover {
  background: var(--main-bg-color);
}

.coupon_inner input {
  border: 1px solid #ebebeb;
  height: 42px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

.coupon_inner a {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  border-radius: 3px;
}

.coupon_inner a:hover {
  color: var(--main-bg-color);
}

.coupon_code {
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .coupon_code.left {
    margin-bottom: 50px;
  }
}

.coupon_code h3 {
  color: #ffffff;
  line-height: 36px;
  padding: 5px 15px;
  background: #000000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .coupon_code h3 {
    line-height: 28px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

.cart_subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_subtotal p {
  font-weight: 600;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .cart_subtotal p {
    font-size: 13px;
  }
}

.cart_subtotal p.cart_amount {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .cart_subtotal p.cart_amount {
    font-size: 13px;
  }
}

.cart_subtotal p span {
  margin-right: 30px;
}

.checkout_btn {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .checkout_btn {
    text-align: center;
  }
}

.checkout_btn a {
  background: var(--main-bg-color);
  color: #ffffff;
  font-size: 15px;
  padding: 3px 14px;
  line-height: 30px;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 0;
}

.checkout_btn a:hover {
  background: #000000;
  color: #ffffff;
}




/*cart page css end*/

/* 14. checkout page css here */

.checkout_page_bg {
  background: #f5f5f9;
  padding: 60px 0 133px;
}

@media only screen and (max-width: 767px) {
  .checkout_page_bg {
    padding: 60px 0 111px;
  }
}

.checkout_form_left {
  background: #ffffff;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .checkout_form_left {
    margin-bottom: 60px;
  }
}

.checkout_form_right {
  background: #ffffff;
  padding: 20px;
}

.user-actions {
  margin-bottom: 20px;
}

.user-actions h3 {
  font-size: 13px;
  font-weight: 400;
  background: #ffffff;
  padding: 15px 10px;
  border-top: 3px solid var(--main-bg-color);
  margin-bottom: 0;
}

.user-actions h3 a {
  color: var(--main-bg-color);
}

.checkout_info {
  border: 1px solid #ebebeb;
  background: #ffffff;
  margin-top: 25px;
  padding: 20px 30px;
}

.checkout_info p {
  margin-bottom: 15px;
}

.checkout_info a {
  color: var(--main-bg-color);
  margin-top: 15px;
  display: block;
}

.form_group {
  margin-bottom: 20px;
}

.form_group label {
  font-size: 14px;
  display: block;
  line-height: 18px;
}

.form_group input {
  border: 1px solid #ebebeb;
  background: none;
  height: 45px;
  width: 350px;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .form_group input {
    width: 100%;
  }
}

.form_group button {
  display: inline-block;
  width: 80px;
  background: #000000;
  border: 0;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
}

.form_group button:hover {
  background: var(--main-bg-color);
}

.form_group input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.form_group.group_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form_group.group_3 label {
  margin-bottom: 0;
  line-height: 34px;
  cursor: pointer;
}

.form_group.group_3 label:hover {
  color: var(--main-bg-color);
}

#checkout_coupon input {
  background: none;
  border: 1px solid #ebebeb;
  width: 200px;
  height: 45px;
  font-size: 12px;
  padding: 0 20px;
  color: #000000;
}

#checkout_coupon button {
  width: 130px;
  background: #000000;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  height: 45px;
  line-height: 45px;
  border-radius: 3px;
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  #checkout_coupon button {
    margin-top: 20px;
  }
}

#checkout_coupon button:hover {
  background: var(--main-bg-color);
}

.mb-20 {
  margin-bottom: 20px;
}

.checkout_form h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  background: #000000;
  font-weight: 500;
}

.checkout_form input {
  border: 1px solid #ebebeb;
  background: none;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  color: #000000;
}

.checkout_form .nice-select {
  width: 100%;
}

.checkout_form .nice-select ul.list {
  width: 100%;
  height: 180px;
  overflow: auto;
}

.checkout_form .nice-select::after {
  top: 56%;
}

.checkout_form label {
  font-weight: 500;
}

.checkout_form label span {
  color: var(--main-bg-color);
}

.checkout_form label.righ_0 {
  cursor: pointer;
  font-size: 15px;
  line-height: 27px;
  padding: 5px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background: #000000;
  font-weight: 500;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .checkout_form label.righ_0 {
    font-size: 13px;
    line-height: 25px;
    padding: 3px 10px;
  }
}

.checkout_form label.righ_0:hover {
  background: var(--main-bg-color);
}

.checkout_form input[type="checkbox"] {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.order_button button {
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background: var(--main-bg-color);
  font-weight: 500;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .order_button button {
    font-size: 14px;
  }
}

.order_button button:hover {
  background: #000000;
}

.order-notes textarea {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 45px;
  max-width: 100%;
  padding: 0 30px 0 20px;
  background: none;
  font-size: 13px;
  resize: none;
  line-height: 45px;
  width: 100%;
  color: #000000;
}

.order-notes label {
  line-height: 13px;
}

.order_table {
  margin-bottom: 35px;
}

.order_table table {
  width: 100%;
}

.order_table table thead tr th {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tbody tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr th {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.panel-default input[type="radio"] {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.panel-default img {
  width: 160px;
}

.order_button button {
  border: 0;
}

.card-body1 {
  margin-bottom: 15px;
}

/*checkout page css end*/

/* 22. wishlist css here */

.wishlist_page_bg {
  background: #f5f5f9;
  padding: 55px 0 133px;
}

@media only screen and (max-width: 767px) {
  .wishlist_page_bg {
    padding: 55px 0 111px;
  }
}

.wishlist_inner {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 60px;
}

.table_desc.wishlist table tbody tr:last-child td {
  border-bottom: 0;
}

.table_desc.wishlist {
  margin-bottom: 0;
}

.table_desc.wishlist table tbody tr td.product_total a {
  background: var(--main-bg-color);
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 18px;
  padding: 10px 20px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 3px;
}

.table_desc.wishlist table tbody tr td.product_total a:hover {
  background: #000000;
}

.wishlist_share {
  text-align: center;
  padding: 20px 0;
  border: 1px solid #ebebeb;
  background: #ffffff;
}

.wishlist_share h4 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.wishlist_share ul li {
  display: inline-block;
}

.wishlist_share ul li a {
  padding: 0 10px;
  display: block;
}

.wishlist_share ul li a:hover {
  color: var(--main-bg-color);
}

/*wishlist css end*/

/* 15. contact page css here */

.contact_page_bg {
  background: #f5f5f9;
  padding: 60px 0 133px;
}

@media only screen and (max-width: 767px) {
  .contact_page_bg {
    padding: 60px 0 111px;
  }
}

.contact_area {
  padding: 20px;
  background: #ffffff;
}

.contact_message h3 {
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_message h3 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .contact_message h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.contact_message p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}

.contact_message ul li {
  padding: 13px 0;
  border-top: 1px solid #ebebeb;
}

.contact_message ul li:last-child {
  padding-bottom: 0;
}

.contact_message ul li i {
  margin-right: 10px;
}

.contact_message ul li a:hover {
  color: var(--main-bg-color);
}

.contact_message label {
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact_message input {
  border: 1px solid #ebebeb;
  height: 45px;
  background: #ffffff;
  width: 100%;
  padding: 0 20px;
  color: #757575;
}

.contact_message textarea {
  height: 170px;
  border: 1px solid #ebebeb;
  background: #ffffff;
  resize: none;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 20px;
  color: #000000;
}

.contact_message button {
  font-weight: 400;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: capitalize;
  border: none;
  background: #000000;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
}

.contact_message button:hover {
  background: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_message.content {
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .contact_message.content {
    margin-bottom: 42px;
  }
}

.contact_message.form p.form-messege {
  margin-bottom: 0;
}

.contact_map {
  margin-bottom: 58px;
}

.map-area #googleMap {
  height: 500px;
  width: 100%;
}

/*contact page css end*/

/* 17. login page css here */

.login_page_bg {
  background: #f5f5f9;
  padding: 60px 0 133px;
}

@media only screen and (max-width: 767px) {
  .login_page_bg {
    padding: 60px 0 110px;
  }
}

.account_form h2 {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .account_form h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.account_form form {
  border: 1px solid #ebebeb;
  padding: 23px 20px 29px;
  border-radius: 5px;
  background: #ffffff;
}

.account_form label {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  line-height: 12px;
  margin-bottom: 12px;
}

.account_form label:hover {
  color: var(--main-bg-color);
}

.account_form input {
  border: 1px solid #ebebeb;
  height: 40px;
  max-width: 100%;
  padding: 0 20px;
  background: none;
  width: 100%;
}

.account_form button {
  background: var(--main-bg-color);
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  line-height: 21px;
  padding: 5px 20px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-left: 20px;
  border-radius: 20px;
}

.account_form button:hover {
  background: #000000;
}

.login_submit label input[type="checkbox"] {
  width: 15px;
  height: 13px;
  margin-right: 3px;
}

.login_submit {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login_submit {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .login_submit {
    text-align: left;
  }
}

.login_submit a {
  font-size: 13px;
  float: left;
  line-height: 39px;
}

.login_submit a:hover {
  color: var(--main-bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login_submit a {
    float: none;
    line-height: 18px;
    display: block;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .login_submit a {
    float: none;
    line-height: 18px;
    display: block;
    margin-bottom: 20px;
  }
}

.account_form p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .account_form.register {
    margin-top: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .account_form.register button {
    margin-left: 0;
  }
}

/*login page css end */

/* 16. faq page css here */

.faq_page_bg {
  background: #f5f5f9;
  padding: 60px 0 133px;
}

@media only screen and (max-width: 767px) {
  .faq_page_bg {
    padding: 60px 0 105px;
  }
}

.faq_content_area {
  padding-bottom: 54px;
}

.accordion_area .card-header {
  background: var(--main-bg-color);
}

.accordion_area .card-header:first-child {
  border-radius: inherit;
}

.card.card_dipult {
  border: none;
  margin-bottom: 10px;
}

.card.card_dipult:last-child {
  margin-bottom: 0;
}

.card-header.card_accor {
  padding: 0;
  border: none;
}

.card-header.card_accor button {
  height: 45px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  white-space: initial;
}

@media only screen and (max-width: 767px) {
  .card-header.card_accor button {
    height: 60px;
    padding: 5px 27px 5px 7px;
  }
}

.card-header.card_accor button i {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .card-header.card_accor button i {
    right: 10px;
  }
}

.card-header.card_accor button.btn-link {
  border: 1px solid var(--main-bg-color);
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.card-header.card_accor button.btn-link.collapsed {
  background: #ffffff;
  border: 1px solid #ebebeb;
  width: 100%;
  text-align: left;
  color: #000000;
}

.card-header.card_accor button.btn-link i.fa-plus {
  display: none;
}

.card-header.card_accor button.btn-link.collapsed i.fa-plus {
  display: block;
}

.card-header.card_accor button.btn-link.collapsed i.fa-minus {
  display: none;
}

.faq_content_wrapper h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 18px;
}

@media only screen and (max-width: 767px) {
  .faq_content_wrapper h4 {
    font-size: 14px;
    line-height: 24px;
  }
}

/*faq page css end*/

/*  18. my account css here */

.account_page_bg {
  background: #f5f5f9;
  padding: 60px 0 133px;
}

@media only screen and (max-width: 767px) {
  .account_page_bg {
    padding: 60px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard_tab_button {
    margin-bottom: 20px;
  }
}

.dashboard_tab_button ul li {
  margin-bottom: 5px;
}

.dashboard_tab_button ul li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  background: #000000;
  border-radius: 3px;
}

.dashboard_tab_button ul li a:hover {
  background: var(--main-bg-color);
  color: #ffffff;
}

.dashboard_tab_button ul li a.active {
  background: var(--main-bg-color);
}

.dashboard_content h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 15px;
}

.dashboard_content h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dashboard_content button {
  font-weight: 500;
  border: 0;
  background: #000000;
  color: #ffffff;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  margin-top: 12px;
}

.dashboard_content button:hover {
  background: var(--main-bg-color);
}

.dashboard_content p a {
  color: var(--main-bg-color);
  font-weight: 500;
}

.table-responsive table thead {
  background: #ebebeb;
}

.table-responsive table thead tr th {
  text-align: center;
}

.table-responsive table tbody tr td {
  border-right: 1px solid #ebebeb;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
}

.table-responsive table tbody tr td:last-child a {
  color: var(--main-bg-color);
}

.table-responsive .table {
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.dashboard_content address {
  font-weight: 500;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.input-radio span {
  font-weight: 500;
  padding-right: 10px;
}

.account_login_form form>input {
  border: 1px solid #ddd;
  background: none;
  height: 40px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
  color: #000000;
}

.account_login_form form span.custom_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.account_login_form form span.custom_checkbox input {
  vertical-align: middle;
  margin-right: 10px;
  top: 5px;
}

.account_login_form form span.custom_checkbox label {
  margin-bottom: 0;
}

/*my account css end*/

/* 10. about page css here */

.about_bg_area {
  background: #f5f5f9;
  padding: 55px 0 133px;
}

@media only screen and (max-width: 767px) {
  .about_bg_area {
    padding: 55px 0 111px;
  }
}

.about_section {
  background: #ffffff;
}

.about_content {
  padding: 26px 30px 30px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .about_content {
    padding: 23px 10px 28px;
  }
}

.about_content h1 {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_content h1 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_content h1 {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .about_content h1 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 13px;
  }
}

.about_content p {
  font-size: 14px;
  line-height: 26px;
  max-width: 890px;
  margin: 0 auto;
}

.chose_content h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 19px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .chose_content h3 {
    font-size: 16px;
    argin-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .chose_content h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 11px;
  }
}

.about_signature {
  margin-top: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_signature {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about_signature {
    margin-top: 22px;
  }
}

.choseus_area {
  margin-bottom: 60px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  padding: 100px 0 96px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choseus_area {
    padding: 60px 0 55px;
  }
}

@media only screen and (max-width: 767px) {
  .choseus_area {
    padding: 60px 0 28px;
  }
}

.single_chose {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .single_chose {
    margin-bottom: 25px;
  }
}

.single_chose:hover .chose_icon {
  -webkit-transform: rotatey(180deg);
  -ms-transform: rotatey(180deg);
  transform: rotatey(180deg);
}

.chose_icon {
  margin-bottom: 23px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .chose_icon {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .chose_icon {
    margin-bottom: 14px;
  }
}

.single_gallery_section {
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .single_gallery_section {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .about_gallery_section {
    margin-bottom: 31px;
  }
}

@media only screen and (max-width: 767px) {
  .gallery_thumb img {
    width: 100%;
  }
}

.about_gallery_content {
  padding: 30px;
}

.about_gallery_content h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
  .about_gallery_content h3 {
    font-size: 16px;
    margin-bottom: 7px;
  }
}

.about_gallery_content p {
  line-height: 26px;
}

.team_container {
  padding-bottom: 45px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team_container {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .team_container {
    padding-bottom: 15px;
  }
}

.team_member {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team_member {
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .team_member {
    margin-bottom: 28px;
  }
}

.team_thumb {
  margin-bottom: 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team_thumb {
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .team_thumb {
    margin-bottom: 18px;
  }
}

.team_content h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team_content h3 {
    line-height: 18px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .team_content h3 {
    line-height: 18px;
    margin-bottom: 4px;
    font-size: 17px;
  }
}

.team_content h5 {
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 12px;
  padding-bottom: 14px;
  position: relative;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team_content h5 {
    margin-bottom: 11px;
    padding-bottom: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .team_content h5 {
    margin-bottom: 11px;
    padding-bottom: 11px;
  }
}

.team_content h5::before {
  position: absolute;
  content: "";
  width: 37px;
  height: 2px;
  background: var(--main-bg-color);
  bottom: 0;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
}

.team_content p {
  font-size: 14px;
  line-height: 25px;
}

.team_content p a:hover {
  color: var(--main-bg-color);
}

/*about page css end*/

/*--
    16. About US css
---------------------------*/

@media only screen and (max-width: 767px) {
  .faq-client-say-area .section_title {
    margin-bottom: 22px;
  }
}

.panel-heading .panel-title {
  margin-bottom: 0;
}

.panel-heading .panel-title a {
  background: #ffffff;
  display: block;
  padding: 14px 20px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  position: relative;
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 500;
}

.panel-heading .panel-title a::before {
  position: absolute;
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #333;
  font-size: 20px;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 25px;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 100%;
  z-index: 4;
  margin-top: 0px;
}

.panel-heading .panel-title a[aria-expanded="true"]::before {
  background: #fff;
  color: var(--main-bg-color);
}

.faq-style-wrap .panel-title a[aria-expanded="true"] {
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background: var(--main-bg-color);
}

.panel.panel-default {
  margin-bottom: 21px;
}

.panel.panel-default:last-child {
  margin-bottom: 0;
}

.panel-body {
  padding: 15px;
  border: 1px solid #ddd;
  background: #ffffff;
}

/*--
    08. Testimonial Css
--------------------------------*/

@media only screen and (max-width: 767px) {
  .testimonials-area {
    margin-top: 57px;
  }
}

.testimonial-two {
  background: #fff;
  border-radius: 7px;
  padding: 55px 25px;
}

@media only screen and (max-width: 767px) {
  .testimonial-two {
    padding: 30px 18px;
  }
}

.testimonial-two .owl-dots {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.testimonial-two .owl-dots .owl-dot {
  width: 6px;
  height: 6px;
  background: var(--main-bg-color);
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.testimonial-two .owl-dots .owl-dot:hover {
  width: 25px;
  border-radius: 30px;
}

.testimonial-two .owl-dots .owl-dot.active {
  width: 25px;
  border-radius: 30px;
}

.quote-container {
  margin: auto;
  position: relative;
}

.quote-container .quote-image {
  display: inline-block;
  height: 90px;
  margin-right: 10px;
  overflow: hidden;
  vertical-align: middle;
  width: 90px;
  border: 7px solid #f6f5f1;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  top: -45px;
}

.quote-container .testimonials-text {
  position: relative;
  margin-top: 20px;
  padding-bottom: 37px;
}

.quote-container .testimonials-text::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 23px;
  height: 17px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
}

.quote-container .author {
  margin-top: 25px;
}

.quote-container .author h6 {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--main-bg-color);
}

.testimonial-wrap-two .quote-container .quote-image {
  margin-right: 0px;
  position: inherit;
  left: 0px;
  top: 0px;
}

/* 11. blog page css here */

.blog_bg_area {
  background: #f5f5f9;
  padding: 55px 0 133px;
}

@media only screen and (max-width: 767px) {
  .blog_bg_area {
    padding: 55px 0 110px;
  }
}

.blog_wrapper_inner {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #ebebeb;
}

.blog_header {
  margin-bottom: 30px;
  text-align: center;
}

.blog_header h1 {
  font-size: 32px;
  line-height: 28px;
  margin-bottom: 0;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .blog_header h1 {
    font-size: 28px;
    line-height: 28px;
  }
}

.blog_wrapper .single_blog {
  overflow: hidden;
  border-right: 0;
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.blog_wrapper .single_blog:last-child {
  padding: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .single_blog {
    padding: 0 0 25px;
    margin-bottom: 30px;
  }
}

.blog_wrapper .blog_thumb {
  float: left;
  width: 45%;
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_thumb {
    width: 100%;
  }
}

.blog_wrapper .blog_thumb iframe {
  height: 325px;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_wrapper .blog_thumb iframe {
    height: 270px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper .blog_thumb iframe {
    height: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_thumb iframe {
    height: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_thumb a {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper .blog_thumb a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_thumb a img {
    width: 100%;
  }
}

.blog_wrapper .blog_content {
  width: 55%;
  float: left;
  text-align: left;
  padding: 15px 15px 0 30px;
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_content {
    padding: 20px 0 0 0;
    width: 100%;
  }
}

.blog_wrapper .blog_content h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper .blog_content h4 {
    font-size: 19px;
    margin-bottom: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_content h4 {
    font-size: 17px;
    margin-bottom: 9px;
  }
}

.blog_wrapper .blog_meta {
  margin-bottom: 17px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper .blog_meta {
    margin-bottom: 12px;
    padding-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_meta {
    margin-bottom: 13px;
    padding-bottom: 13px;
  }
}

.blog_wrapper .blog_meta span {
  font-size: 13px;
  line-height: 24px;
}

.blog_wrapper .blog_meta span a {
  color: var(--main-bg-color);
}

.blog_wrapper .blog_meta span a:hover {
  text-decoration: underline;
}

.blog_wrapper .blog_meta span.author {
  margin-right: 4px;
}

.blog_wrapper .blog_meta span.author a {
  margin-right: 5px;
}

.blog_wrapper .blog_desc {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .blog_desc {
    margin-bottom: 12px;
  }
}

.blog_wrapper .blog_desc p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

.blog_wrapper .btn_more a {
  color: var(--main-bg-color);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.blog_wrapper .btn_more a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .blog_bidio .blog_content {
    padding-top: 13px;
  }
}

.readmore_button a {
  height: 38px;
  line-height: 40px;
  background: var(--main-bg-color);
  border: 0;
  color: #fff;
  display: block;
  text-align: center;
  padding: 0 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
  border-radius: 3px;
  max-width: 170px;
}

.readmore_button a:hover {
  background: #000000;
}

@media only screen and (max-width: 767px) {
  .readmore_button a {
    max-width: 130px;
  }
}

.blog_sidebar_widget {
  padding: 30px 20px 40px 20px;
  background: #ffffff;
  border: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_sidebar_widget {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_sidebar_widget {
    margin-top: 60px;
  }
}

.blog_sidebar_widget .widget_title {
  position: relative;
  margin-bottom: 20px;
}

.blog_sidebar_widget .widget_title h3 {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 25px;
  padding-top: 0;
  font-weight: 500;
}

.blog_sidebar_widget .widget_list.comments .post_thumb a img {
  border-radius: 50%;
}

.blog_sidebar_widget .widget_list.comments .post_info span a {
  color: var(--main-bg-color);
}

.blog_sidebar_widget .widget_list.comments .post_info a:hover {
  color: var(--main-bg-color);
}

.blog_sidebar_widget .widget_categories .widget_title {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_sidebar_widget .widget_categories {
    margin-bottom: 44px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_sidebar_widget .widget_categories {
    margin-bottom: 46px;
  }
}

.widget_search input {
  height: 35px;
  border: 1px solid #ebebeb;
  background: #fff;
  color: #000000;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 15px;
}

.widget_search button {
  color: #fff;
  display: inline-block;
  background: #000;
  border: none;
  padding: 0 20px;
  height: 34px;
  line-height: 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.widget_search button:hover {
  background: var(--main-bg-color);
}

.tag_widget ul li {
  display: inline-block;
}

.tag_widget ul li a {
  margin: 0 6px 0 0;
  display: block;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #ebebeb;
  background: #fff;
  padding: 0 15px;
  line-height: 29px;
  border-radius: 3px;
}

.tag_widget ul li a:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #fff;
}

.post_thumb {
  width: 60px;
  float: left;
  margin-right: 10px;
}

.post_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.post_wrapper:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.post_info h4 {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 14px;
  margin-bottom: 5px;
}

.post_info h4 a:hover {
  color: var(--main-bg-color);
}

.post_info span {
  font-size: 12px;
}

.blog_categories ul li {
  border-top: 1px solid #ddd;
}

.blog_categories ul li a {
  padding: 10px 0;
  text-transform: capitalize;
  display: inline-block;
  margin-left: 0;
}

.blog_categories ul li a:hover {
  color: var(--main-bg-color);
}

.blog_categories ul li:last-child a {
  padding-bottom: 0;
}

.shipping_area.shipping_contact.blog_shipping {
  margin-top: 94px;
}

.blog_pagination .pagination {
  border: 1px solid #ebebeb;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 0;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .blog_pagination .pagination {
    margin-top: 0;
  }
}

/*blog page css end*/

/*blog fullwidth css here*/

.blog_fullwidth .single_blog {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 55px;
}

.blog_fullwidth .blog_thumb {
  width: 100%;
  margin-right: 0;
}

.blog_fullwidth .blog_thumb iframe {
  height: 550px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_fullwidth .blog_thumb iframe {
    height: 440px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_fullwidth .blog_thumb iframe {
    height: 440px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_fullwidth .blog_thumb iframe {
    height: 200px;
  }
}

.blog_fullwidth .blog_content {
  width: 100%;
  padding: 20px 0 0;
}

.blog_thumb_active:hover .owl-nav>div {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.blog_thumb_active:hover .owl-nav>div.owl-next {
  right: 20px;
}

.blog_thumb_active .owl-nav>div {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  background: rgba(46, 110, 213, 0.6);
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  left: 40px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.blog_thumb_active .owl-nav>div.owl-next {
  right: 40px;
  left: auto;
}

.blog_aduio_icon {
  margin-bottom: 12px;
  margin-top: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_aduio_icon {
    margin-bottom: 6px;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_aduio_icon {
    margin-bottom: 8px;
    margin-top: 17px;
  }
}

.blog_aduio_icon audio {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_reverse .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .blog_reverse .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper_sidebar .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper_sidebar .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.blog_nosidebar .blog_thumb {
  width: 33%;
}

@media only screen and (max-width: 767px) {
  .blog_nosidebar .blog_thumb {
    width: 100%;
  }
}

.blog_nosidebar .blog_content {
  width: 67%;
}

@media only screen and (max-width: 767px) {
  .blog_nosidebar .blog_content {
    width: 100%;
  }
}

/* 12. blog details css here */

.post_header {
  margin-bottom: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post_header {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .post_header {
    margin-bottom: 22px;
  }
}

.post_header h3 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .post_header h3 {
    font-size: 26px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post_header h3 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .post_header h3 {
    font-size: 17px;
    line-height: 24px;
  }
}

.blog_details {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ebebeb;
}

.blog_details .single_blog {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details .blog_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .blog_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details .blog_wrapper {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .blog_wrapper {
    margin-bottom: 60px;
  }
}

.blog_details .blog_meta {
  margin-bottom: 0;
}

.blog_details .blog_thumb {
  width: 100%;
  margin-right: 0;
}

.blog_details .blog_content {
  width: 100%;
  padding-top: 22px;
}

.blog_details .post_content>p {
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .blog_details .post_content>p {
    font-size: 14px;
  }
}

.blog_details .post_content blockquote {
  margin: 25px 0 25px 40px;
  font-style: italic;
  padding: 30px 45px;
  background: #f6f6f6;
  border: 1px solid #ebebeb;
  border-left: 4px solid var(--main-bg-color);
}

.blog_details .post_content blockquote p {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .blog_details .post_content blockquote p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .post_content blockquote {
    margin: 23px 0 23px 0;
    padding: 20px 12px 15px;
  }
}

.blog_details .social_sharing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog_details .social_sharing p {
  font-size: 13px;
  text-transform: capitalize;
  margin-right: 20px;
  margin-bottom: 0;
  line-height: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .blog_details .social_sharing p {
    font-size: 13px;
    margin-right: 10px;
  }
}

.blog_details .social_sharing ul li {
  display: inline-block;
}

.blog_details .social_sharing ul li a {
  background: #e6e6e6;
  border-radius: 100%;
  display: inline-block;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  margin-right: 5px;
  text-align: center;
  width: 26px;
}

.blog_details .social_sharing ul li a:hover {
  color: #ffffff;
  background: var(--main-bg-color);
}

@media only screen and (max-width: 767px) {
  .blog_details .social_sharing ul li a {
    margin-right: 0;
    font-size: 11px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
}

.blog_details .social_sharing ul li:first-child a {
  padding-left: 0;
  border-left: 0;
}

.entry_content {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 22px;
}

@media only screen and (max-width: 767px) {
  .entry_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
  }
}

.entry_content .post_meta {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .entry_content .post_meta {
    margin-bottom: 10px;
  }
}

.entry_content .post_meta span {
  font-weight: 500;
  font-size: 13px;
}

.entry_content .post_meta span a:hover {
  color: var(--main-bg-color);
}

.comments_box {
  margin-bottom: 58px;
}

.comments_box h3 {
  font-size: 14px;
  margin: 0 0 20px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.comment_list {
  overflow: hidden;
  margin-bottom: 30px;
}

.comment_list:last-child {
  margin-bottom: 0;
}

.comment_list .comment_thumb {
  display: inline-block;
  float: left;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_thumb {
    width: 40px;
  }
}

.comment_list .comment_content {
  margin-left: 70px;
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_content {
    margin-left: 55px;
  }
}

.comment_list .comment_content h5 {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.comment_list .comment_content h5 a:hover {
  color: var(--main-bg-color);
}

.comment_list .comment_content span {
  line-height: 18px;
  margin-bottom: 8px;
  font-size: 13px;
  font-style: italic;
  display: inline-block;
}

.comment_list .comment_content p {
  margin-bottom: 0;
  font-size: 14px;
}

.comment_reply {
  position: absolute;
  top: 20px;
  right: 12px;
}

@media only screen and (max-width: 767px) {
  .comment_reply {
    top: 15px;
  }
}

.comment_reply a {
  padding: 2px 10px;
  border-radius: 3px;
  color: #fff;
  background: #000000;
  display: block;
  font-size: 13px;
}

@media only screen and (max-width: 767px) {
  .comment_reply a {
    line-height: 20px;
    font-size: 13px;
  }
}

.comment_reply a:hover {
  background: var(--main-bg-color);
}

.comments_form h3 {
  font-size: 14px;
  margin: 0 0 20px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.comments_form p {
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comments_form form .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.comments_form form label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.comments_form form textarea {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-bottom: 10px;
  resize: none;
  padding: 10px;
}

.comments_form form input {
  width: 100%;
  height: 40px;
  border: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding: 0 20px;
}

.comments_form form button {
  border: 0;
  line-height: 36px;
  background: #000000;
  font-weight: 500;
}

.comments_form form button:hover {
  background: var(--main-bg-color);
}

@media only screen and (max-width: 767px) {
  .comments_form form button {
    line-height: 38px;
    height: 38px;
    padding: 0 10px;
  }
}

.comment_list.list_two {
  padding-left: 50px;
}

@media only screen and (max-width: 767px) {
  .comment_list.list_two {
    padding-left: 0;
  }
}

.related_posts {
  border-top: 1px solid #ebebeb;
  padding: 57px 0 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related_posts {
    padding: 57px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .related_posts {
    padding: 47px 0 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related_posts .row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}

.related_posts h3 {
  font-size: 14px;
  margin: 0 0 20px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.related_thumb {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related_thumb img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .related_thumb img {
    width: 100%;
  }
}

.related_content h4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.related_content h4 a {
  color: #000000;
}

.related_content h4 a:hover {
  color: var(--main-bg-color);
}

.related_content span {
  font-size: 13px;
  line-height: 17px;
}

.related_content span i {
  margin-right: 3px;
}

.related_content .blog_meta {
  border-bottom: 0;
  padding-bottom: 0;
}

.related_content .blog_meta span {
  font-size: 12px;
  line-height: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_related {
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .single_related {
    margin-bottom: 25px;
  }
}

/*blog details css end*/

/*  24. modal css here */

.modal-dialog.modal-dialog-centered {
  min-width: 1100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 1000px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 950px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 90%;
  }
}

.modal_tab_img {
  text-align: center;
  margin-bottom: 10px;
}

.modal_tab_img img {
  margin: 0 auto;
}

.modal_tab_button ul li a {
  padding: 0;
  border: 1px solid #ebebeb;
  margin: 0 2px;
}

.modal_tab_button ul li a img {
  width: 100%;
}

.modal_title h2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 14px;
  line-height: 26px;
}

.modal_social h2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 20px;
}

.modal_social ul li {
  display: inline-block;
  margin-right: 3px;
}

.modal_social ul li a {
  text-transform: uppercase;
  display: inline-block;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
}

.modal_social ul li.facebook a {
  background: #3c5b9b;
}

.modal_social ul li.facebook a:hover {
  background: var(--main-bg-color);
}

.modal_social ul li.twitter a {
  background: #1da1f2;
}

.modal_social ul li.twitter a:hover {
  background: var(--main-bg-color);
}

.modal_social ul li.pinterest a {
  background: #bd081b;
}

.modal_social ul li.pinterest a:hover {
  background: var(--main-bg-color);
}

.modal_social ul li.google-plus a {
  background: #dc5043;
}

.modal_social ul li.google-plus a:hover {
  background: var(--main-bg-color);
}

.modal_social ul li.linkedin a {
  background: #000;
}

.modal_social ul li.linkedin a:hover {
  background: var(--main-bg-color);
}

.modal_price {
  margin-bottom: 12px;
}

.modal_price span {
  font-weight: 600;
  color: var(--main-bg-color);
  font-size: 16px;
}

.modal_price span.old_price {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 5px;
}

.modal_description p {
  line-height: 24px;
  font-size: 15px;
  margin: 0;
}

.variants_size h2,
.variants_color h2 {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 7px;
  line-height: 20px;
}

.variants_size.mb-15,
.mb-15.variants_color {
  margin-bottom: 24px;
}

.variants_size .select_option,
.variants_color .select_option {
  width: 100%;
  border-radius: 0;
  margin-bottom: 25px;
}

.variants_size .select_option .list,
.variants_color .select_option .list {
  width: 100%;
  border-radius: 0;
}

.modal_add_to_cart {
  margin-bottom: 25px;
}

.modal_add_to_cart form input {
  width: 95px;
  border: 1px solid #ebebeb;
  background: none;
  padding: 0 10px;
  height: 45px;
}

@media only screen and (max-width: 767px) {
  .modal_add_to_cart form input {
    width: 75px;
  }
}

.modal_add_to_cart form button {
  background: none;
  border: 1px solid #000000;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  height: 45px;
  width: 230px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  background: #000000;
  color: #ffffff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .modal_add_to_cart form button {
    width: 130px;
  }
}

.modal_add_to_cart form button:hover {
  background: var(--main-bg-color);
  color: #ffffff;
  border-color: var(--main-bg-color);
}

.modal_body {
  padding: 29px 6px 38px;
}

@media only screen and (max-width: 767px) {
  .modal_body {
    padding: 42px 6px 38px;
  }
}

@media only screen and (max-width: 767px) {
  .modal_body .modal_tab {
    margin-bottom: 60px;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-content button.close {
  position: absolute;
  left: 94%;
  width: 35px;
  height: 35px;
  line-height: 33px;
  display: block;
  border: 1px solid #ebebeb;
  top: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .modal-content button.close {
    left: 83%;
    width: 32px;
    height: 32px;
    line-height: 30px;
    top: 4px;
    margin-bottom: 14px;
  }
}

.modal-content button.close:hover {
  color: var(--main-bg-color);
}

.modal_add_to_cart.mb-15 {
  margin-bottom: 23px;
}

.modal_description.mb-15 {
  margin-bottom: 20px;
}

.product_navactive.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.product_navactive.owl-carousel .owl-nav {
  display: block;
}

.product_navactive.owl-carousel .owl-nav div {
  position: absolute;
  background: #f2f2f2;
  border-radius: 3px;
  color: #333;
  height: 32px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
  width: 32px;
  text-align: center;
  line-height: 32px;
  left: -7px;
  font-size: 18px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.product_navactive.owl-carousel .owl-nav div:hover {
  background: var(--main-bg-color);
  color: #ffffff;
}

.product_navactive.owl-carousel .owl-nav div.owl-next {
  right: -7px;
  left: auto;
}

/*modal css end*/

/*  23. newsletter popup css here */

.newletter-popup {
  background: #fff;
  top: 50% !important;
  margin-top: -179px;
  position: fixed !important;
  padding: 50px;
  text-align: center;
  display: none;
}

@media only screen and (max-width: 767px) {
  .newletter-popup {
    padding: 30px 20px;
    width: 95%;
  }
}

#boxes .newletter-title h2 {
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  #boxes .newletter-title h2 {
    font-size: 22px;
    margin: 0 0 10px;
  }
}

#boxes .box-content label {
  font-weight: 400;
  font-size: 14px;
}

#boxes .box-content .newletter-label {
  width: 70%;
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  #boxes .box-content .newletter-label {
    width: 100%;
  }
}

#frm_subscribe form {
  width: 340px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form {
    width: 100%;
  }
}

#frm_subscribe form input {
  background: #ebebeb;
  padding: 0 127px 0 20px;
  font-size: 13px;
  height: 45px;
  border: 0;
  width: 100%;
  color: #747474;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form input {
    padding: 0 104px 0 20px;
  }
}

#frm_subscribe form a.theme-btn-outlined {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 110px;
  background: #000000;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  line-height: 45px;
}

#frm_subscribe form a.theme-btn-outlined:hover {
  background: var(--main-bg-color);
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form a.theme-btn-outlined {
    width: 90px;
  }
}

#boxes .box-content .subscribe-bottom {
  margin-top: 20px;
}

#boxes .box-content .subscribe-bottom label {
  margin: 0;
  font-size: 13px;
}

#boxes .box-content .subscribe-bottom #newsletter_popup_dont_show_again {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

#boxes #frm_subscribe #notification {
  color: #f00;
}

#boxes #frm_subscribe #notification .success {
  color: #67d7bb;
}

#popup2 {
  position: absolute;
  right: 1px;
  top: 1px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  text-transform: capitalize;
  padding: 6px 12px;
  background: #000000;
  font-weight: 600;
  line-height: 17px;
  color: #ffffff;
  display: block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#popup2:hover {
  background: var(--main-bg-color);
}

/*newsletter popup css end*/

/* 09. error page css heer   */

.error_page_bg {
  background: #f5f5f9;
  padding: 71px 0 153px;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .error_page_bg {
    padding: 47px 0 108px;
    margin-top: 25px;
  }
}

.header_error {
  border-bottom: 1px solid #ebebeb;
}

.error_form {
  text-align: center;
}

.error_form h1 {
  font-size: 200px;
  font-weight: 700;
  color: var(--main-bg-color);
  letter-spacing: 10px;
  line-height: 160px;
  margin: 0 0 63px;
}

@media only screen and (max-width: 767px) {
  .error_form h1 {
    font-size: 130px;
    line-height: 120px;
    letter-spacing: 4px;
    margin: 0 0 40px;
  }
}

.error_form h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .error_form h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 14px;
  }
}

.error_form p {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .error_form p {
    font-size: 15px;
    margin-bottom: 22px;
    line-height: 21px;
  }
}

.error_form form {
  width: 450px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .error_form form {
    width: 100%;
  }
}

.error_form form input {
  padding: 0 60px 0 30px;
  background: #f8f8f8;
  border: 1px solid #ddd;
  color: #000000;
  height: 40px;
  width: 100%;
  border-radius: 3px;
}

.error_form form button {
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  background: no-repeat;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  top: 0;
  text-transform: uppercase;
  padding: 0 15px;
  font-weight: 600;
}

.error_form form button:hover {
  color: var(--main-bg-color);
}

.error_form a {
  color: #fff;
  display: inline-block;
  background: var(--main-bg-color);
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: uppercase;
  margin-top: 35px;
  border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .error_form a {
    padding: 0 20px;
    margin-top: 25px;
  }
}

.error_form a:hover {
  background: #000000;
}

/*404 page css end*/

/*privacy policy css here*/

.privacy_policy_bg {
  //  background: #f5f5f9;
  padding: 53px 0 113px;
}

@media only screen and (max-width: 767px) {
  .privacy_policy_bg {
    padding: 60px 0 110px;
  }
}

.privacy_policy_header {
  text-align: center;
  margin-bottom: 47px;
}

@media only screen and (max-width: 767px) {
  .privacy_policy_header {
    margin-bottom: 44px;
  }
}

.privacy_policy_header h1 {
  font-size: 42px;
  line-height: 45px;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .privacy_policy_header h1 {
    font-size: 25px;
    line-height: 25px;
  }
}

.privacy_content {
  margin-bottom: 44px;
}

.privacy_content:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .privacy_content {
    margin-bottom: 46px;
  }
}

.privacy_content {
  h2 {
    font-size: 25px;
    line-height: 33px;
    color: red;

    font-weight: bold;
    background-color: #fff;
    padding: 8px 15px;
    border-left: 5px solid;
    width: max-content;
  }

  h3 {
    font-weight: 500 !important;

  }
}

@media only screen and (max-width: 767px) {
  .privacy_content h2 {
    font-size: 18px;
    line-height: 26px;
  }
}

.privacy_content h3 {
  font-size: 21px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .privacy_content h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

.privacy_content p {
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .privacy_content p {
    font-size: 15px;
    line-height: 28px;
  }
}

.privacy_content p a {
  color: #a43d21;
}

.privacy_content p a:hover {
  color: var(--main-bg-color);
}

.privacy_content.section_1 h2 {
  margin-bottom: 14px;
}

@media only screen and (max-width: 767px) {
  .privacy_content.section_1 h2 {
    margin-bottom: 9px;
  }
}

.privacy_content.section_2 h2 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .privacy_content.section_2 h2 {
    margin-bottom: 16px;
  }
}

.privacy_content.section_3 h2 {
  margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
  .privacy_content.section_3 h2 {
    margin-bottom: 12px;
  }
}

.ant-layout {
  // background: #fff !important;
  overflow-x: hidden;
  background-color: transparent !important;

}

.ant-btn {

  margin: 0;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #000 !important;
  background-color: #fff !important;

}


.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff !important;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
  border-radius: 6px !important;

}

.ant-btn-primary.width-auto,
.ant-btn-primary.width-auto:hover,
.ant-btn-primary.width-auto:focus {

  width: auto !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: var(--main-bg-color) !important;
  background-color: #fff !important;
  border-color: var(--main-bg-color) !important;
}

.table_subTable {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px !important;
  margin: 0 15px !important;
}





// Dropdown

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #adadad !important;
  border-right-width: 1px !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(238, 115, 136, 0.2);
  border-radius: 6px !important;
}

.ant-select-selection:hover {
  border-color: #adadad !important;
}

.ant-select-selection {
  border-color: #adadad !important;
  border-right-width: 1px !important;
  outline: none !important;
  border-radius: 6px !important;
  margin: 10px;
}

// SELECT ICON

.ant-select-arrow {
  color: rgb(237, 28, 0) !important;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: #000000 !important;
  font-size: 14px;
}

.global-search {

  .ant-select-selection {
    border-radius: 15px !important;

    // overflow: hidden;
    &__rendered {

      height: 38px !important;
    }
  }

  .ant-select-selection:hover {

    border-radius: 15px !important;
  }

  .ant-select-selection:focus {

    border-radius: 15px !important;
  }

  .ant-input {
    height: 38px !important;
    border-radius: 6px !important;
    border: none !important;
    box-shadow: none !important;
    background-color: #efefef !important;
  }

  .ant-input:focus {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-input:hover {
    border: none !important;
    box-shadow: none !important;
  }
}



.ant-tabs-tab-active {
  // background-color: #ffffff;
  color: #000000 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  //border-bottom: 5px solid red;
  //z-index: 9;
}

ul.widget_list {
  text-align: center !important;
}

ul.widget_list li.list_item {
  margin: 0 10px;
}

ul.widget_list li.list_item a i {
  margin-right: 0 !important;
}

.price_box {
  text-align: center !important;
}

.add_to_cart {
  text-align: center !important;
}

.add_to_cart_btn {
  margin: 10px 0;
}

.add_to_cart_input {
  margin: 10px 0;
}

.add_to_cart_input input {
  width: 30%;
}

.auto_brand {
  padding: 20px;
  text-align: center;
}

.auto_brand a {
  text-align: center;
  text-decoration: none;
}

.auto_brand a:hover {
  color: var(--main-bg-color);
}

.auto_brand img {
  width: 30%;
}

.detail_name {}

.table_detail {
  width: 100%;
  margin-top: 8px;
  border: none;
}

.table_detail tr td {
  border-top: 0 !important;
}

.ware_house_title {
  color: #2ed47a;
  font-weight: 700;
}

.list_ware_house {}

.magasin_city {
  float: left;
  position: relative;
  background-color: #fafafa;
  border-radius: 6px;
  padding: 10px 15px;
  margin-right: 10px;
  box-shadow: 0px 0px 20px 0px rgba(163, 163, 163, 0.22);
}

.magasin_city a {
  color: #000000;
}

.magasin_city span {
  position: absolute;
  top: 0;
  height: 11px;
  width: 11px;
  border-radius: 10px;
  background-color: #2ed47a;
}


// input

.ant-input {
  border-radius: 6px !important;
  // background-color: #f9f9f9;
  border-color: #adadad;
}

.textarea_autoplus {
  border-radius: 10px !important;

}

.ant-select-selection {
  margin: 0 !important;
}


.react-multiple-carousel__arrow {
  min-width: 36px !important;
  min-height: 36px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 16px !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 10px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 10px) !important;
}

.react-multiple-carousel__arrow:hover {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 4px 0px rgba(81, 81, 81, 0.16);
}

.react-multi-carousel-dot-list {
  bottom: 0px !important;

}

.home-carousel .react-multi-carousel-list {
  background-color: #fff;
  padding: 0px 0px !important;
  margin: 0;
  margin-bottom: 10px;
  border-radius: 15px;
  border: 1px solid #ddd;
  height: 90px;
}


.react-multi-carousel-dot--active button {
  background: #bfbfbf !important;
}

.react-multi-carousel-dot button {
  border-color: transparent !important;
}

.tag_filter_year {
  margin: 0 5px 12px 0;
  padding: 5px 15px;
  text-transform: capitalize;
  display: inline-block;
  border: 1px solid #ebebeb;
  background: #ffffff;
  border-radius: 3px;
}

.btn_filter_year {
  margin: 3px !important;
  // background: #fff !important;
  padding: 0 6px !important;
  border: 1px solid #ddd;
  // box-shadow: 0 0px 6px 0px #e9e9e9 !important;
  // width: 6.5% !important;
}

.btn_filter_year:hover {
  background: var(--main-bg-color) !important;
  color: #fff !important;
}

.btn_rest {
  background: none !important;
  border: none !important;
  text-align: right !important;
  color: #3c5b9b !important;
  text-align: right;
  margin-top: 5px;
}

.btn_rest:hover {
  color: var(--main-bg-color) !important;
  border: none !important;
}

.card-oe {

  .ant-card-body {
    height: 150px !important;
    overflow-y: auto;
    padding: 14px 24px;
  }
}

.card-oe,
.card-pdf {
  border-radius: 6px !important;

  &_item {
    margin-bottom: 0;
    color: #000;
  }



  .ant-card-head-title {
    padding: 8px 0px !important;
  }

  .ant-card-head {
    padding: 0px 24px !important;
    background-color: #fafafa !important;
    min-height: 36px;
    font-weight: 500 !important;
    // font-size: 12px !important;

  }



}

.card-oe_item span {

  font-weight: 600 !important;

}

.card-pdf {

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3a3a3a;
    padding: 12px 10px;
    border-radius: 6px;

    .icon_pdf {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        margin-left: 10px;
        color: white;
      }
    }

    .icon {
      color: white;
      font-size: 24px;
    }

    &:hover {
      color: #fe0000;
    }
  }
}







.box_info {
  height: auto;
}

.box_info2 .ant-descriptions-row>td {
  display: flex;
  justify-content: space-between;
}

.box_info .ant-descriptions-row>td {
  display: flex;
  justify-content: space-between;
}

.box_info .ant-descriptions-item-label {
  font-weight: 500 !important;
  color: #000;
}

.box_info .ant-descriptions-item-content {
  font-weight: 600 !important;
  color: #000;
}

.ant-descriptions-item-label {
  font-weight: 500 !important;
  color: #000;
  //font-size: 12px;
}

.ant-descriptions-item-content {
  font-weight: 600 !important;
  color: #000;
  //font-size: 12px;
}

.color_status {
  color: #2ed47a;
}



.colorRed {
  color: var(--main-bg-color);
}

.brandCard {
  border: 1px solid #eff5f5;
  text-align: center;
  padding: 20px 20px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 10px;
}

.brandCard:hover {
  box-shadow: 0 6px 6px rgba(199, 199, 199, 0.23);
  border: none;
}

.our_products__select {
  width: 1400px;
  border: none !important;
  background: transparent;
  border-radius: 0 !important;
  height: 490px !important;
}

@media only screen and (max-width: 767px) {
  .our_products__select {
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .our_products__select {
    width: 100%;
  }
}

.our_products--group {
  border: none !important;
  background: #fafafa !important;

  border-radius: 0 !important;
  height: 480px !important;
  overflow-y: auto !important;
  -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);

  .dropdown-item {
    padding: 0 !important;
  }
}

.our_products--group {}

.our_products--group:last-child {}

.our_products__title {
  background: #f6f6f6 !important;
}



.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #ececec;
}

.dropdown-toggle::after {
  display: none;
}

.categories_title.btn:hover {
  color: #fff !important;
}

.card__cars {
  border-radius: 8px !important;
  margin: 10px 0 !important;
  border: 1px solid #f1f1f1 !important;
  height: 90px;

  &__logobrand {
    width: 100%;
    filter: var(--filter-image);
    padding: 10px;
    margin: 0 auto;
  }

  .ant-card-cover {
    height: 90px;
  }
}

.cars_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 75px;
  margin: 4px;
  padding: 4px;
  padding-top: 8px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #fff;
  transition: border-color 1s ease;

  &.clicked {

    background-color: var(--light-main-bg);

  }

  &:hover {
    transition: border-color 1s ease;

    border-color: #999;

  }

  p {
    font-size: 8px;
    color: black;
    font-weight: 700;
  }

  .imgbrand {
    min-height: 40px;
    height: 40px;
    max-height: 40px;
    margin: auto;
  }

  .imgbrand img {
    max-height: 100%;
    max-width: 100%;
  }

  .title {

    padding: 10px 10px;
  }

  .title .ant-card-meta-title {
    font-size: clamp(0.4rem, 1vw, 0.8rem);
  }

  .row {
    align-items: center;
    justify-content: center;
    align-content: center;

    .brandlogo {
      width: 70% !important;
      margin: 0 au;
    }

    .card-block {
      text-align: flex-start;

      .card-title {
        margin-bottom: 0;
      }
    }
  }
}

.cars_detail_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 50px;
  margin: 4px;
  padding: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 13.5px 1.5px rgba(210, 210, 210, 0.44);
  border: none;

  &:hover {
    box-shadow: 0px 1px 3.5px 2.5px rgba(255, 208, 208, 0.24);
  }

  p {
    font-size: 12px;
    line-height: 14px;
  }

  .imgbrand {
    width: 28%;
  }

  .imgbrand .logobrand {
    width: 75% !important;
    filter: var(--filter-image);

  }

  .title {
    width: 68%;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.compact_card_item {

  //min-width: 250px;
  // flex: 1 1 250px;
  // flex-grow: 1;
  padding: 15px 10px;
  //  background-color: white;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  color: #000;
  text-align: left;

  transition: 0.3s;

  label {
    cursor: pointer;

    font-weight: 700;
    margin: 0;
  }
}

.compact_card_item:hover {
  background-color: var(--main-bg-color);
  border-radius: 15px;
  color: #fff;
}

.heading-brand {
  display: flex;
  align-items: center;
}

.heading-brand span {
  width: 32px;
  margin-right: 10px;
}

.years {
  min-height: 20px !important;
  max-height: 100px !important;
  background-color: #f6f6f6;
  padding: 7px;
  text-align: center;
  overflow-y: scroll !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}




.card__family {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 5px;
  margin: 8px 0;
  height: 90px;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 10px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(185, 175, 175, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(185, 175, 175, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(185, 175, 175, 0.15);
}

.card__family .ant-card-body {
  padding: 0 !important;
}

.card__family h4 {
  color: #c41515;
  font-size: 11.5px !important;
}



.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.ant-checkbox-wrapper {
  color: #000;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--main-bg-color);
}



// Searchbar autocomplte

.autoComplete .ant-select-dropdown-menu {
  padding: 15px !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: 100%;
  max-height: 100%;
}

.autoComplete .ant-select-dropdown-menu .ant-select-dropdown-menu-item-group {
  width: 50% !important;
}

.autoComplete .ant-select-dropdown-menu .ant-select-dropdown-menu-item-group-title {
  padding: 12px;
  margin-bottom: 10px;
  color: var(--main-bg-color);
  font-size: 14px;
  line-height: 32px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 9px;
    line-height: 18px;
  }
}

.autoComplete .ant-select-dropdown-menu .ant-select-dropdown-menu-item-group-list::after {
  border-right: 1px solid #f2f2f2 !important;
}

.autoComplete {
  .ant-select-dropdown-menu-item-group-list>.ant-select-dropdown-menu-item {
    padding-left: 5px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;

  img {

    filter: var(--filter-image-loading);
  }
}

.filterTop__details_scroll_horizontal_item_tooltip .ant-tooltip-inner {
  background-color: #fff;
  color: #000;
  font-weight: 600;
  padding: 10px 20px;
  width: max-content;
}

.filterTop__details_scroll_horizontal {
  // background-color: #fff;
  margin-bottom: 0px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  scrollbar-color: rgb(242, 242, 242) white;
  scrollbar-width: thin;
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;

  &_item {
    width: max-content;
    height: 45px;
    border: 1px solid #e1e1e1;
    background-color: white;
    margin: 7px 4px;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;



    &_image {
      width: 50px;

      .logobrand {
        width: 100%;
        filter: var(--filter-image);
      }
    }

    &_image_grand {
      width: 45px;

      .logobrand {
        width: 100%;
        filter: var(--filter-image);

      }
    }

    &_title {
      margin: 0 10px;
      font-size: 0.8rem;
      font-weight: 600;
      transition: 10s;
    }
  }

  &_item.grand {

    height: 45px;
  }
}

.arrow-prev {
  padding: 10px;
}

.arrow-next {
  padding: 10px;
}

.filterTop__details {
  background-color: #fafafa;
  margin-bottom: 20px;
  overflow-y: hidden !important;
  overflow-x: scroll !important;
  display: flex;
  justify-content: normal;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  scrollbar-color: rgb(242, 242, 242) white;
  scrollbar-width: thin;

  &_item {
    width: 40px;
    height: 40px;
    border: 1px solid #e1e1e1;
    background-color: white;
    margin: 0 6px;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    &_image {
      width: 30px;

      .logobrand {
        width: 100%;
        filter: var(--filter-image);

      }
    }

    &_title {
      display: none;
      margin: 0 10px;
      font-size: 0.8rem;
      font-weight: 600;
      transition: 10s;
    }
  }
}

.filterTop__details .filterTop__details_item:hover {
  width: max-content;
  border: 1.2px solid var(--main-bg-color);
}

.filterTop__details .filterTop__details_item:hover .filterTop__details_item_title {
  display: inline;
  width: max-content;
}



.price_box {
  line-height: 2rem;
  font-size: 1.7rem;
  color: #ea3d3a;
  font-weight: 700;
}

.icons__details {
  background: #f6f6f6;
  text-align: center;
  margin: 4px 0;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 6px;
}

.detail_product_model {
  // width: 230px;
}

.detail_product_model img {
  width: 100%;
  border-radius: 12px;
}

.head__article--box {
  position: relative;
  text-align: left;
  margin: 15px auto;
  border-radius: 8px;
  background: rgba(237, 0, 1, 0.04);
  padding: 25px;
  transition: 0.8s ease;
}

.journal-lumineux {
  /*  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 20s linear infinite;
  -webkit-animation: my-animation 20s linear infinite;
  animation: my-animation 20s linear infinite; */

  display: inline-block !important;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  flex-grow: 1;
  width: 0px;
  background: #f1f1f1;
  padding: 0px 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-right: 15px;
  //font-size: 12px;
}



/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.cardProduct__list-item:hover .autoplusIcon {
  animation: fa-spin 1s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transfo: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.hover-effect5:hover .shipping__area--inner--item--icon,
.hover-effect5:hover .shipping_icon .shipping_image {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  line-height: 110px;
  text-shadow: 1px 1px 2px #ffa000;
}

.hover-effect1:hover .shipping__area--inner--item--icon,
.hover-effect1:hover .shipping_icon .shipping_image {
  animation: fa-spin 1s infinite linear;
}

.brand>* {
  cursor: pointer !important;
}

//  NOS VALEURS

.nos-valeurs-item {

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #eee;
  border-top: 10px solid var(--main-bg-color);

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px 10px;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 10px;
  width: 300px;
  height: 380px;
  overflow: hidden;

  h4 {
    margin-bottom: 40px;

  }

  p {
    text-align: justify;
    white-space: pre-wrap;
    /* or pre-line */


  }
}

.nos-valeurs-item:hover {

  background-image: linear-gradient(to top, var(--main-bg-color), transparent, transparent);
  background-color: transparent;

}


.nos-valeurs-item .icon-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--main-bg-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;

  min-width: 60px;
  min-height: 60px;
  margin-bottom: 10px;


  .anticon {
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    margin-left: 0px !important;
  }

  i::before {
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-left: 0px !important;
  }
}

.avantage-entreprise-div-parent {

  display: flex;
  flex-direction: row;

  padding: 0px 20px 35px 20px;
  /* background: white;
  background-image: url(./shared/assets/images/bg-trait.png);
  background-repeat: no-repeat;
  background-size: cover; */
  position: relative;

  .histoire {

    margin-top: 10px;
    margin-left: 50px;
    // font-weight: 500;
    //font-size: 15px;
  }

}



.avantage-entreprise-div-parent-child {


  display: flex;
  flex-direction: row;

}













.btnPrevNext {
  width: 20% !important;
  margin: 0 4px;
}




.tab__detail--article--brand {
  position: relative;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #fafafa;
  padding: 10px 2px;
  height: 100%;
  transition: 0.8s ease;
  cursor: pointer;

  label {
    font-size: 12px;
    font-weight: 600;
    color: rgb(46, 46, 46);
    margin-bottom: 0;
  }

  &.active {
    background: var(--light-main-bg);


  }

  &:hover {
    background: var(--light-main-bg);

  }

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;


  }
}

.tab__detail--article--title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.exportPopOver {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.exportPopOver_btn {
  background-color: var(--main-bg-color);
  color: #fff;
  border-radius: 6px;
  border-color: var(--main-bg-color);
  align-items: center;
  display: flex;
}

.ant-popover {
  border-radius: 20px;
}

.ant-popover-title {
  padding: 10px 16px;
  text-align: center;
}

// Date Picker
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: var(--main-bg-color);
}

.ant-calendar-today .ant-calendar-date {
  color: var(--main-bg-color);
  font-weight: bold;
  border-color: var(--main-bg-color);
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #ffdfdf;
  border: 0;
  border-radius: 0;
  content: "";
}

.ant-calendar-selected-day .ant-calendar-date {
  background: #ffdfdf;
}


.ant-table-expanded-row td {

  padding: 0 !important;
}

// Table
.tableContent {

  background: #fff;
  //border-radius: 15px;
  //margin: 15px;
  transition: 0.8s ease;
  border: 0;
  border-left: 1px solid #ddd;

}

.tableContent2 {
  position: relative;
  text-align: left;
  background: #fff;
  padding: 6px;
  transition: 0.8s ease;
}

.tableContent_title {
  text-align: center;
  color: #000;
}

.table__custom {
  background-color: #fff !important;
}


a,
button,
img,
input,
span {
  font-weight: 500 !important;
}

.bg-gray {
  background-color: #f6f6f6;
}



.ant-list-items .ant-list-item {
  cursor: pointer !important;
  padding: 5px 2px;
  border: 0 !important;
  color: black;
  font-weight: 500;
}

.ant-list-items .ant-list-item:first-child {
  margin-top: 5px;
}


.widget_categories .listStyle:hover,
.widget_categories .listStyle.our-brand:hover {
  color: red !important;
}





// Article




.no-sub-item-tecdoc {

  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  margin: 0 !important;
}


.brand_title {
  user-select: none;
  background: var(--light-main-bg);

  color: #000;
  //border: 1px solid #ddd;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  margin: 0 !important;
  //border-bottom: 4px solid var(--main-bg-color);
  padding: 0;
  margin-top: 0px;



  height: 25px;
  // border-top: 2px solid;
  // border-left: 2px solid;
  // border-image-slice: 1;

  padding: 0px 10px;


  h3 {
    font: 600 15px sans-serif;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  img {
    width: 90px;
    height: 30px;
  }
}

.brand_title .ant-checkbox+span {
  color: rgb(180, 20, 20);
  font-size: 0.8rem;
  line-height: 1;
  margin-top: 7px;
}

.card_article_group_by_famille {

  overflow: hidden;
  //margin-bottom: 10px !important;
  position: relative;
  //background: #fff;
  overflow: hidden;

}

.famille_title {

  font-weight: bold;
  // margin: 5px 0px 0px 0px;
  // font-size: 1rem;
  background-color: #eee;
  color: #000;

  //border-radius: 5px;
  padding: 2px 10px;
  border-bottom: 1px solid #fafafa;


}


.card_article_group_by_fournisseur {
  // width: 300px;
  //border-radius: 5px;
  overflow: hidden;
  //  margin-bottom: 10px !important;
  position: relative;
  // border-radius: 5px;
  //background: #fff;
  overflow: hidden;
  border: 1px solid #ddd;
  //border-bottom: 0px;
}


.card_article_group_by_famille .card_article_group_by_fournisseur:is(:last-child) {
  margin-bottom: 0px !important;


}

.card_article_group_by_fournisseur.first {
  margin-top: 0 !important;
  border-radius: 0 !important;
  border-top: 0px solid #ddd;

}


.growed {
  flex-grow: 1;
}

.card_article_group_by_fournisseur .not-last-article {

  border-bottom: 1px solid #ddd !important;

}

.card_article_description_tags.grand button {

  padding: 5px 8px !important;
  margin: 5px 5px !important;
}

.ribbon {
  z-index: 2;
  $default-left: 10px;
  $default-top: -3px;
  $default-color: #ffe900;
  $second-color: #000;
  $default-width: 30px;
  $default-height: auto;


  left: var(--left, $default-left);
  top: var(--top, $default-top);

  filter: drop-shadow(2px 3px 2px rgba(black, 0.5));

  >.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var($second-color);
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    background: var(--color, $default-color) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
    padding: 4px 4px 2px 4px;


    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);

    width: var(--width, $default-width);
    min-height: var(--height, $default-height);

    transition: clip-path 1s, padding 1s, background 1s;

    >span {

      margin-bottom: 5px;

      &:last-child {

        margin-bottom: 10px;
      }

      i {
        font-size: 20px;
      }

      img {
        //filter: var(--filter-image);
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      }


    }
  }

  &.slant-up>.content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%);
  }

  &.slant-down>.content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
  }

  &.down>.content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
  }

  &.up>.content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%);
  }

  &.check>.content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px));
  }
}

.card_article {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1;
  padding: 0px;
  margin: 0px 0;
  // margin-top: 10px; 
  // background-color: rgba(210, 210, 210, 0.1);
  background-color: #fff;
  // border-radius: 5px;
  //overflow: hidden;
  transition: 0.8s ease;


  .ribbon {
    position: absolute;
  }

  .div-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: auto;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #ddd;

    &.gere {
      //   background-color: var(--main-bg-color);

    }

  }

  .warehouse--dropdown {

    text-align: center;
    width: 80px;
    padding: 1px 2px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }



  &_stock {
    padding-top: 24px;


    &_status_available_in_default_city {
      border-left: 5px solid #ffd90f;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &_status_unknown {

      border-left: 5px solid #666;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &_status_success {
      border-left: 5px solid #2ed47a;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &_status_warning {
      border-left: 5px solid #ff0000;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &_status_warning_orange {
      border-left: 5px solid orange;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  &_carousel {
    display: flex;
    align-self: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 120px;
    margin-right: 8px;
    margin-left: 8px;

    &_image {
      border-radius: 0px;

      &_status_success {
        width: 16px;
        height: 16px;
        border-radius: 6px;
        position: absolute;
        top: 5%;
        right: 6%;
        z-index: 9;
        background-color: #2ed47a;
      }

      &_status_warning {
        width: 16px;
        height: 16px;
        border-radius: 6px;
        position: absolute;
        top: 5%;
        right: 6%;
        z-index: 9;
        background-color: #ff9494;
      }

      &_status_warning_orange {
        width: 16px;
        height: 16px;
        border-radius: 6px;
        position: absolute;
        top: 5%;
        right: 6%;
        z-index: 9;
        background-color: orange;
      }
    }
  }

  &_description {
    display: inline-block;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px;
    padding-left: 14px;
    width: 100%;
    //padding-top: 8px;

    &_title {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    &_refs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      h6 {
        color: #000;
      }

      span {
        font-weight: 900;
        color: #000;
      }
    }

    &_code_tecdoc {
      margin-bottom: 0px;
      //  font-size: 12px;

    }

    &_code {
      color: var(--main-bg-color);
      margin-left: 5px;
      margin-bottom: 0px;
      // font-size: 12px;
    }

    &_stock {
      background-color: #fafafa;
      padding: 8px;
      padding-left: 8px;
      padding-top: 8px;
      display: flex;
      align-items: center;
      flex-direction: row;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 3px;
      border: 1px solid #f0f0f0;
      border-radius: 4px;

      &_item {
        background-color: #fff;
        padding: 10px;
        margin: auto 3px;
        border-radius: 6px;
        position: relative;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.17);
        box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.17);

        h6 {
          width: max-content;
          margin-bottom: 0;
        }

        &_status {
          width: 12px;
          height: 12px;
          border-radius: 6px;
          position: absolute;
          top: -5px;
          right: 6%;
          background-color: #2ed47a;
        }
      }
    }

    &_tags {
      padding-top: 4px;
      font-size: 12px;

      button {
        padding: 0 5px !important;
        height: auto;
        background-color: #ffebeb;
        border: 1px solid #ded8d8;
        border-radius: 6px;
        margin: 1px 2px;

        span {

          font-size: 12px;
        }
      }

      &_tooltip {
        padding: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &_item {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin: 2px 0;

          h5 {
            font-weight: 600;
          }
        }
      }
    }


  }

  &_description.compact {

    padding-top: 4px;
    padding-bottom: 4px;

    .warehouse--dropdown {

      padding-top: 2px;
      padding-bottom: 2px;
      height: 25px;
    }

  }

  &_description.compact.new {

    margin-left: 45px;
  }

  &_price {
    //  background-color: #e9ecef;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
    // padding: 2px 8px 2px 8px;
    // min-width: 240px;

    // margin-right: 15px;
    margin-left: 20px;
    // margin-top: 10px;
    // height: calc(100% - 20px);

    // margin-bottom: 20px !important;
    //align-self: center;
    // border: 1px solid #eee;
    // box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-radius: 0px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    &_boxprice {
      text-align: right;

      h6 {
        color: #000;
        font-weight: 300;
        flex-grow: 1;
      }

      h4 {
        color: var(--main-bg-color);
        margin: 0 !important;
      }
    }


  }

  .compact &_price {

    margin-right: 0;
  }

  /* &_price:after {
    background: linear-gradient(-137deg, #e9ecef 22px, transparent 0) 0 22px, linear-gradient(320deg, #e9ecef 22px, transparent 0) 0 22px;
    background-position: left-top;

    background-repeat: repeat-y;
    background-size: 45px 45px;
    content: " ";
    display: block;
    position: absolute;
    top: 0px;
    left: -45px;
    height: calc(100%);
    width: 45px;
  } */



  &_actions {
    text-align: center;
    margin: 0 auto;

    img {

      height: 35px;
      width: 35px;
      padding: 0px 8px;
    }

    &_item {
      background: #ffffff;
      text-align: center;
      margin: 4px 0;
      height: 35px;
      width: 35px;
      line-height: 32px;
      border-radius: 6px;
      cursor: pointer;
    }

    .red {
      background-color: var(--main-bg-color) !important;
      color: #fff;
    }
  }
}

.ajouter-au-panier-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-width: 0px !important;
  border-radius: 3px !important;
  //width: 40px !important;
  text-align: center !important;
  padding: 2px 10px !important;

}

.ajouter-au-souhait-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  border-width: 0px !important;
  border-radius: 3px !important;
  border-color: #faad14 !important;
  background-color: #faad14 !important;
  color: white !important;
  // width: 40px !important;
  text-align: center !important;
  padding: 2px 10px !important;

}


.vente-perdue-button {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 15px;
  border-width: 0px !important;
  border-radius: 3px !important;
  // width: 40px !important;
  text-align: center !important;

  padding: 2px 10px !important;
}


.compact .ajouter-au-panier-button {


  height: 25px;
  padding: 0 8px;
  margin-left: 10px;

}

.compact .vente-perdue-button {


  height: 25px;
  padding: 0 8px;
  margin-left: 10px;

}


.compact .ajouter-au-souhait-button {


  height: 25px;
  padding: 0 8px;
  margin-left: 10px;

}


.ajouter-au-panier-button:focus,
.ajouter-au-panier-button:hover {

  width: auto !important;
}


.card_article_group_by_fournisseur .titre-div {

  padding: 2px 10px;
  padding-right: 0px;
  background-color: #fff;
  border-top: 1px dashed #ccc;
  border-radius: 0px;
  margin: 0px;
}


.card_article_group_by_fournisseur .card_article:last-child {
  //border-bottom: 2px solid #ccc;

}


.card_article_price_input {
  display: flex;
  flex-direction: row;
  align-items: center;



  .total {

    font-weight: bold;
    font-size: 1rem;
    margin: 0 !important;
    color: #000;
  }

  .operation_math {
    line-height: 10px;
    font-weight: bold;
    font-size: 0.9rem;
    margin: 0 !important;
    margin-right: 5px !important;
    margin-left: 5px !important;

    background-color: #000;
    color: #fff;
    border-radius: 50%;
    padding: 7px;
    width: 23px;
    height: 23px;

  }
}

.tooltipDetials .ant-tooltip-content .ant-tooltip-inner {
  background-color: #ebebeb !important;
  width: 440px;
  color: #000000 !important;
}

.Tooltip__warehouse .ant-tooltip-content .ant-tooltip-inner {
  background-color: #ebebeb !important;
  width: 100px;
  color: #000000 !important;
}

.select_search .ant-select-selection {
  border: 0 !important;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.267);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.267);
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.267);
}

.select_search .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  text-align: center;
  font-weight: 600;
}

.select_search .ant-select-dropdown-menu {
  text-align: center;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #fff3f3 !important;
}

.switch {
  position: relative;
  display: none;
  align-items: center;
  justify-content: space-between;

  .btn_switch {
    background-color: var(--main-bg-color) !important;
  }
}

.headingSwitch {
  display: flex;
  align-items: center;
  position: relative;

  &__title {
    font-size: clamp(1rem, 1.1vw, 1.4rem);
    font-weight: 700;
    text-align: left;
    line-height: inherit;
    margin-bottom: 0;
  }
}

.headingModal {
  display: flex;
  align-items: center;
  position: relative;

  &__title {
    font-size: clamp(1rem, 1.3vw, 2rem);
    font-weight: 700;
    text-align: left;
    line-height: inherit;
    margin-bottom: 0;
  }
}

.contactPage {
  .contactCard {
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.17);
    transition: 0.8s ease;
    padding: 20px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 190px;

    &_head {
      margin-bottom: 20px;
      color: #000;
      font-weight: 500;
    }

    &_body {
      text-align: center;
      color: #000;
      font-weight: 500;
    }
  }

  .leaflet-container {
    width: 100%;
  }

  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-bar {
    margin: 0 !important;
  }

  .contactList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    margin: 8px 0;

    h3 {
      font-size: clamp(1rem, 1vw, 1.5rem);
      font-weight: 700;
      color: var(--main-bg-color);
      margin-bottom: 0;
    }

    p {
      margin: 2px 0;
      text-transform: lowercase;
      text-align: left;
      margin-bottom: 8px;
      color: #000;
    }

    &_info {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    &_hours {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .contactInfo {
    h3 {
      font-size: clamp(1rem, 1vw, 1.5rem);
      font-weight: 800;
      color: var(--main-bg-color);
    }

    p {
      margin: 2px 0;
    }

    &_info {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    &_hours {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

// NAME CATEGORY
.name__category {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget_categories .listStyle {
  padding: 4px !important;
  // font-size: 13px;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, .8);
  // color: #000;
}

.widget_categories .listStyle.our-brand {

  color: var(--main-bg-color);
  font-weight: bold !important;

}




.widget_categories .checkboxTitle {
  font-size: 12px;
  color: #000;
  font-weight: 600 !important;
}


.ant-select-dropdown.autoComplete.ant-select-dropdown--single {
  width: calc(100vw / var(--zoom)) !important;
  left: 0 !important;
  height: auto;
  max-height: calc((100vh / var(--zoom)) - 120px) !important;

  top: 63px !important;
}

// Search GLOBAL
.global-search-item-desc {
  font-size: 12px !important;
  padding-left: 10px !important;
}

.p0-10 {
  padding: 0 10px;
}

.tableContent .ant-list-split .ant-list-header {
  padding: 0 4px !important;
}

.cart_dropdown {

  top: 77px !important;


  .ant-dropdown-menu {
    padding: 0px;
    border-radius: 12px;

    width: 360px !important;
    max-height: calc((100vh / var(--zoom)) - 120px) !important;
    overflow-y: auto;
    right: -40px !important;
    position: relative;

    li {
      //  background-color: #fafafa;
      //  border-radius: 4px;
      //  border: 1px solid #ccc;
      //  margin: 5px 0;
      padding: 6px 12px;

    }

    li:is(:first-child) {
      background-image: url(./shared/assets/images/filet.svg);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      position: sticky !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      // height:140px;
      width: 100%;
      background-color: #FFFFFF;
      z-index: 2;
      display: block !important;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

    }

    li:not(:last-child) {

      border-bottom: 1px solid #ddd;

    }

    li:not(:first-child):hover {
      background-color: var(--tr-hover);

    }

    li:nth-child(even) {

      background-color: var(--tr-even);
    }

  }

  .status_article_item {
    display: flex;
    align-items: center;
  }

  .status_article_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 10px;
    width: 90%;
  }

  .status_article {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .ant-dropdown-menu-item {

    padding: 5px;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f1f1f1;
  }
}

.panier_dropdown {

  .ant-dropdown-menu {
    padding: 0px;
    border-radius: 12px;
    width: 250px !important;
    max-height: 350px !important;
    overflow-y: auto;
    position: relative;

    li {
      //  background-color: #fafafa;
      //  border-radius: 4px;
      //  border: 1px solid #ccc;
      //  margin: 5px 0;
      padding: 6px 12px;

    }



    li:not(:last-child) {

      border-bottom: 1px solid #ddd;

    }

    li:hover {
      background-color: var(--tr-hover);

    }

    li:nth-child(even) {

      background-color: #f9f9f9;
    }

  }

  .status_article_item {
    display: flex;
    align-items: center;
  }

  .status_article_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 10px;
    width: 90%;
  }

  .status_article {
    width: 25px;
    height: 25px;

    min-width: 25px;
    min-height: 25px;
    border-radius: 6px;
  }

  .ant-dropdown-menu-item {

    padding: 5px;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f1f1f1;
  }
}


.change__password {
  -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  padding: 20px;
  border-radius: 12px;
}

.search_global_select {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    font-size: 14px;
  }

  small {
    font-size: 60%;
  }
}

.leaflet-marker-icon {
  margin-left: -12px;
  margin-top: -41px;
  width: 25px;
  height: 41px;
}

.leaflet-popup {
  bottom: 24px !important;
}

// FLEX BOX GRID
.grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.grid-item {
  height: auto;
  flex-basis: 20%;
  -ms-flex: auto;
  width: 259px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
}

.grid-row .grid-item:first-child {
  padding-left: 0px;

}

@media (max-width: 1333px) {
  .grid-item {
    flex-basis: 33.33%;
  }
}

@media (max-width: 1073px) {
  .grid-item {
    flex-basis: 33.33%;
  }
}

@media (max-width: 815px) {
  .grid-item {
    flex-basis: 50%;
  }
}

@media (max-width: 555px) {
  .grid-item {
    flex-basis: 100%;
  }
}

.tab-petit {
  .ant-tabs-tab {

    font-size: 12px;


  }

}

.tab-historique {
  .ant-tabs-tab {

    font-size: 12px;

    margin: 0 !important;
  }

}

.ant-tabs-nav .ant-tabs-tab span,
.ant-tabs-nav .ant-tabs-tab {
  font-weight: 600 !important;
  font-size: 13px;
}



.global__search__tab>.ant-tabs-bar {
  .ant-tabs-tab-active {
    background-color: var(--main-bg-color) !important;
    color: white !important;
  }



  .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab {
    background-color: #303030;
    color: white;
    width: auto;
    flex: 1 1 0px;
    border: none !important;
    border-radius: 0 !important;
    // margin: 0 4px;
    padding: 0 6px !important;

    span {
      font-size: 12px;
    }
  }

  .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
  .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    display: none;
  }

  .ant-tabs-nav-container-scrolling {
    padding: 0;
  }

  .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab:first-child {
    margin: 0 0px;
  }

  .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab:hover {
    background-color: #303030;
    color: white !important;
  }

  .ant-tabs-nav {
    width: 100% !important;
    display: flex;
    flex-direction: row;
  }

  .ant-tabs-nav>div {
    width: 100% !important;
    display: flex;
    flex-direction: row;
  }


}


.global__search__tab__item {
  padding: 0 1.25rem;

  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    color: #000 !important;
    font-size: 14px;
    font-weight: 300 !important;

  }

  input {
    margin-top: 0;
  }
}

.input__search {}

.ant-select-dropdown-menu-item {

  color: rgba(0, 0, 0, .8);
  padding: 4px 8px
}

// Select custom :
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input:hover {
  border-color: #fe0000 !important;
  border-right-width: 1px !important;
}

.ant-input:focus {
  border-color: #fe0000;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--light-main-bg);
}

.ant-spin {


  align-self: center;
  margin: 0 auto;

}

// Spin
.ant-spin-dot-item {

  background-color: var(--loading-color);
}

.ant-dropdown-menu {
  // height: 40px !important;
  text-align: left;
}

.dropdown--select {
  .ant-dropdown-menu {
    height: 100%;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--tr-hover);
}

// Result Search VIN

.vin_tag {
  margin: 1em 0;

  &_item {
    padding: 14px 16px;
    border-radius: 0;
    border: 1px solid #e8e8e8;
    background-color: #fafafa;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    font-weight: 500 !important;

    &:hover {
      background-color: #fe0000;
      color: white;
    }
  }
}

// Contact infos

.contact_info_item {
  margin-bottom: 10px;




  &_title {
    background-color: #fe0000;
    padding: 12px;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: white;
      margin-bottom: 0;
      text-align: center;
    }
  }

  &_content {
    &_link {
      padding: 10px 16px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #000000;

      &:hover {
        color: #fe0000;
      }
    }

    a {
      color: #000000;

      &:hover {
        color: var(--tr-hover);
      }
    }
  }
}

.menu__list {
  &--item {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    white-space: nowrap;

    &--logo {
      width: 25px;
      padding: 0px;
      filter: var(--filter-image);
    }
  }
}

.dropdown-menu {
  padding: 0;
}



.user_list--item {
  display: flex;
  align-items: center;
  padding: 2px 3px;

  img {
    width: 16px;
    margin-right: 8px;
  }

  span {
    font-size: 12px;
  }
}

// INFO PERSONAL
.info--personal {
  padding-top: 20px;

  &--item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 8px;

    h4 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }

    h5 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }
  }
}

.inputs--mail {
  width: 100%;

  &--notif {
    width: 100%;
  }
}


// Details Product

.product__details--img {
  cursor: zoom-in;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.5);
  }
}

.product__details--zoom {
  width: 800px !important;

  .ant-modal-content {

    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: auto;
  }
}

.product__details--image {
  max-width: 100%;
  margin: auto 0;
  max-height: 450px;
}




.react-multi-carousel-track {
  align-items: center;
}

//  Cart
.cart__resume {
  display: flex;
  flex-direction: column;

  padding: 10px 15px;


  .ant-btn-primary {

    width: 260px !important;
    align-self: center;
    margin-top: 10px;

  }

  &--items {
    &--item {
      padding-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #000;
    }
  }
}

.ant-tabs-ink-bar {
  background-color: var(--main-bg-color);
  z-index: 0;
  height: 3px;
}

.input__table--red {

  input,
  .ant-select-selection,
  .ant-calendar-picker-input {
    border-color: #fe0000 !important;
  }

  .ant-select-selection__placeholder {
    color: #f79191 !important;
  }

  ::-webkit-input-placeholder {
    color: #f79191;
  }

  ::-moz-placeholder {
    color: #f79191;
  }

  :-ms-input-placeholder {
    color: #f79191;
  }

  :-moz-placeholder {
    color: #f79191;
  }
}

.label__tabel--red {
  color: #fe0000;
  margin-bottom: 0;
}

.ant-modal.full-screen-modal {
  width: 100% !important;





  .ant-modal-content {
    height: calc((100vh / var(--zoom)) - 60px);

  }
}

.ant-modal.form-modal {

  .ant-modal-body {
    padding: 15px !important;
  }
}





.ant-modal {
  padding: 0 !important;


  .heading {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .heading__title {

    font-size: 20px;
    line-height: 15px;
  }


  .ant-modal-content {
    // background-image: url(./shared/assets/images/dots-background.svg);
    background-color: #fff;
    background-repeat: repeat;
    background-size: 21px 21px;
    background-position: top left;

    margin: 30px !important;
    max-height: calc((100vh / var(--zoom)) - 60px);
    overflow: auto !important;
    display: flex;
    flex-direction: column;
  }


  .ant-modal-body {
    padding: 0 !important;
    overflow: auto !important;
    flex-grow: 1;
  }


  .ant-modal-close {
    right: 9px !important;
    top: 5px !important;


    .ant-modal-close-x {
      height: auto !important;
      width: auto !important;
      font-size: 28px !important;
      line-height: 28px !important;
    }
  }




}


.admin--stock--action--download:hover {
  background-color: #fe0000;
  color: white;
}



.head--admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.autoplus--btn {
  width: max-content !important;
  border-radius: 8px !important;
}

.autoplus--btn:hover {
  width: max-content !important;
  border-radius: 8px !important;
}

.admin--stock--action {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;

  &--upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;

    label {
      margin-bottom: 0.1rem;
    }

    &--file {
      background-color: white;
      border: 1px solid #dddddd;
      padding: 6px;
      width: 300px;

      label {
        padding: 3px 10px;
        background-color: #f7f7f7;
        border: 0.5px solid #bfbfbf;
        cursor: pointer;
      }
    }

    input {
      background-color: white;
      border: 1px solid #dddddd;
      padding: 8px;
      visibility: hidden;
      position: absolute;
      top: 10000px;
    }
  }

  &--download {
    background-color: hsl(0, 0%, 11%);
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: #fe0000;
      color: white;
    }

    span {
      margin-right: 5px;
    }
  }
}

.import-label {
  background-color: rgba(237, 28, 0, 0.2);
  padding: 6px;
  cursor: pointer;
  border-radius: 8px;
  color: #000;
  font-weight: 500;
}

.import--cart--action {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;

  &--upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;

    label {
      margin-bottom: 0.1rem;
    }

    &--file {
      background-color: white;
      border: 1px solid #dddddd;
      padding: 6px;
      width: 300px;

      label {
        padding: 3px 10px;
        background-color: #f7f7f7;
        border: 0.5px solid #bfbfbf;
        cursor: pointer;
      }
    }

    input {
      background-color: white;
      border: 1px solid #dddddd;
      padding: 8px;
      visibility: hidden;
      position: absolute;
      top: 10000px;
    }
  }

  &--download {
    background-color: var(--main-bg-color);
    padding: 6px;
    margin: 5px;
    border-radius: 8px;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: #fe0000;
      color: white;
    }

    span {
      margin-right: 5px;
    }
  }
}

.admin--stock--action--download:hover {
  background-color: #fe0000;
  color: white;
}



// Create Stock
.admin--stock--create {
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  position: relative;
  padding-top: 20px;
  margin-top: 10px;

  label {
    margin-bottom: 0.1rem;
  }

  &--delete {
    z-index: 999;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .datepicker {
    width: 100%;
  }
}

.import--cart--item {
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  position: relative;
  padding-top: 20px;
  margin-top: 10px;

  label {
    margin-bottom: 0.1rem;
  }

  &--delete {
    z-index: 999;
    position: absolute;
    right: 10px;
    top: 30px;
  }
}

// ADMIN
.layout--admin {
  background-color: #f7f8fa !important;
  // padding: 30px 40px;
}

.sidebar--admin {
  border-right: 1px solid #ededed;

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .ant-layout-sider-trigger {
    border-right: 1px solid #ededed;
  }
}

.admin--header {
  background-color: white !important;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
}

.admin--right {
  text-align: right;
  padding-right: 20px;
}

.logo--admin {
  height: 55px;
  padding-left: 20px;

  img {

    max-height: 100%;
  }
}

// Entries
.entries--area {}


.entries--tabs {
  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-nav .ant-tabs-tab {
    width: 33.33%;
    // @media (max-width: 800px) {
    //   width: initial;
    // }
  }

  .ant-tabs-tab {
    background-color: #fafafa;
    color: #000000 !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 9;
    font-weight: 600;
    font-size: 12px;
  }

  .ant-tabs-tab-active {
    background-color: #fce7e7;
    color: #000000 !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 3px solid var(--main-bg-color);
    z-index: 9;
    font-weight: 600;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
  }
}

.card_entires {
  background: white;
  padding: 0;
  border: none;
}

.row__entires {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cart-entries {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &--stock {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  &--input {
    width: 60px;
    margin-right: 5px;
    text-align: center;
  }

  &--btn {
    background-color: var(--main-bg-color) !important;
    color: #fff !important;
    padding: 10px;
    border-radius: 8px;
  }
}





.add_to_cart_two {
  background-color: var(--main-bg-color) !important;
  color: #fff !important;
  padding: 10px 24px;
  border-radius: 10px;
  width: max-content;
  cursor: pointer;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.admin--table--supplier--logo {
  width: 56px;
  height: auto;
}

.customer--table--supplier--logo {
  width: auto;
  height: 150px;
}

.warehouse--dropdown {
  display: block;
  color: white !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}




.warehouse--actif {
  background: green;
}

.warehouse--inactif--default--city {
  background: #ffd90f;
  color: black !important;
  ;
}

.warehouse--inactif {
  background: rgb(192, 0, 0);
}

.warehouse--non-gere {
  background: #555;
}

.article--attribute-parent {
  // border-top: 1px solid #ddd;
  // background-color: #f1f1f1;
  //width: 100%;

  // margin-top: 10px;

  .titre {
    font-weight: bold;

    flex-grow: 1;
    margin: 0 !important;
  }

  .titre.new {

    padding-left: 45px;
  }
}

.article--attribute-parent.new .journal-lumineux {

  padding-left: 45px;
}

.div-horizontal {
  //width: 100%;
  display: flex;
  flex-direction: row;
}



.div-vertical {
  //width: 100%;
  display: flex;
  flex-direction: column;
}


.div-vertical.center,
.div-horizontal.center {

  align-items: center;
}

.div-vertical.wrapped,
.div-horizontal.wrapped {

  flex-wrap: wrap;
}

.div-vertical.center-justified,
.div-horizontal.center-justified {

  justify-content: center;
}

.article--attribute {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-descriptions-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    padding: 0;
    border-top: 0.5px solid #eae6e6;
  }

  &--list {
    display: flex;
    flex-direction: column;
    width: 100%;



    &--item {
      width: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      white-space: normal;
      padding: 2px 0px;



      /*  &:after {
        content: " / ";
        position: absolute;
        right: 0;
        // border-right: 1px solid #000000;
        top: 10%;
        bottom: 10%;
      } */

      .key {
        // font-size: 10px;
        flex-grow: 1;
        margin: 0;
        padding-left: 8px;
      }

      .value {
        font-weight: 600;
        text-align: right;
        white-space: nowrap;
        margin: 0;
        background-color: #f9f9f9;
        padding: 0px 8px;
        // font-size: 12px;
      }

      /* &:last-child {
        &:after {
          content: "";
        }
      } */
    }


    &--item:nth-child(even) {
      background-color: #f9f9f9;

    }

    &--item:not(:last-child) {
      border-bottom: 1px solid #ddd;

    }


  }

  &--show-hide {

    padding: 0px 10px !important;
    //width: 100%;
    // margin-top: 10px;
    // margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    label {
      margin: 0 !important;
      margin-right: 10px !important;
      font-weight: bold;
      font-size: 0.8rem;
      flex-grow: 1;
    }
  }
}

.card--product--icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
  filter: var(--filter-image);

}

.vehicles__sidebar {

  //border-top: 1px solid #d9d9d9 !important;
  //margin-bottom: 15px !important;
}




.vehicles_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}

.show--more {
  background-color: hsla(0, 100%, 46%, 0.1);
  height: 30px;
  font-size: 12px;
  width: 100%;
  color: #000;
  border: none;
}

.show--more--details {
  background-color: hsla(0, 100%, 46%, 0.1) !important;
  height: 30px;
  font-size: 12px;
  color: #000;
  border: none;

  &:hover {
    background-color: hsla(0, 100%, 46%, 0.1) !important;
    color: #000;
  }
}

.original_price {
  color: #000 !important;
  text-align: right;
  margin-bottom: 0;
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.brut_price {
  font-weight: 500;
}

.import--cart--childrens {
  background: white;
}

.import--cart--childrens .import--cart--item {
  margin: 8px;
  background-color: #fceded;
  border: 1px solid #ec0d0d;
}

.item--wareHouses {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// LOGIN AREA ------------------------------
.login__container {
  display: grid;
  place-items: center;
  min-height: calc((100vh / var(--zoom)) / var(--zoom));
  width: 100%;
  background: #f1f1f1;


  &--body {
    height: max-content;
    width: 40%;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);

    @media (max-width: 800px) {
      width: 80%;
    }

    &--form {
      padding: 24px;

      &--actions {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 24px 0;

        @media (max-width: 800px) {
          justify-content: center;
          padding: 0;

          .reset--btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

// END LOGIN AREA ------------------------------

// Responsive Menu
.menu--mobile {
  display: none;
  text-align: right;

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &--icon {
    background-color: black;
    border: 1px solid white;
    border-radius: 4px;
    height: 36px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  &--drawer {
    .ant-drawer-header {
      padding: 0 24px;
    }

    .ant-drawer-body {
      padding: 0;
    }

    &--list {
      padding-top: 20px;

      &--item {
        border-bottom: 0.5px solid rgb(222, 222, 222);

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #fff1f2;
          color: #bd081b;
        }

        a {
          text-decoration: none;
          color: black;
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 24px;

          &:hover {
            color: #bd081b;
          }
        }
      }

      &--itemCatalog {
        cursor: pointer;
        text-decoration: none;
        color: black;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 24px;
      }

      .active {
        color: #bd081b;
        background-color: #fff1f2;
      }
    }
  }
}

// END Responsive Menu
.test-card {
  width: 475px;
  height: 245px;
}

// Global Search Responsive
.global-search__mobile {
  margin: 10px 0;
  width: calc(100% - 60px);

  &--head {
    padding: 6px 16px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.add-to-cart-icon {
  border-left: 1px solid rgb(52, 103, 241);
}

// END Global Search Responsive

.menu--item--catalog {
  display: block;
  font-size: 12px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 600 !important;
  position: relative;
  color: #fafafa;
  cursor: pointer;
}

.menu--item--catalog:hover {
  border-bottom: 2px solid var(--main-bg-color);
}

.upload-list {
  margin: 2% 0 3% 0;
  background-color: white;
  padding: 1% 0 1% 0;
}

.upload-input {
  width: 100%;
  height: 100%
}

.upload-form {
  width: 120;
}

.antd-delete-upload-icon {
  font-size: 24px;
}

.antd-btn-bg-color {
  background-color: #03a9fc;
}

.antd-btn-bg-color {
  background-color: #03a9fc;
}

.global-search-placeholder {

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    color: rgb(49, 49, 49) !important;
    font-size: 13px;
  }

  .ant-input-group-addon {
    border-radius: 0 !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .ant-btn-primary {

    border-radius: 0 !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    width: 45px !important;
    border: none !important;
    height: 35px !important;
  }
}

.search-code-article {
  input {
    font-size: 14px;
  }

  input::placeholder {
    color: rgb(0, 0, 0);
  }
}

.search-description-article {
  border: 1px solid red;
  font-size: 20px;
}

.search-description-article::placeholder {
  color: red;
}

.global-search-antd-empty {
  background-color: white;
  margin-top: 2px;
}

.sticky--tab {
  background-color: transparent;
  // border: 1px solid #ddd;
  // border-radius: 5px;
  // border: 1px solid #ddd !important;
  position: sticky;
  top: 0;

  overflow: auto;
  // --box-shadow: 0 6px 6px 0 rgba(29, 30, 32, 0.05);

  .ant-tabs-nav {
    width: 100%;

    .ant-tabs-tab {
      width: 50%;
      text-align: center;
      margin: 0;
    }
  }
}

.supplier-name {
  font-weight: 600 !important;
}

.dropdown_menu {
  width: 100%;
  height: 220;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}



body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F1F1F1;
}

body::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--main-bg-color);
}

body *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F1F1F1;
}


body *::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
}

body *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--main-bg-color);
}

.full-width {
  width: 100% !important;

}

.full-width:focus,
.full-width:hover {
  width: 100% !important;


}

.gutter-example {

  padding: 20px;
  padding-top: 10px;
}




.dark_div {
  background-color: #f1f1f1;
}


.section {
  display: flex;
  flex-direction: row;
  //margin: 25px;
  //border-radius: 25px;
  //background-color: #fff !important;

  //border: 1px solid #ddd;
  overflow: hidden;
  height: calc((100vh / var(--zoom)) - 69px);
  max-height: calc((100vh / var(--zoom)) - 69px);
}

.ant-modal .section {
  height: auto;
  max-height: auto;
}

.section.full {
  border-radius: 0;
  //margin:  0px 25px;
  margin: 0;
  border: 0;
  min-height: calc((100vh / var(--zoom)) - 27px);
  height: auto;
  max-height: none;


}

.section.catalog {
  border-radius: 0;
  //margin:  0px 25px;
  margin: 0;
  border: 0;
  height: auto;

  overflow: auto;
  max-height: none;


}

.section.full-height {

  height: 100%;
  max-height: none;
  min-height: 500px;

}

.section.modele {
  min-height: 500px;

  height: 500px;
  max-height: 500px;
}

.layout--admin .section.full {

  height: 100%;
}

.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;


      .ant-table {
        flex-grow: 1;

        .ant-table-container {
          height: 100%;

        }
      }

      .ant-pagination {

        align-self: flex-end;

      }
    }
  }
}


.section.form {
  height: auto;
  max-height: none;
  width: auto;
  align-self: center;
}




.titre-div-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px 40px;
  /*  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
  // border-bottom: 1px solid #ddd;

}

.heading__title {
  font-family: 'Oswald';

}

.titre-div-horizontal .heading__title {

  color: #000;
}

.panier-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  padding: 15px;
  background-color: #f9f9f9;
  background-image: url(./shared/assets/images/filet.svg);
  // background-repeat: no-repeat;
  background-size: auto;
  background-position: center;

  .total_cart_div {
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .total_cart {

    font-size: 40px;
    font-size: bold;
    z-index: 1;
    margin: 0;

  }

  .total_cart_div::before {
    content: '';
    position: absolute;
    top: 50%;
    z-index: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 180px;
    height: 180px;
    background-color: rgba(20, 200, 20, 0.1);
  }


  .total_cart_avant_promo {

    z-index: 1;
    color: green;
    font-weight: bold;
    margin-top: 5px;
  }


}

.show-more-primary {

  margin-top: 20px;
  margin-bottom: 20px;
}


.nombre-notification {

  position: absolute;
  right: -18px;
  top: -12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: 1px solid #000;
  border-radius: 35px;
  width: 35px;
  height: 25px;

  text-align: center;
}

.ant-pagination-item-active {

  border-color: #f00 !important;


}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: #ddd !important;

}



.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {

  background-color: var(--tr-hover) !important;
}

input[type="number"].ant-input {
  padding: 4px 4px;
}

.ant-table-header-column {
  width: 100%;

  .ant-table-column-title {
    display: block;
    width: 100%;

    .ant-input-number,
    .ant-select,
    .ant-input {
      width: 100%;
    }
  }
}

.ant-table {
  color: #000 !important;
  //border: 0 !important;

  tr:nth-child(even) {
    background-color: var(--tr-even);
  }



  tr {

    // border: 0 !important;
  }

  .total_cart {
    font-weight: bold;
    color: green;
    font-size: 1.3rem;
    margin: 0;
  }

  .total_cart_avant_promo {
    font-size: 0.9rem;

    text-decoration: line-through;
    margin: 0;
    margin-right: 10px;
  }

  .ant-table-footer {

    padding: 0;
    border-top: 1px solid #ddd !important;
    background-color: #fff;
  }

  th {
    padding: 6px 5px !important;
    font-weight: bold !important;
    // font-size: 12px;

    //border-top: 1px solid #ddd !important;
  }

  th * {
    font-weight: bold !important;

  }

  td {

    // color: #000;
    padding: 4px 5px !important;
    // font-weight: 500 !important;
    //font-size: 12px;
  }

  td * {

    // color: #000;
    font-weight: 500 !important;
  }

  table {
    border-radius: 0;
    // border: 0;
  }


  &-body {

    margin: 0 !important;

  }


}

.menu-wrapper {

  width: 100%
}

.menu-wrapper--inner {

  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.car-home {}

.car-home .car-list {

  overflow: auto !important;
  flex-grow: 1;
  padding: 20px;
}


.car-home .gutter-example {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto !important;

}

.cardProduct.card {
  background: #fff !important;
  //border: 0px !important;
}

.cardProduct {
  border-radius: 6px;
  overflow: hidden;
  // height: 375px;
  //box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  height: calc(100% - 20px) !important;
  //background-image: url(./shared/assets/images/bg-trait.png);
  background-repeat: no-repeat;
  background-size: cover;

  .list-group-item {

    background-color: transparent !important;
  }

  .logo-assembly-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-right: 6px;
    // background-color: #f1f1f1;
    // box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    img {

      filter: var(--filter-image);
    }
  }

  &__header {
    display: flex;
    // flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    height: 40px;
    //background-color: #f9f9f9;
    background-color: var(--light-main-bg);
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;

    &-image {
      width: 30px;
    }

    &-title {
      font-size: 0.9rem;
      line-height: 17px;
      font-weight: 600;
      color: #000;
      margin-left: 10px;
      margin-bottom: 0 !important;
    }
  }

  &__list {
    border: none !important;
    // background-color: #fff !important;
    overflow: auto;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(193, 193, 193, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(203, 203, 203);
    }

    &::-webkit-scrollbar-track-piece {
      background-color: rgb(255, 255, 255);
    }

    &-item {
      border: none !important;
      padding: 2px 5px;
      border-radius: 8px !important;
      margin: 1px 8px 1px 8px;
      //  margin: 2px 0;
      //  background-color: #fafafa !important;
      color: #000000;

      &:hover {
        background-color: rgb(243, 243, 243) !important;
        color: #000000;
      }


    }
  }
}

.ps-sidebar-container {
  display: flex;
  flex-direction: column;
}

.ps-sidebar-root .home-background .home-carousel .div-carousel {

  height: 250px;
}

.home-background {
  position: relative;

  background-color: transparent;


  .home-carousel {

    /*     background-color: rgba(255, 255, 255, 0);
    padding: 20px;
    padding-bottom: 30px;
    margin: 20px;
    margin-bottom: 0;
    margin-right: 0px;
    margin-left: 0px;
    // -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

    border-radius: 25px; */
    overflow: hidden;

    .carousel-indicators {

      bottom: -40px !important;
    }

    .carousel-indicators li {

      background-color: #000 !important;
    }

    .div-carousel {
      position: relative;


      /*  min-height: 450px;

      height: calc((100vh / var(--zoom)) - 200px);
      max-height: 550px; */

      img {
        width: 100%;
        height: auto;
      }


    }




  }

  .voile {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3023459383753502) 0%, rgba(0, 0, 0, 1) 100%);

  }

  .container {
    height: 100%;
  }

  .side-bar-search {
    margin: 0 !important;
    background-color: transparent !important;
  }

  .card {
    background-color: transparent !important;
    margin: 0 !important;

  }


  h2 {
    color: #000;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 5px;
  }

  label {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 550;
  }

  .voile-child {
    display: flex;
    flex-direction: column;
    background-color: var(--light-main-bg);
    border-top: 10px solid var(--main-bg-color);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-top: 15px;
    align-items: center;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);


  }

  .mecanicien {


    position: absolute;
    bottom: 0;
    right: 20px;
    max-height: calc(100% - 50px);
  }
}

.scroll-to-top {
  background-color: var(--main-bg-color);
  color: #fff;
}

.grand-logo {
  max-height: 160px;
}

.page-fullscreen {
  position: relative;
  width: 100%;
  height: calc(100vh / var(--zoom));
  background-color: #000;

  .home-carousel {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;



    .div-carousel {
      background-color: #000;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: calc(100vh / var(--zoom));
    }

    .div-carousel.un {

      background-image: url(./shared/assets/images/dynamique/usine2.jpg);

    }


    .div-carousel.deux {
      background-position: top center;
      background-image: url(./shared/assets/images/dynamique/usine1.jpg);

    }

    .div-carousel.trois {
      background-position: top center;

      background-image: url(./shared/assets/images/dynamique/usine3.jpg);

    }

    .div-carousel.quatre {
      background-position: top center;

      background-image: url(./shared/assets/images/dynamique/usine4.jpg);

    }

  }

  .voile {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3023459383753502) 0%, rgba(0, 0, 0, 1) 100%);

  }

  .container {
    height: 100%;
  }

  .side-bar-search {
    margin: 0 !important;
    background-color: transparent !important;
  }

  .card {
    background-color: transparent !important;
    margin: 0 !important;

  }

  h2 {
    color: #fff;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 10px;
  }

  label {
    color: #fff;
  }

  .voile-child {
    height: 100%;
    background-color: rgba(0, 0, 0, .5);

  }

  .mecanicien {


    position: absolute;
    bottom: 0;
    right: 20px;
    max-height: calc(100% - 50px);
  }
}

.ant-tabs-bar {
  margin: 0 !important;

}

.ant-modal .ant-tabs-bar,
.white-tabs .ant-tabs-bar {

  background-color: #fff;
}

.button-form-full {

  margin-top: 20px;
  width: 100% !important;
}

.stock_par_magasin {
  padding: 15px;
  //width: 50%;

  .quantite {
    font-weight: 600;

  }

  .disponibilite {
    font-weight: 700;


  }

  .disponibilite.disponible {

    color: green;
  }

  .disponibilite.indisponible {

    color: red;
  }

  .ville {
    margin: 0;

    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

.ant-list {

  .table-list-item {
    cursor: pointer;
    background-color: none;

  }

  .table-list-item * {
    cursor: pointer;

  }

  .table-list-item:nth-child(even) {

    background-color: var(--tr-even);

  }

  .table-list-item:hover {
    background-color: var(--tr-hover);
  }
}

.float-div {
  position: fixed;
  display: flex;
  flex-direction: row;

  z-index: 100;

  bottom: 10px;
  right: 30px;


}

.float {
  width: 45px;
  height: 45px;
  z-index: 100;

  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
}

.float.chat {
  background-color: var(--main-bg-color);
  margin-left: 10px;
}

.float:hover {
  opacity: 0.9;
  color: #fff !important;
}

.float-icon {
  margin-top: 12px;

}




.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-12px);
  }
}

.barniere-voile-noir-light {
  position: absolute;
  width: 100%;
  height: calc(100%);
  /*  background: #000;
  background: -webkit-linear-gradient(to right, rgba(29, 38, 113, 0.2), rgba(0, 0, 0, 0.4));
  background: linear-gradient(to bottom, rgba(29, 38, 113, 0.3) 20%, rgba(0, 0, 0, 0.8));
 */
}


.footer-clean {
  position: relative;
  border-top: 8px solid var(--main-bg-color);
  color: black;

  background: rgb(249, 249, 249, 0.7);

  background-image: url(./shared/assets/images/filet.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.footer-clean h3 {
  margin-top: 0;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 18px;
  color: #000;
  position: relative;

}

.footer-clean h3:before {
  position: absolute;
  background: #e02730;
  background: var(--main-bg-color);
  content: "";
  display: block;
  height: 3px;
  width: 70px;
  bottom: 0;
  left: 0;
}

.footer-clean ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  margin-bottom: 0;
  //font-size: 16px;
}

.footer-clean ul li {

  margin-bottom: 8px;
}

.footer-clean li {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
  font-weight: 500 !important;

  a,
  span {
    font-weight: 500 !important;
    color: #000 !important;


  }

}

.footer-clean li:hover,
.footer-clean li:hover a {
  color: #000 !important;
  opacity: 1;


}

.footer-clean .item.social {
  text-align: center;
  margin-top: 35px;
}

@media (max-width:1114px) {
  .footer-clean .item {
    // text-align: center;
    // padding-bottom: 20px;
  }
}

@media (max-width: 1114px) {
  .footer-clean .item.social {
    // text-align: center;
  }
}

.footer-clean .item.social>a {
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-top: 10px;
  color: inherit;
  opacity: 0.75;
  color: #000;
}

.footer-clean .item.social>a:hover {
  opacity: 0.9;
}


.footer-clean .copyright {
  margin-top: 14px;
  margin-bottom: 0;
  font-size: 13px;
  opacity: 0.7;
  font-weight: bold;
  color: #000;
}


.descriptions-div {
  margin-bottom: 25px;

  .ant-collapse-content>.ant-collapse-content-box {

    padding: 0 !important;
    margin-top: 15px !important;
  }

}

.ant-collapse {



  .ant-collapse-header {
    margin-bottom: 0px !important;
    border-radius: 3px !important;
    padding: 10px 10px !important;
    height: auto !important;
    color: #fff !important;
    background-color: var(--main-bg-color);
    font-size: 14px;
    font-weight: bold;
  }




}

.ant-descriptions-item-content {
  background-color: #fff !important;
}

.sidebar_widget {

  /*  .ant-collapse-content {
    max-height: 400px !important;
    overflow: auto !important;
    margin-bottom: 15px !important;
  } */

  .ant-collapse {
    .ant-collapse-header {
      background-color: #fff !important;
      color: #000 !important;

    }
  }


  .ant-collapse-borderless {
    background-color: #fff !important;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    padding: 5px 0px 0px 0px;

    .ant-collapse-content>.ant-collapse-content-box {

      padding: 0px;


    }
  }
}

.ant-collapse-borderless {
  background-color: transparent !important;

  >.ant-collapse-item {

    border-bottom: 0px !important;
  }
}

.not-found-message {

  padding: 20px;
}

.tag-span {
  height: auto !important;
  background-color: #ffebeb !important;
  border: 1px solid #ded8d8 !important;
  border-radius: 6px !important;
  margin: 5px 5px !important;
  padding: 5px 8px !important;
}

.not-hightlighted-text {
  color: rgba(0, 0, 0, .8);
}


.nouveau-article-card {

  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  width: 220px;
  padding: 15px 10px;
  height: 300px;
  margin: 15px 5px;
  border-radius: 5px;

  border: 1px solid #ddd;
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;

  .name {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }

  .name-supplier {

    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    //color: var(--main-bg-color);
  }

  .prix {

    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom: 0px;
  }

  .warehouse--dropdown {

    text-align: center;
    width: 75px;
    height: auto;
    padding: 0px 3px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }

  .div-image {
    margin: 10px 5px 15px 5px;
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;


    }
  }

  .ribbon {
    $default-left: 15px;
    $default-top: -3px;
    $default-color: #ffe900;
    $second-color: #000;

    $default-width: 30px;
    $default-height: auto;

    position: absolute;
    left: var(--left, $default-left);
    top: var(--top, $default-top);

    filter: drop-shadow(2px 3px 2px rgba(black, 0.5));

    >.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var($second-color);
      font-size: 1.25rem;
      text-align: center;
      font-weight: 400;
      background: var(--color, $default-color) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
      padding: 8px 4px 4px 4px;


      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);

      width: var(--width, $default-width);
      min-height: var(--height, $default-height);

      transition: clip-path 1s, padding 1s, background 1s;

      >span {

        margin-bottom: 5px;

        &:last-child {

          margin-bottom: 10px;
        }

        i {
          font-size: 24px;
        }

        img {
          //filter: var(--filter-image);
          // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        }


      }
    }

    &.slant-up>.content {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%);
    }

    &.slant-down>.content {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
    }

    &.down>.content {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
    }

    &.up>.content {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%);
    }

    &.check>.content {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px));
    }
  }


}

.ant-radio-group {

  display: flex;
  flex-direction: row;

  .ant-radio-button-wrapper {
    min-width: 100px;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {

  z-index: 0;



}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {


  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.ant-radio-button-wrapper:hover {

  color: var(--main-bg-color);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {

  background: var(--main-bg-color);
  border-color: var(--main-bg-color);

}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 var(--main-bg-color);
}



.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--main-bg-color) !important
}


.comparison-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #000;
  font-weight: 600;



  td.cadre {

    background-color: var(--light-main-bg);
    color: #000;
    border-color: #bbb;
    border-bottom-width: 0px;
    border-left-width: 0px;
  }

  td.titre {
    background-color: var(--main-bg-color);
    color: #fff;

  }

  th {

    color: #000;
    font-weight: bold !important;
    font-size: 1.2rem;
    text-align: center;
    padding: 10px 10px;

    * {
      font-weight: bold !important;

    }
  }

}

.comparison-table th,
.comparison-table td {
  padding: 4px 10px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  background-color: #fff;
}

.comparison-table th:first-child {
  border: 0;
}

.comparison-table th:nth-child(2n) {
  box-shadow: 0 0 2rem hsla(0, 0%, 0%, 0.15);
}

.comparison-table th:nth-child(2n),
.comparison-table td:nth-child(2n) {
  position: relative;
  background: var(--tr-even);
}

.comparison-table td:not(:first-child) {
  text-align: left;
}


span.gere {

  color: var(--main-bg-color);
}



.trade-number {

  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 4px;
  margin: 4px;
  min-width: 100px;
  text-align: center;
  transition: all 1s ease 0s;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;

  &.fixed {

    width: 120px;
    max-width: 120px;
  }

  &.compatible {

    font-weight: bold;
    border: 1px solid #62ab00;
    color: #62ab00;
  }
}

.trade-number:hover {

  background-color: #f1f1f1;
  border-color: #000;
}



.genesys-app .genesys-mxg-frame {

  zoom: calc(1 / var(--zoom));

}

.full-screen-modal {

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {

      height: 100%;
    }
  }
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {

  height: 100%;
}

.critere-tag {

  text-overflow: ellipsis;
  overflow: hidden;
  margin: 12px 2px 2px 2px;
  position: relative;
  padding-right: 20px;

  i {

    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}

.light-tag-div-critere {

  border-radius: 2px;
  padding: 3px 10px 3px 10px;
  margin: 0px 0px 10px 0px;
  // border: 1px solid #ccc;
  background-color: var(--light-main-bg);

}

.chart {

  zoom: calc(1 / var(--zoom));
  height: 350px;
}



.chart-div {

  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  padding: 10px;
  border: 1px solid #eee;
  //box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  width: calc(50% - 20px);
  min-width: 550px;
  flex-grow: 1;
  overflow: hidden;

  .chart-titre-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
  }

}

.chart-div-parent-full-width {

  .chart-div {

    width: 100% !important;
  }
}


.nav-dropmenu {

  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  overflow: auto;
  margin: 0px 0px 0px 0px;
  height: calc(calc(100vh / var(--zoom)) - 150px);
  width: 290px;
  min-width: 290px;
  border: 1px solid #eee;
  border-radius: 0px;
  padding: 0px 0px;

  .ant-tabs {

    overflow: auto;
  }

}

.ps-sidebar-container {

  overflow: auto;
}




.nav-dropmenu.notification {
  width: 350px;
  min-width: 350px;


}

.nav-dropmenu {
  display: none;
  position: fixed;
  right: 20px;
  top: 63px;
  margin: auto;
  height: auto;
  border-radius: 8px;
  border: 0;
  max-height: calc((100vh / var(--zoom)) - 85px);
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);


}


/* .nav-dropmenu:after {
  content: '';
  height: 0;
  width: 0;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #fff;
  right: 260px;
  top: 70px;
  position: fixed;
  display: block;
  z-index: 300;

} */

.div-nav-notification {

  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 10px 10px 5px 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background 0.5s;
  background: #fff;

}


.div-nav-notification:hover {
  background-color: var(--light-main-bg);
}



.div-nav-notification * {
  cursor: pointer;

}

.div-nav-notification.entree {
  border-left: 6px solid #f1f1f1;
  background-color: #fff;
}

.div-nav-notification.entree.selected {
  border-left-color: var(--main-bg-color);
  background-color: var(--light-main-bg);
}

.titre-notification {
  font-weight: bold;
  color: var(--main-bg-color);
  flex-grow: 1;
  font-size: 0.9rem;
  margin: 0;
}

.description-notification {

  color: #000;
  flex-grow: 1;
  margin: 0;
  font-weight: 500;
}

.date-notification {
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: 510;


  text-align: right;
  border-radius: 5px;
  background: #f9f9f9;
  padding: 0 3px;
  font-size: 12px;

}

.image-notification {
  height: auto;
  width: 40px;
  border-radius: 5px;
  cursor: zoom-in;
  margin: 0 8px 0 0px;
  object-fit: contain;

}

.ref-sap {
  color: var(--main-bg-color);

}

.carousel-control-next-icon,
.carousel-control-prev-icon {


  min-width: 25px !important;
  min-height: 25px !important;

}

/*
.sr-only {

  width: 100px !important;
  height: 100px !important;
} */

.blacked .carousel-control-prev-icon,
.blacked .carousel-control-next-icon {
  -webkit-filter: invert(100%);
  filter: invert(100%);

}

.ant-modal.center-body {

  /*  display: flex;
  flex-direction: row;
  align-items: center; */
  //  width: 700px !important;
  max-width: 100% !important;
  height: 100% !important;

  .ant-modal-body {
    padding: 0px 0px !important;

    .slide {
      height: 100%;
      width: 100%;
    }

    .carousel-inner {
      display: flex;
      height: 100%;
    }
  }
}

.ant-badge-not-a-wrapper .ant-badge-count {

  min-width: 34px !important;
}

.application-div {


  display: flex;
  flex-direction: row;
  // align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 4px;
  margin-right: 4px;



  .application-label {
    margin: 0;
    font-weight: 600;
    font-size: 12px;


  }

  .application-separator {
    margin: 0;
    font-weight: bold;
    padding: 0px 5px;
    font-size: 1.5rem;
    color: var(--main-bg-color);
    height: 10px;
    position: relative;
    top: -7px;
  }
}

.count-up-div-parent::-webkit-scrollbar {
  display: none;
  /* Pour Chrome, Safari, et Opera */
}

.count-up-div-parent {

  margin-bottom: 20px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  /* Pour Firefox */
  -ms-overflow-style: none;
  /* Pour Internet Explorer et Edge */


  .count-up-div-child {
    display: inline-block;
    width: 180px;
    /* Définir la largeur des éléments enfants */
    height: 70px;
    /* Définir la hauteur des éléments enfants */
    margin-right: 10px;
    /* Espacement entre les éléments */
    background-color: lightblue;
    /* Couleur de fond pour la démonstration */
    text-align: center;
    line-height: 70px;
    /* Aligner le texte verticalement */
    font-size: 16px;
  }

  .count-up-div {
    flex-grow: 1;
    padding: 15px 0px 7px 0;
    position: relative;
    background-color: #f9f9f9;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    // border-bottom: 5px solid var(--main-bg-color);
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    min-width: 160px;
    margin: 0px 10px;
    color: #000 !important;
    transition: background-color 0.5s ease, color 0.1s ease;

    .text {

      font-size: 10px !important;
      font-weight: bold !important;
      line-height: 10px !important;

    }

    .num {

      font-size: 25px !important;
      font-weight: bold !important;
      margin: 0px 0px 15px 0px !important;
      line-height: 15px !important;

    }
  }

  .count-up-div.green {
    background-color: #62ab00 !important;
    color: white !important;
  }

  .count-up-div.red {
    background-color: #cb2028 !important;
    color: white !important;
  }

  .count-up-div.yellow {
    background-color: #faad14 !important;
    color: white !important;
  }

  .count-up-div:hover {
    background-color: #faad14 !important;
    color: white !important;
  }
}

.ant-pagination.ant-table-pagination {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.preview-cadre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0px solid #ddd;
  margin: 20px 0px;
  width: 400px;
  height: 220px;
}