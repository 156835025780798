.side-by-side-magnifier {
  display: flex;
  justify-content: center;

  overflow: hidden;
  zoom: calc(1 / 1);
  height: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 15px;

  overflow: hidden;
  cursor: zoom-in;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.magnified-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.magnified-image {
  width: 100%;
  height: 100%;

  position: absolute;
}
