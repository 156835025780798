.headingAssemblyGroupTitle {
  display: flex;
  align-items: center;
  position: relative;
  font-size: clamp(1rem, 1.1vw, 1.4rem);
  font-weight: 700;
  text-align: left;
  line-height: inherit;
  margin-bottom: 0;
  padding: 15px;
}
.empty_list_cross {
  .ant-list-empty-text {
    display: none;
  }
}
